import { getErrorss } from '../../../functions/errors/GetErrors'
import { getQueryCurrentUser } from '../../../functions/shared/api/queries/getQueryCurrentUser'
import { ACCESS_KEY, USER_ID_KEY, urlAPI } from '../../../urls'
import { dataFetchingFinish, dataFetchingStart } from '../../slices/modalSlice'

import { userSlice, type ISettings, type User } from '../../slices/userSlice'
import { type AppDispatch, type RootState } from '../../store'

export const updateProfile = (formValues: any) => async (dispatch: AppDispatch, getState: () => RootState) => {

    try {
        dispatch(dataFetchingStart())
        const jwt = window.localStorage.getItem(ACCESS_KEY)
        const userID = window.localStorage.getItem(USER_ID_KEY)
        const currentUser = getState().user.currentUser
        const userSettings = (currentUser.Settings as ISettings[] | null)?.at(0)
        const actualUser = {
            ...currentUser,
            About : formValues.about_me , 
            Settings:
                     [{
                         ...userSettings,
                         __component: 'settings.agent',
                         Company: formValues.company_name ?? ""
                     }]  
        }
        const response = await fetch(`${urlAPI}`, {
            method: 'POST',
            body: JSON.stringify({
                method: 'PUT',
                collection: 'users',
                id: Number(userID),
                query: getQueryCurrentUser(),
                body: {
                    ...currentUser,
                    About : formValues.about_me , 
                    Settings:
                             [{
                                 ...userSettings,
                                 __component: 'settings.agent',
                                 Company: formValues.company_name ?? ""
                             }]  
                }
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        })
        const dataJSON = await response.json()
       
        if (dataJSON.success) {
            if ((!dataJSON.response.error != null)) {
                dispatch(userSlice.actions.userUpdate(actualUser as User))
                return {  textNavigate: "/agent/profile/me" , isSuccessful: true, textNotification: 'You have successfully updated your profile data.' }        
            } else {
                dispatch(dataFetchingFinish())
                return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON) }
            }
        } else {
            dispatch(dataFetchingFinish())
            return { textNavigate: '', isSuccessful: false, textNotification: getErrorss(dataJSON)} 
        }
    } catch (error) {
        console.log(error)
        return { textNavigate: '', isSuccessful: false, textNotification: error }
        
    }
}




