import React, { useEffect } from 'react'
import { type IViewing } from '../../../store/slices/viewingsSlice'
import { useNavigate } from 'react-router-dom'
import { type Unit } from '../../../store/slices/unitsSlice'
import { type User } from '../../../store/slices/userSlice'
import {
    setShowPopupViewingFeedback
} from '../../../store/slices/swalsSlice'
import { viewingsQuery } from '../../../store/actionsCreators/viewingsActions'
import Swal from 'sweetalert2'
import { animationOptions, swalOptions } from '../../../functions/swalOptions'
import swalConfig from '../../../app/texts/swalTexts'
import { customCreateNotify } from '../../../store/actionsCreators/userActions/customCreateNotify'
import { createFeedbackViewingAction } from '../../../store/actionsCreators/viewingActions/createFeedbackViewingAction'
import { scanDocumentsForOffer } from '../../../store/actionsCreators/offerActions'
import { ACCESS_KEY, urlAPI } from '../../../urls'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import { is24HoursPassed } from '../../../functions/agent/date/is24HoursPassed'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'

interface IProps {
    dataLoaded: boolean
    dispatch: any
    viewings: IViewing[]
    units: Unit[]
    currentUser: User | Record<string, unknown>
}
function ViewingFeedback({ dataLoaded, dispatch, currentUser, units, viewings }: IProps): JSX.Element {
    const navigate = useNavigate()
    const oneCycleShowPopupsViewingReschedule = useAppSelector((state: RootState) => state.swalsStates.oneCycleShowPopupsViewingReschedule)
    const stackViewingsReschedule = useAppSelector((state: RootState) => state.swalsStates.stackViewingsReschedule)
    const userID = (currentUser as User).id
    const agentViewings = viewings.filter((el: IViewing) => el?.attributes?.User?.data?.id === userID)

    const viewingsCompleted = agentViewings.filter((view) =>
        view?.attributes?.Statuses?.includes('Waiting for feedback') &&
        ((view?.attributes?.NotifyViewedDate === null) || is24HoursPassed(view?.attributes?.NotifyViewedDate))
    )

    useEffect(() => {
        if ((!oneCycleShowPopupsViewingReschedule || stackViewingsReschedule.length === 0) || oneCycleShowPopupsViewingReschedule) {
            if (viewingsCompleted.length > 0) {
                showFeedbackPopup(viewingsCompleted[0])
            }
        }
    }, [oneCycleShowPopupsViewingReschedule])

    const showFeedbackPopup = (viewingDone: IViewing) => {
        dispatch(setShowPopupViewingFeedback(true))

        const unit = units.filter((el: any) => el.id === viewingDone?.attributes?.Unit?.data?.id)[0]
        const viewingDatetime = viewingDone.attributes.Datetime
        let offerModalType = ''
        const userName = getNameUser(currentUser as User)
        const unitAddress = getUnitAddress(unit)
        if (unit?.attributes?.Marketing?.Use === 'For rent') {
            offerModalType = unit?.attributes?.Type === 'Residential' ? 'residential' : 'commercial'
        } else {
            offerModalType = 'sale'
        }


        const paramsAnimationInfo = {
            iconHtml: '<div  id="lottie-icon-info"></div>',
            didOpen: async () => {
                const pathLottie = await import('../../../animations/Info.json')
                const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
                if (lottieContainer != null) {
                    lottie.loadAnimation({
                        container: lottieContainer,
                        renderer: 'svg',
                        ...{ ...animationOptions, animationData: pathLottie.default }
                    })
                }
            }
        }

        void Swal.fire({
            ...swalOptions('info'),
            ...{
                title: swalConfig.feedbackPopup.title(unitAddress, viewingDatetime),
                html: swalConfig.feedbackPopup.htmlMessage,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: swalConfig.feedbackPopup.confirmButtonText,
                denyButtonText: swalConfig.feedbackPopup.denyButtonText,
                allowOutsideClick: false,
                ...paramsAnimationInfo,
                backdrop: true,
                preConfirm: async () => {
                    const viewingTakePlace = swalConfig.feedbackPopup.questions.viewingTakePlace
                    void Swal.fire({
                        ...swalOptions('info'),
                        ...{
                            title: viewingTakePlace.title(unitAddress, viewingDatetime),
                            html: viewingTakePlace.html,
                            showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonText: viewingTakePlace.confirmButtonText,
                            denyButtonText: viewingTakePlace.denyButtonText,
                            ...paramsAnimationInfo,
                            allowOutsideClick: false,
                            preConfirm: async () => {
                                const viewingTakePlaceWasOkay = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasOkay
                                void Swal.fire({
                                    ...swalOptions('info'),
                                    ...{
                                        title: viewingTakePlaceWasOkay.title(unitAddress, viewingDatetime),
                                        html: viewingTakePlaceWasOkay.html,
                                        confirmButtonText: viewingTakePlaceWasOkay.confirmButtonText,
                                        denyButtonText: viewingTakePlaceWasOkay.denyButtonText,
                                        showDenyButton: true,
                                        showConfirmButton: true,
                                        ...paramsAnimationInfo,
                                        allowOutsideClick: false,
                                        preConfirm: async (value) => {
                                            const feedbackAgent = viewingTakePlaceWasOkay.feedbackViewingTakePlaceWasOkayMakeOffer
                                            const currentDubaiDate = getCurrentDubaiDate()
                                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)
                                            const notificationParams = {
                                                notificationTime: inMomentViewingForAdmin,
                                                RuleID: viewingDone.id,
                                                Rule: 'admin-viewing-feedback',
                                                User: Number(userID),
                                                Text: viewingTakePlaceWasOkay.textNotifyViewingTakePlaceWasOkayMakeOffer(userName, unitAddress),
                                                publishedAt: 'Published'
                                            }
                                            dispatch(customCreateNotify(notificationParams))
                                            dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                            dispatch(scanDocumentsForOffer(unit.id, offerModalType, navigate, false))
                                            dispatch(setShowPopupViewingFeedback(false))
                                        },
                                        preDeny: async () => {
                                            const viewingTakePlaceWasOkaySubmitTheAnswer = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasOkay.viewingTakePlaceWasOkaySubmitTheAnswer
                                            void Swal.fire({
                                                ...swalOptions('info'),
                                                ...{
                                                    title: viewingTakePlaceWasOkaySubmitTheAnswer.title(unitAddress, viewingDatetime),
                                                    input: 'select',
                                                    inputLabel: viewingTakePlaceWasOkaySubmitTheAnswer.inputLabel,
                                                    inputOptions: viewingTakePlaceWasOkaySubmitTheAnswer.inputOptions,
                                                    ...paramsAnimationInfo,
                                                    inputPlaceholder: 'Select a reason',
                                                    inputValidator: async (value) => {
                                                        if (value === 'Other reason') {
                                                            const viewingTakePlaceWasOkaySubmitTheAnswerOtherReason = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasOkay.viewingTakePlaceWasOkaySubmitTheAnswer.viewingTakePlaceWasOkaySubmitTheAnswerOtherReason;
                                                            return await new Promise((resolve) => {
                                                                void Swal.fire({
                                                                    ...swalOptions('info'),
                                                                    ...{
                                                                        title: viewingTakePlaceWasOkaySubmitTheAnswerOtherReason.title(unitAddress, viewingDatetime),
                                                                        inputLabel: viewingTakePlaceWasOkaySubmitTheAnswerOtherReason.inputLabel,
                                                                        confirmButtonText: viewingTakePlaceWasOkaySubmitTheAnswerOtherReason.confirmButtonText,
                                                                        input: 'textarea',
                                                                        showConfirmButton: true,
                                                                        showLoaderOnConfirm: true,
                                                                        showLoaderOnDeny: true,
                                                                        ...paramsAnimationInfo,
                                                                        allowOutsideClick: false,
                                                                        preConfirm: (inputValue) => {

                                                                            if (!inputValue) {
                                                                                Swal.showValidationMessage('Please , add a reason.');
                                                                                return false;
                                                                            }
                                                                            return inputValue;
                                                                        },
                                                                    }
                                                                }).then((result: any) => {
                                                                    if (result.isConfirmed) {
                                                                        const feedbackAgent = viewingTakePlaceWasOkaySubmitTheAnswer.feedbackViewingTakePlaceWasOkaySubmitTheAnswerReason(String(result.value));
                                                                        const currentDubaiDate = getCurrentDubaiDate();
                                                                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1);

                                                                        const notificationParams = {
                                                                            notificationTime: inMomentViewingForAdmin,
                                                                            RuleID: viewingDone.id,
                                                                            Rule: 'admin-viewing-feedback',
                                                                            User: Number(userID),
                                                                            Text: `User: ${userName} complete his viewing: Unit: ${unitAddress} Check his review in app!`,
                                                                            publishedAt: 'Published',
                                                                        };

                                                                        dispatch(customCreateNotify(notificationParams));
                                                                        dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }));
                                                                        dispatch(setShowPopupViewingFeedback(false));
                                                                    }
                                                                }).catch(err => {
                                                                    console.log(err);
                                                                });
                                                            });
                                                        }
                                                    },
                                                    showConfirmButton: true,
                                                    confirmButtonText: 'Submit the answer',
                                                    preConfirm: async (value) => {
                                                        if (!value) {
                                                            Swal.showValidationMessage('Please , add a reason.');
                                                            return false;
                                                        }
                                                        const feedbackAgent = viewingTakePlaceWasOkaySubmitTheAnswer.feedbackViewingTakePlaceWasOkaySubmitTheAnswerReason(String(value))
                                                        const currentDubaiDate = getCurrentDubaiDate()
                                                        const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                                        const notificationParams = {
                                                            notificationTime: inMomentViewingForAdmin,
                                                            RuleID: viewingDone.id,
                                                            Rule: 'admin-viewing-feedback',
                                                            User: Number(userID),
                                                            Text: `User: ${userName} complete his viewing: Unit: ${unitAddress} Check his review in app!`,
                                                            publishedAt: 'Published'
                                                        }
                                                        dispatch(customCreateNotify(notificationParams))
                                                        dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                                        dispatch(setShowPopupViewingFeedback(false))
                                                    },
                                                    allowOutsideClick: false,
                                                }
                                            })
                                        }
                                    }
                                })
                            },
                            preDeny: async () => {
                                const viewingTakePlaceWasNotOkay = swalConfig.feedbackPopup.questions.viewingTakePlace.viewingTakePlaceWasNotOkay
                                void Swal.fire({
                                    ...swalOptions('info'),
                                    ...{
                                        title: viewingTakePlaceWasNotOkay.title(unitAddress, viewingDatetime),
                                        inputLabel: viewingTakePlaceWasNotOkay.inputLabel,
                                        input: 'select',
                                        inputOptions: viewingTakePlaceWasNotOkay.inputOptions,
                                        ...paramsAnimationInfo,
                                        inputPlaceholder: 'Select a reason',
                                        inputValidator: async (value) => {
                                            if (value === 'Other reason') {
                                                const viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason = viewingTakePlaceWasNotOkay.viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason

                                                return await new Promise((resolve) => {
                                                    void Swal.fire({
                                                        ...swalOptions('info'),
                                                        ...{
                                                            title: viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason.title(unitAddress, viewingDatetime),
                                                            inputLabel: viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason.inputLabel,
                                                            confirmButtonText: viewingTakePlaceWasNotOkaySubmitTheAnswerOtherReason.confirmButtonText,
                                                            input: 'textarea',
                                                            showConfirmButton: true,
                                                            showLoaderOnConfirm: true,
                                                            showLoaderOnDeny: true,
                                                            ...paramsAnimationInfo,
                                                            allowOutsideClick: false,
                                                            preConfirm: (inputValue) => {
                                                                if (!inputValue) {
                                                                    Swal.showValidationMessage('Please , add a reason.');
                                                                    return false;
                                                                }
                                                                return inputValue;
                                                            },
                                                        }
                                                    }).then((result: any) => {
                                                        if (result.isConfirmed) {
                                                            const feedbackAgent = viewingTakePlaceWasNotOkay.feedbackViewingTakePlaceWasNotOkaySubmitTheAnswerReason(String(result.value))
                                                            const currentDubaiDate = getCurrentDubaiDate()
                                                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                                            const notificationParams = {
                                                                notificationTime: inMomentViewingForAdmin,
                                                                RuleID: viewingDone.id,
                                                                Rule: 'admin-viewing-feedback',
                                                                User: Number(userID),
                                                                Text: `User:  ${userName} complete his viewing: Unit: ${unitAddress} Check his review in app!`,
                                                                publishedAt: 'Published'
                                                            }
                                                            dispatch(customCreateNotify(notificationParams))
                                                            dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                                            dispatch(setShowPopupViewingFeedback(false))
                                                        }
                                                    })
                                                })
                                            }
                                        },
                                        showConfirmButton: true,
                                        confirmButtonText: 'Submit the answer',
                                        showLoaderOnConfirm: true,
                                        showLoaderOnDeny: true,
                                        preConfirm: async (value) => {
                                            const feedbackAgent = viewingTakePlaceWasNotOkay.feedbackViewingTakePlaceWasNotOkaySubmitTheAnswerReason(String(value))
                                            const currentDubaiDate = getCurrentDubaiDate()
                                            const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                            const notificationParams = {
                                                notificationTime: inMomentViewingForAdmin,
                                                RuleID: viewingDone.id,
                                                Rule: 'admin-viewing-feedback',
                                                User: Number(userID),
                                                Text: `User:  ${userName} complete his viewing: Unit: ${unitAddress} Check his review in app!`,
                                                publishedAt: 'Published'
                                            }
                                            dispatch(customCreateNotify(notificationParams))
                                            dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                            dispatch(setShowPopupViewingFeedback(false))
                                        },
                                        allowOutsideClick: false
                                    }
                                })
                                return false
                            }
                        }
                    })
                    return false
                },
                preDeny: async () => {
                    const viewingDidNotTakePlace = swalConfig.feedbackPopup.questions.viewingDidNotTakePlace
                    void Swal.fire({
                        ...swalOptions('info'),
                        ...{
                            title: viewingDidNotTakePlace.title(unitAddress, viewingDatetime),
                            inputLabel: viewingDidNotTakePlace.inputLabel,
                            inputOptions: viewingDidNotTakePlace.inputOptions,
                            confirmButtonText: viewingDidNotTakePlace.confirmButtonText,
                            inputPlaceholder: viewingDidNotTakePlace.inputPlaceholder,
                            input: 'select',
                            showDenyButton: false,
                            showConfirmButton: true,
                            showLoaderOnConfirm: true,
                            showLoaderOnDeny: true,
                            ...paramsAnimationInfo,
                            allowOutsideClick: false,
                            preConfirm: async (value) => {
                                if (!value) {
                                    Swal.showValidationMessage("Please select an option.");
                                    return false;
                                }

                                const currentDubaiDate = getCurrentDubaiDate()
                                const inMomentViewingForAdmin = toUTCWithOffset(currentDubaiDate, 1)

                                const feedbackAgent = viewingDidNotTakePlace.feedbackViewingViewingDidNotTakePlace(value)

                                const notificationParams = {
                                    notificationTime: inMomentViewingForAdmin,
                                    RuleID: viewingDone.id,
                                    Rule: 'admin-viewing-feedback',
                                    User: Number(userID),
                                    Text: `User:  ${userName} complete his viewing: Unit: ${unitAddress} Check his review in app!`,
                                    publishedAt: 'Published'
                                }
                                dispatch(customCreateNotify(notificationParams))
                                dispatch(createFeedbackViewingAction({ feedbackAgent, viewID: viewingDone.id }))
                                dispatch(setShowPopupViewingFeedback(false))
                            }
                        }
                    })
                    return false
                }
            }
        }).then(
            async (result: any) => {
                if (result.isDismissed) {
                    const jwt = window.localStorage.getItem(ACCESS_KEY) ?? ''
                    const response = await fetch(urlAPI,
                        {
                            method: 'POST',
                            headers: {
                                'Content-type': 'application/json',
                                Authorization: `Bearer ${jwt}`
                            },
                            body: JSON.stringify({
                                method: 'PUT',
                                collection: 'viewings',
                                query: viewingsQuery,
                                id: Number(viewingDone.id),
                                body: {
                                    NotifyViewedDate: getCurrentDubaiDate()
                                }
                            })
                        })
                    const dataJSON = await response.json()
                    dispatch(setShowPopupViewingFeedback(false))

                }
            }
        )
    }

    return (
        <></>
    )
}

export default ViewingFeedback
