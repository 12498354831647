
import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
import Modal from 'antd/es/modal/Modal'
import { type ISettings, type User } from '../../../../../store/slices/userSlice'

import CloseIconBasic from '../../../../shared/elements/CloseIconBasic'
import { ava_blank } from '../../../../../urls'
import Avatar from '../../../../shared/elements/Avatar'
interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    previewUser: User
}
export default function OtherProfilesNEW({
    previewOpen,
    previewUser,
    setPreviewOpen,

}: IProps): JSX.Element {
    const userCONTACTS = (previewUser)?.Type?.[0]
    const userContactsINFO = (previewUser)?.ContactInfo
    const userSettings = previewUser?.Settings as ISettings[]

    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )

    let fullName =
        (userContactsINFO?.FirstName ? userContactsINFO.FirstName : userCONTACTS?.Name) +
        ' ' +
        (userContactsINFO?.FamilyName ? userContactsINFO.FamilyName : '')
    fullName === 'undefined ' ? fullName = 'No name' : null
    const maxLength = 28

    return (
        <>
            <div className="d-flex flex-stack bg-white p-4 rounded shadow-sm "
                style={{ backgroundImage: `url(/assets/media/stock/s_${Math.floor(Math.random() * 5) + 1}.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="overlay" style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    zIndex: 0
                }}></div>
                <div style={{ position: 'relative', zIndex: 1 }}>
                    <div className="symbol symbol-40px me-5" onClick={() => {
                        setPreviewOpen(true)
                    }}>
                        <Avatar image={userCONTACTS?.Photo ?? undefined} name={fullName} size={'7rem'} />
                    </div>
                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-gray-800 text-hover-primary fs-3 fw-bold" style={{ wordBreak: 'break-word' }}>
                                    {fullName.length > maxLength
                                        ? fullName.slice(0, maxLength) + '...'
                                        : fullName}
                                </span>
                            </div>
                            <span className="text-gray-900 fw-semibold d-block fs-5">{userSettings?.[0].Company ?? "Company LLC."}</span>
                            <div className="text-gray-800 fs-6  ">
                                {previewUser.About ?? "Hey there!I am using BSO Club!"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={previewOpen}
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false)
                }}
                closeIcon={
                    <CloseIconBasic onCancel={() => {
                        setPreviewOpen(false)
                    }} cls={''} style={{ top: '-5px', right: '-5px' }} />
                }
                centered
                styles={{
                    body: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50vh'
                    }
                }}
            >
                <img
                    alt="example"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={imageUrl}
                    className='object-fit-cover w-100 h-100'
                />
            </Modal>
        </>
    )
}
