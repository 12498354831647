import React, { useEffect, useState } from 'react'
import UseUserType, { type UserType } from '../../../../hooks/UseUserType'
import { REACT_APP_BSO_USER_ID, USER_ID_KEY } from '../../../../urls'
import { timeStringInScheduler } from '../../../../functions/agent/date/timeStringInScheduler'
import { type IAdvertise } from '../../../../store/slices/advertisesSlice'
import { Link } from 'react-router-dom'
import { generateBgAndBorderCol } from '../../../../functions/agent/schedule/generateBgAndBorderCol'
import { nanoid } from 'nanoid'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { type RootState } from '../../../../store/store'
import Icon from '../../../../app/icon/Icon'
interface IProps {
    advertise: IAdvertise
}

function AdvertiseCard({ advertise }: IProps): JSX.Element {
    const { userType, userTypeLowercase } = UseUserType()
    const userID = window.localStorage.getItem(USER_ID_KEY)
    let notifyApp: UserType | 'BSO' = userType
    if (userID === REACT_APP_BSO_USER_ID) {
        notifyApp = 'BSO'
    }
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    const [statusNotify, setStatusNotify] = useState(advertise?.attributes?.Notify)

    const currentUnit = useAppSelector(
        (state: RootState) => state.units.units
    )?.filter(
        (unit) => unit.id === Number(advertise?.attributes?.Unit?.data?.id)
    )[0]
    let unitNumber, buildName, location
    if (advertise?.attributes?.Name) {
        [unitNumber, buildName, location] = advertise?.attributes?.Name?.split(' <-> ')
    } else {
        [unitNumber, buildName, location] = [
            currentUnit?.attributes?.Number ?? 'Not number',
            currentUnit?.attributes?.Property?.data?.attributes?.Name ?? 'Not title',
            currentUnit?.attributes?.Property?.data?.attributes?.Area ?? 'Not location'
        ]
    }

    let startDateString = 'No date yet'
    let endDateString = 'No date yet'

    if (advertise?.attributes?.StartDate) {
        const [startDate, timeStartDate] = timeStringInScheduler(
            advertise?.attributes?.StartDate ?? ''
        ).split(',')
        const [dayS, mounthS, yearS] = startDate.split(' ')
        startDateString = `${dayS}  / ${mounthS} ${yearS}`
    }
    if (advertise?.attributes?.EndDate) {
        const [endDate, timeEndDate] = timeStringInScheduler(
            advertise?.attributes?.EndDate ?? ''
        ).split(',')
        const [dayE, mounthE, yearE] = endDate.split(' ')
        endDateString = `${dayE}  / ${mounthE} ${yearE}`
    }

    const dispatch = useAppDispatch()
    useEffect(() => {
        setStatusNotify(advertise?.attributes?.Notify)
    }, [advertise, dispatch])

    const createdAdvAgent = useAppSelector(
        (state) => state.user.allUsers
    )?.filter(
        (user) => user.id === Number(advertise?.attributes?.User?.data?.id)
    )[0]

    return (

        <Link
            to={`/${userTypeLowercase}/modalView/${notifyApp === 'BSO' ? 'magic_' : ''}advertise_modal/${advertise?.attributes?.Unit?.data?.id}/${advertise?.id}/tasks`} // `/agent/modalView/viewing_modal/${view?.attributes?.Unit?.data?.id}/${view?.id}/attention`
            className="text-decoration-none"
        >
            <div
                className={`d-flex flex-row row card shadow-sm g-0 p-2 px-3 m-0 align-items-center justify-content-between position-relative border border-1 border-${generateBgAndBorderCol(advertise).borderColor
                    } bg-light-${generateBgAndBorderCol(advertise).borderColor}`}
            >
                {statusNotify === notifyApp || statusNotify === 'Both'
                    ? (
                        <span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger">
                            !
                        </span>
                    )
                    : null}
                <div className="d-flex flex-column col-2  lh-1 ">
                    {isDev
                        ? notifyApp !== 'BSO' && <span className={`position-absolute badge  badge-light-${generateBgAndBorderCol(advertise).textColor} border border-${generateBgAndBorderCol(advertise).borderColor} text-${generateBgAndBorderCol(advertise).textColor} p-1`} style={{ top: '-7px', left: '-5px' }}>
                            {'BSO UNIT'}
                        </span>
                        : null}
                    <div className="w-50px d-flex flex-column align-items-center justify-content-start  px-2 col-2">
                        <span className="fw-normal fs-5x lh-1  text-dark">  {advertise?.attributes?.CreditsAmount}</span>
                        <span className="text-dark fs-6">Credits</span>
                    </div>
                </div>

                <div className="d-flex flex-column col-6">
                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'location_on'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>{location}{' '}</span>
                    </span>

                    <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'info'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>{unitNumber}, {buildName}</span>
                    </span>

                    {notifyApp === 'BSO' && <span className="fs-6 text-gray-800 d-flex align-items-center gap-2">
                        <Icon
                            name={'person'}
                            styles={{ minWidth: '12.9px' }}
                        />
                        <span className={'text-dots-app'}>
                            {`${String(createdAdvAgent?.ContactInfo?.FirstName ?? 'No')} ${String(createdAdvAgent?.ContactInfo?.FamilyName ?? 'Name')}`}
                        </span>
                    </span>}

                    <div className="fs-6 d-flex align-items-center py-1">
                        <span
                            className={`me-1 badge  h-10px min-w-10px w-10px badge-circle badge-outline badge-${generateBgAndBorderCol(advertise).borderColor} 
                                bg-light-${generateBgAndBorderCol(advertise).borderColor}`}
                        >
                            {' '}
                        </span>

                        <div className="d-flex flex-column ">
                            {advertise?.attributes?.Statuses?.map((el: any) => (
                                <div key={nanoid()} className="fs-6 fw-bold text-dark lh-1 ps-1">{el}</div>))}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column col-4 align-items-end lh-sm">
                    {advertise?.attributes?.StartDate
                        ? <>
                            <span className="fs-8 text-gray-700">Duration start</span>
                            <span className="fs-6 text-dark fw-bold mb-1">{startDateString}</span>
                        </>
                        : <>
                            <span className="fs-8 text-gray-700">Duration dates</span>
                            <span className="fs-7  text-dark fw-bold text-end"> Dates not set yet  </span>
                            <div className={`badge badge-light-${generateBgAndBorderCol(advertise).textColor} border border-${generateBgAndBorderCol(advertise).borderColor} text-${generateBgAndBorderCol(advertise).textColor} fs-8 px-2 d-inline mb-1`}>
                                {advertise?.attributes?.Statuses?.includes('In-process') ? "Upload unit's docs" : 'Set duration'}
                            </div>
                        </>
                    }
                    {advertise?.attributes?.EndDate &&
                        <>
                            <span className="fs-8 text-gray-700">Duration end</span>
                            <span className="fs-6 text-dark fw-bold">    {endDateString}</span></>
                    }
                </div>

            </div>

        </Link>

    )
}

export default AdvertiseCard
