import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'

import { Drawer, Modal } from 'antd'
import { type ISettings, type User } from '../../../../../store/slices/userSlice'
import Icon from '../../../../../app/icon/Icon'
import CloseIconBasic from '../../../../shared/elements/CloseIconBasic'
import { mainSwalDeal } from '../../../../../functions/swalOptions'
import { ava_blank } from '../../../../../urls'
import Avatar from '../../../../shared/elements/Avatar'

interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    currentUser: User
}

export default function MyProfileNEW({
    previewOpen,
    setPreviewOpen,
    currentUser
}: IProps): JSX.Element {
    const userCONTACTS = (currentUser)?.Type?.[0]
    const userContactsINFO = (currentUser)?.ContactInfo
    const userSettings = currentUser?.Settings as ISettings[]
    const userID = currentUser.id
    const navigate = useNavigate()
    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )
    const [wallpaper, setWallpaper] = useState<string>(`/assets/media/stock/s_${Math.floor(Math.random() * 5) + 1}.jpg`);
    const [wallpaperModalOpen, setWallpaperModalOpen] = useState<boolean>(false);

    const wallpapers = [
        '/assets/media/stock/s_1.jpg',
        '/assets/media/stock/s_2.jpg',
        '/assets/media/stock/s_3.jpg',
        '/assets/media/stock/s_4.jpg',
        '/assets/media/stock/s_5.jpg',
    ];


    const upgradeAvatar = (): void => {
        void mainSwalDeal('Want to change your avatar?', undefined, 'Yes, I do', 'Not now').then(async (result: any) => {
            if (result.isConfirmed === true) {
                navigate(`/agent/modal/agent_avatar/${userID}/${userID}`)
            }
        })
    }

    let fullName = (userContactsINFO?.FirstName ? userContactsINFO.FirstName : userCONTACTS?.Name) + ' ' +
        (userContactsINFO?.FamilyName ? userContactsINFO.FamilyName : '')
    fullName === 'undefined ' ? fullName = 'No name' : null


    const selectWallpaper = (selectedWallpaper: string) => {
        setWallpaper(selectedWallpaper);
        setWallpaperModalOpen(false);
    };
    // Math.floor(Math.random() * 2) + 1 === 2 ? {} :
    return (
        <>
            <div className="d-flex flex-stack bg-white p-4 rounded rounded-4 shadow-sm "

                style={
                    {
                        backgroundImage: `url(${wallpaper})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'relative'
                    }
                }>
                <div className="overlay rounded rounded-4"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 0 }}></div>
                <div style={{ position: 'relative', zIndex: 1, width: '100%' }}>
                    <div className=' d-flex justify-content-between align-items-start'>
                        <div className="symbol symbol-40px me-5" onClick={() => {
                            setPreviewOpen(true)
                        }}>
                            <Avatar image={imageUrl ?? undefined} name={fullName} size={'7rem'} />
                        </div>
                        <span className=""
                            onClick={() => {
                                setWallpaperModalOpen(true);
                            }}
                        > <Icon name={'wallpaper_slideshow'} className={'fs-4x text-gray-800'} /></span>
                    </div>
                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="text-gray-800 text-hover-primary fs-3 fw-bold" style={{ wordBreak: 'break-word' }}>
                                    {fullName}
                                </span>
                            </div>
                            <span className="text-gray-900 fw-semibold d-block fs-5">{userSettings?.[0].Company ?? "Company LLC."}</span>
                            <div className="text-gray-800 fs-6  ">
                                {currentUser.About ?? "Hey there!I am using BSO Club!"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                height={'auto'}
                styles={{
                    body: {
                        maxHeight: '88vh',
                        marginBottom: '3px',
                        paddingTop: '3px',
                        marginTop: '6px',
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }
                }}
                placement={'bottom'}
                closable={false}
                onClose={() => { setWallpaperModalOpen(false); }}
                open={wallpaperModalOpen}
                rootStyle={{ zIndex: '1100' }}
                key={'select-wallpaper-driver-bricks'}
                maskClosable={true}
            >
                <div key={'dashboard-child-driver-bricks'}
                    className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                >
                    <div className="w-100 mw-450px  mx-auto">
                        <div className="d-flex flex-wrap">
                            {wallpapers.map((wp, index) => (
                                <div key={index} className="m-2 mx-auto" style={{ cursor: 'pointer' }} onClick={() => { selectWallpaper(wp); }}>
                                    <img src={wp} alt={`wallpaper-${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '0.5rem' }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Drawer>




            <Modal
                open={previewOpen}
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false)
                }}

                closeIcon={
                    <CloseIconBasic onCancel={() => {
                        setPreviewOpen(false)
                    }} cls={''} style={{ top: '-5px', right: '-5px' }} />
                }
                centered
                styles={{
                    body: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50vh'
                    }
                }}
            >
                <img
                    alt="example"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={imageUrl}
                    className='position-relative object-fit-cover w-100'
                />
                <div style={{ top: '11px', left: '12px' }} className='position-absolute' onClick={upgradeAvatar}>
                    <div className={'p-2'} style={{ borderRadius: '1rem', background: '#00000066' }}>
                        <i className="ki-duotone ki-pencil fs-2x p-1">
                            <span className="path1 text-white"></span><span className="path2 text-white"></span>
                        </i>

                    </div>

                </div>
            </Modal>



        </>
    )
}
