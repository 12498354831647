/* eslint-disable */
export default {
  'ac_unit_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-246 337-145q-11 11-27.5 11T282-146q-12-11-12-27.5t12-28.5l158-158v-80h-80L201-281q-11 11-27.5 11T145-282q-11-11-11-27.5t11-27.5l101-103H119q-17 0-28-11.5T80-480q0-17 11.5-28.5T120-520h126L145-622q-11-11-11-27.5t12-28.5q11-11 27.5-11t28.5 11l158 158h80v-80L281-758q-11-11-11-27.5t12-28.5q11-11 27.5-11t27.5 11l103 100v-126q0-17 11.5-28.5T480-880q17 0 28.5 11.5T520-840v126l102-100q11-11 27.5-11t28.5 11q11 12 11 28.5T678-758L520-600v80h80l158-158q11-11 27.5-11t28.5 12q11 11 11 27.5T814-622L714-520h126q17 0 28.5 11.5T880-480q0 17-11.5 28.5T840-440H714l100 103q11 11 11 27.5T814-282q-12 12-28.5 12T758-282L600-440h-80v80l158 159q11 11 11 27.5T677-145q-11 11-27.5 11T622-145L520-246v127q0 17-11.5 28T480-80q-17 0-28.5-11.5T440-120v-126Z" /></svg>,
  'ac_unit_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M446.667-265.334 326.333-145.667q-9 9-22.833 9-13.833 0-23.5-9.333-10-9.667-10-23.5t10-23.833L446.667-360v-86.667H360l-165 165q-9 9-22.833 9.334-13.834.333-23.834-9-9.666-9.667-10-23.5-.333-13.833 10-24.167l117.001-117.667H112.333q-13.666 0-23-9.5Q80-465.667 80-480q0-13.667 9.5-23.5t23.833-9.833h152.001L145.667-632.667q-9.667-9.667-9.667-23.5T146-680q9.667-9.667 23.5-9.667T193.333-680L360-513.333h86.667v-87.334l-165-164q-9-9-9-22.833 0-13.833 9.333-23.833Q291.667-821 305.5-821t24.166 9.667l117.001 116v-151.334q0-13.666 9.5-23.5Q465.667-880 480-880q13.667 0 23.5 9.833 9.833 9.834 9.833 23.5v151.334L632.667-814q9.667-9.667 23.5-9.667T680-814q9 10 9 23.833 0 13.833-9.667 23.5l-166 166v87.334h87.334L765.334-678q8.999-9 22.833-9Q802-687 812-677.667q9.667 9.667 9.667 23.5t-9.667 23.5L695.333-513.333h151.334q13.666 0 23.5 9.833Q880-493.667 880-480q0 14.333-9.833 23.833-9.834 9.5-23.5 9.5H695.333l118 120.334q9.667 9 9.667 22.833 0 13.833-9.667 22.833Q804-270 790.167-270t-23.5-10l-166-166.667h-87.334V-360L680-191.666q9.667 9 9.667 22.833 0 13.833-10 23.166-9 10.334-22.833 10.334-13.834 0-23.5-9.667L513.333-265.334v153.001q0 13.666-9.833 23Q493.667-80 480-80q-14.333 0-23.833-9.5-9.5-9.5-9.5-23.833v-152.001Z" /></svg>,
  'ac_unit_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M450-275 321-146q-8 8-20.5 8t-21.5-8q-9-9-9-21.5t9-21.5l171-171v-90h-90L192-282q-8 8-20.5 8.5T150-281q-9-9-9.5-21.5T150-325l125-125H109q-12 0-20.5-8.5T80-480q0-12 8.5-21t21.5-9h165L146-638q-9-9-9-21.5t9-21.5q9-9 21.5-9t21.5 9l171 171h90v-91L282-768q-8-8-8-20.5t8-21.5q9-9 21.5-9t22.5 9l124 124v-164q0-12 8.5-21t21.5-9q12 0 21 9t9 21v164l128-128q9-9 21.5-9t21.5 9q8 9 8 21.5t-9 21.5L510-601v91h91l168-168q8-8 20.5-8t21.5 8q9 9 9 21.5t-9 21.5L686-510h164q12 0 21 9t9 21q0 13-9 21.5t-21 8.5H686l127 129q9 8 9 20.5t-9 20.5q-8 10-20.5 10t-21.5-9L601-450h-91v90l171 173q9 8 9 20.5t-9 20.5q-8 10-20.5 10t-21.5-9L510-275v166q0 12-9 20.5T480-80q-13 0-21.5-8.5T450-110v-165Z" /></svg>,
  'account_circle_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z" /></svg>,
  'account_circle_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M226-262q59-42.333 121.333-65.5Q409.667-350.666 480-350.666T613-327.5q62.667 23.167 121.667 65.5 41-49.667 59.833-103.667 18.834-54 18.834-114.333 0-141-96.167-237.167T480-813.334q-141 0-237.167 96.167T146.666-480q0 60.333 19.167 114.333T226-262Zm253.876-184.667q-58.209 0-98.043-39.957Q342-526.581 342-584.791q0-58.209 39.957-98.042 39.958-39.834 98.167-39.834t98.043 39.958Q618-642.752 618-584.543q0 58.21-39.957 98.043-39.958 39.833-98.167 39.833ZM480.312-80q-82.645 0-155.645-31.5-73-31.5-127.334-85.833Q143-251.667 111.5-324.511T80-480.177q0-82.823 31.5-155.49 31.5-72.666 85.833-127Q251.667-817 324.511-848.5T480.177-880q82.823 0 155.49 31.5 72.666 31.5 127 85.833Q817-708.333 848.5-635.645T880-480.312q0 82.645-31.5 155.645-31.5 73-85.833 127.334Q708.333-143 635.645-111.5T480.312-80ZM480-146.666q54.333 0 105-15.834 50.667-15.833 97.667-52.167-47-33.666-98-51.5Q533.667-284 480-284t-104.667 17.833q-51 17.834-98 51.5 47 36.334 97.667 52.167 50.667 15.834 105 15.834Zm0-366.667q31.333 0 51.334-20 20-20 20-51.334 0-31.333-20-51.333-20.001-20-51.334-20-31.333 0-51.334 20-20 20-20 51.333 0 31.334 20 51.334 20.001 20 51.334 20Zm0-71.334Zm0 369.334Z" /></svg>,
  'add_ad_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M720-160v-120H600v-80h120v-120h80v120h120v80H800v120h-80Zm-600 40q-33 0-56.5-23.5T40-200v-560q0-33 23.5-56.5T120-840h560q33 0 56.5 23.5T760-760v200h-80v-80H120v440h520v80H120Zm0-600h560v-40H120v40Zm0 0v-40 40Z" /></svg>,
  'add_circle_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440v120q0 17 11.5 28.5T480-280q17 0 28.5-11.5T520-320v-120h120q17 0 28.5-11.5T680-480q0-17-11.5-28.5T640-520H520v-120q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v120H320q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440h120Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'add_circle_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M448.67-444v130.67q0 14.16 9.61 23.75 9.62 9.58 23.84 9.58 14.21 0 23.71-9.58 9.5-9.59 9.5-23.75V-444h131.34q14.16 0 23.75-9.62 9.58-9.61 9.58-23.83 0-14.22-9.58-23.72-9.59-9.5-23.75-9.5H515.33v-136q0-14.16-9.61-23.75-9.62-9.58-23.84-9.58-14.21 0-23.71 9.58-9.5 9.59-9.5 23.75v136H313.33q-14.16 0-23.75 9.62-9.58 9.62-9.58 23.83 0 14.22 9.58 23.72 9.59 9.5 23.75 9.5h135.34Zm31.51 364q-82.83 0-155.67-31.5-72.84-31.5-127.18-85.83Q143-251.67 111.5-324.56T80-480.33q0-82.88 31.5-155.78Q143-709 197.33-763q54.34-54 127.23-85.5T480.33-880q82.88 0 155.78 31.5Q709-817 763-763t85.5 127Q880-563 880-480.18q0 82.83-31.5 155.67Q817-251.67 763-197.46q-54 54.21-127 85.84Q563-80 480.18-80Zm.15-66.67q139 0 236-97.33t97-236.33q0-139-96.87-236-96.88-97-236.46-97-138.67 0-236 96.87-97.33 96.88-97.33 236.46 0 138.67 97.33 236 97.33 97.33 236.33 97.33ZM480-480Z" /></svg>,
  'add_circle_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M453-446v136q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63 12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-136h137q12.75 0 21.38-8.68 8.62-8.67 8.62-21.5 0-12.82-8.62-21.32-8.63-8.5-21.38-8.5H513v-144q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v144H310q-12.75 0-21.37 8.68-8.63 8.67-8.63 21.5 0 12.82 8.63 21.32 8.62 8.5 21.37 8.5h143Zm27.27 366q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" /></svg>,
  'add_circle_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440v120q0 17 11.5 28.5T480-280q17 0 28.5-11.5T520-320v-120h120q17 0 28.5-11.5T680-480q0-17-11.5-28.5T640-520H520v-120q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v120H320q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440h120Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" /></svg>,
  'add_circle_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M448.67-444v130.67q0 14.16 9.61 23.75 9.62 9.58 23.84 9.58 14.21 0 23.71-9.58 9.5-9.59 9.5-23.75V-444h131.34q14.16 0 23.75-9.62 9.58-9.61 9.58-23.83 0-14.22-9.58-23.72-9.59-9.5-23.75-9.5H515.33v-136q0-14.16-9.61-23.75-9.62-9.58-23.84-9.58-14.21 0-23.71 9.58-9.5 9.59-9.5 23.75v136H313.33q-14.16 0-23.75 9.62-9.58 9.62-9.58 23.83 0 14.22 9.58 23.72 9.59 9.5 23.75 9.5h135.34Zm31.51 364q-82.83 0-155.67-31.5-72.84-31.5-127.18-85.83Q143-251.67 111.5-324.56T80-480.33q0-82.88 31.5-155.78Q143-709 197.33-763q54.34-54 127.23-85.5T480.33-880q82.88 0 155.78 31.5Q709-817 763-763t85.5 127Q880-563 880-480.18q0 82.83-31.5 155.67Q817-251.67 763-197.46q-54 54.21-127 85.84Q563-80 480.18-80Z" /></svg>,
  'add_circle_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M453-446v136q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63 12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-136h137q12.75 0 21.38-8.68 8.62-8.67 8.62-21.5 0-12.82-8.62-21.32-8.63-8.5-21.38-8.5H513v-144q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v144H310q-12.75 0-21.37 8.68-8.63 8.67-8.63 21.5 0 12.82 8.63 21.32 8.62 8.5 21.37 8.5h143Zm27.27 366q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Z" /></svg>,
  'add_location_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-400h80v-120h120v-80H520v-120h-80v120H320v80h120v120Zm40 214q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" /></svg>,
  'add_location_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M446.667-526.667V-440q0 14.167 9.617 23.75t23.833 9.583q14.216 0 23.716-9.583 9.5-9.583 9.5-23.75v-86.667H600q14.167 0 23.75-9.617t9.583-23.833q0-14.216-9.583-23.716-9.583-9.5-23.75-9.5h-86.667V-680q0-14.167-9.617-23.75t-23.833-9.583q-14.216 0-23.716 9.583-9.5 9.583-9.5 23.75v86.667H360q-14.167 0-23.75 9.617t-9.583 23.833q0 14.216 9.583 23.716 9.583 9.5 23.75 9.5h86.667ZM480-167.999Q609.334-286 671.334-382.166q62-96.167 62-169.834 0-114.86-73.361-188.097Q586.611-813.334 480-813.334q-106.611 0-179.973 73.237Q226.666-666.86 226.666-552q0 73.667 63 169.834Q352.667-286 480-167.999Zm-.167 65.666q-11.833 0-23.5-4-11.666-4-20.666-12.667-49.666-45.333-99-97.332-49.333-52-88.5-107.834Q209-379.999 184.5-437.833 160-495.666 160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 56.334-24.5 114.167-24.5 57.834-63.667 113.667-39.167 55.834-88.5 107.834-49.334 51.999-99 97.332-9 8.667-20.833 12.667-11.833 4-23.667 4ZM480-560Z" /></svg>,
  'arrow_circle_left_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M472-440h128q17 0 28.5-11.5T640-480q0-17-11.5-28.5T600-520H472l36-36q11-11 11-28t-11-28q-11-11-28-11t-28 11L348-508q-12 12-12 28t12 28l104 104q11 11 28 11t28-11q11-11 11-28t-11-28l-36-36Zm8 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'arrow_circle_left_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M456-446.667h144q14.333 0 23.833-9.5 9.5-9.5 9.5-23.833 0-14.333-9.5-23.833-9.5-9.5-23.833-9.5H456l47.333-47.334Q513-570.333 513-584q0-13.667-9.667-23.333Q493.667-617 480-617q-13.667 0-23.333 9.667l-104 104q-10 10-10 23.333 0 13.333 10 23.333l104 104Q466.333-343 480-343q13.667 0 23.333-9.667Q513-362.333 513-376q0-13.667-9.667-23.333L456-446.667ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Zm0-66.666q139.333 0 236.334-97.334 97-97.333 97-236 0-139.333-97-236.334-97.001-97-236.334-97-138.667 0-236 97Q146.666-619.333 146.666-480q0 138.667 97.334 236 97.333 97.334 236 97.334ZM480-480Z" /></svg>,
  'arrow_circle_left_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M448-450h152q13 0 21.5-8.5T630-480q0-13-8.5-21.5T600-510H448l53-53q9-9 9-21t-9-21q-9-9-21-9t-21 9L355-501q-9 9-9 21t9 21l104 104q9 9 21 9t21-9q9-9 9-21t-9-21l-53-53Zm32 370q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'arrow_circle_right_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m488-440-36 36q-11 11-11 28t11 28q11 11 28 11t28-11l104-104q12-12 12-28t-12-28L508-612q-11-11-28-11t-28 11q-11 11-11 28t11 28l36 36H360q-17 0-28.5 11.5T320-480q0 17 11.5 28.5T360-440h128Zm-8 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'arrow_circle_right_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m504-446.667-47.333 47.334Q447-389.667 447-376q0 13.667 9.667 23.333Q466.333-343 480-343q13.667 0 23.333-9.667l104-104q10-10 10-23.333 0-13.333-10-23.333l-104-104Q493.667-617 480-617q-13.667 0-23.333 9.667Q447-597.667 447-584q0 13.667 9.667 23.333L504-513.333H360q-14.333 0-23.833 9.5-9.5 9.5-9.5 23.833 0 14.333 9.5 23.833 9.5 9.5 23.833 9.5h144ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Zm0-66.666q139.333 0 236.334-97.334 97-97.333 97-236 0-139.333-97-236.334-97.001-97-236.334-97-138.667 0-236 97Q146.666-619.333 146.666-480q0 138.667 97.334 236 97.333 97.334 236 97.334ZM480-480Z" /></svg>,
  'arrow_circle_right_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m512-450-53 53q-9 9-9 21t9 21q9 9 21 9t21-9l104-104q9-9 9-21t-9-21L501-605q-9-9-21-9t-21 9q-9 9-9 21t9 21l53 53H360q-13 0-21.5 8.5T330-480q0 13 8.5 21.5T360-450h152ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'attach_file_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M720-330q0 104-73 177T470-80q-104 0-177-73t-73-177v-370q0-75 52.5-127.5T400-880q75 0 127.5 52.5T580-700v350q0 46-32 78t-78 32q-46 0-78-32t-32-78v-330q0-17 11.5-28.5T400-720q17 0 28.5 11.5T440-680v330q0 13 8.5 21.5T470-320q13 0 21.5-8.5T500-350v-350q-1-42-29.5-71T400-800q-42 0-71 29t-29 71v370q-1 71 49 120.5T470-160q70 0 119-49.5T640-330v-350q0-17 11.5-28.5T680-720q17 0 28.5 11.5T720-680v350Z" /></svg>,
  'attach_file_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M725.333-327.333q0 103.333-72.45 175.333T477.217-80Q374-80 301.334-152q-72.667-72-72.667-175.333V-704q0-73.333 51.833-124.667Q332.334-880 405.333-880q73 0 124.834 51.333Q582-777.333 582-704v356.667q0 43.333-30.667 74-30.666 30.666-74.333 30.666-43.666 0-74.333-30.448Q372-303.564 372-347.333V-684q0-14.167 9.617-23.75t23.833-9.583q14.217 0 23.717 9.583 9.5 9.583 9.5 23.75v336.667q0 15.666 11.166 26.833 11.167 11.167 27.594 11.167 16.426 0 27.166-11.167 10.741-11.167 10.741-26.833V-704q-.334-46-32.167-77.667-31.833-31.667-77.974-31.667-46.141 0-78.001 31.707Q295.333-749.92 295.333-704v376.667q-.333 75.667 52.981 128.167t129.02 52.5q75.278 0 127.972-52.5 52.695-52.5 53.361-128.167V-684q0-14.167 9.617-23.75t23.833-9.583q14.217 0 23.717 9.583 9.499 9.583 9.499 23.75v356.667Z" /></svg>,
  'badge_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-240h240v-18q0-17-9.5-31.5T444-312q-20-9-40.5-13.5T360-330q-23 0-43.5 4.5T276-312q-17 8-26.5 22.5T240-258v18Zm350-60h100q13 0 21.5-8.5T720-330q0-13-8.5-21.5T690-360H590q-13 0-21.5 8.5T560-330q0 13 8.5 21.5T590-300Zm-230-60q25 0 42.5-17.5T420-420q0-25-17.5-42.5T360-480q-25 0-42.5 17.5T300-420q0 25 17.5 42.5T360-360Zm230-60h100q13 0 21.5-8.5T720-450q0-13-8.5-21.5T690-480H590q-13 0-21.5 8.5T560-450q0 13 8.5 21.5T590-420ZM160-80q-33 0-56.5-23.5T80-160v-440q0-33 23.5-56.5T160-680h200v-120q0-33 23.5-56.5T440-880h80q33 0 56.5 23.5T600-800v120h200q33 0 56.5 23.5T880-600v440q0 33-23.5 56.5T800-80H160Zm0-80h640v-440H600q0 33-23.5 56.5T520-520h-80q-33 0-56.5-23.5T360-600H160v440Zm280-440h80v-200h-80v200Zm40 220Z" /></svg>,
  'badge_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M234.667-244.667H474V-260q0-17.667-9.167-31.833Q455.667-306 440.667-312q-28.001-10.333-46.834-14.167Q375-330 356-330q-20.333 0-41.5 4.5T268.666-312q-15.666 6-24.833 20.167-9.166 14.166-9.166 31.833v15.333ZM592-309.334h113.334q11.555 0 19.111-7.577Q732-324.489 732-336.078q0-11.589-7.555-19.089-7.556-7.5-19.111-7.5H592q-11.555 0-19.111 7.578-7.556 7.577-7.556 19.166t7.556 19.089q7.556 7.5 19.111 7.5Zm-236-53.333q23.333 0 39.666-16.333Q412-395.333 412-418.667q0-23.333-16.334-39.666-16.333-16.334-39.666-16.334t-39.667 16.334Q300-442 300-418.667q0 23.334 16.333 39.667 16.334 16.333 39.667 16.333Zm236-62h113.334q11.555 0 19.111-7.577Q732-439.822 732-451.411q0-11.589-7.555-19.089-7.556-7.5-19.111-7.5H592q-11.555 0-19.111 7.578-7.556 7.577-7.556 19.166t7.556 19.089q7.556 7.5 19.111 7.5ZM146.666-80q-27 0-46.833-19.833T80-146.666v-466.668q0-27 19.833-46.833T146.666-680h233.335v-133.334q0-27 19.833-46.833T446.941-880h66.118q27.274 0 47.107 19.833t19.833 46.833V-680h233.335q27 0 46.833 19.833T880-613.334v466.668q0 27-19.833 46.833T813.334-80H146.666Zm0-66.666h666.668v-466.668H579.999v20.001q0 29.666-19.833 48.166-19.833 18.5-47.107 18.5h-66.118q-27.274 0-47.107-18.5t-19.833-48.166v-20.001H146.666v466.668Zm300.001-446.667h66.666v-220.001h-66.666v220.001ZM480-380Z" /></svg>,
  'badge_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M232-247h239v-14q0-18-9-32t-23-19q-32-11-50-14.5t-35-3.5q-19 0-40.5 4.5T265-312q-15 5-24 19t-9 32v14Zm361-67h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q738-350 730.917-357q-7.084-7-17.917-7H593q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q568-328 575.083-321q7.084 7 17.917 7Zm-239-50q22.5 0 38.25-15.75T408-418q0-22.5-15.75-38.25T354-472q-22.5 0-38.25 15.75T300-418q0 22.5 15.75 38.25T354-364Zm239-63h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q738-463 730.917-470q-7.084-7-17.917-7H593q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q568-441 575.083-434q7.084 7 17.917 7ZM140-80q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h250v-140q0-24 18-42t42.411-18h59.178Q534-880 552-862t18 42v140h250q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm0-60h680v-480H570v30q0 28-18 44t-42.411 16h-59.178Q426-530 408-546t-18-44v-30H140v480Zm310-450h60v-230h-60v230Zm30 210Z" /></svg>,
  'bathtub_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-600q-33 0-56.5-23.5T200-680q0-33 23.5-56.5T280-760q33 0 56.5 23.5T360-680q0 33-23.5 56.5T280-600ZM200-80q-17 0-28.5-11.5T160-120q-33 0-56.5-23.5T80-200v-200q0-17 11.5-28.5T120-440h80v-30q0-38 26-64t64-26q20 0 37 8t31 22l56 62q8 8 15.5 15t16.5 13h274v-326q0-14-10-24t-24-10q-6 0-11.5 2.5T664-790l-50 50q5 17 2 33.5T604-676L494-788q14-9 30-11.5t32 3.5l50-50q16-16 36.5-25t43.5-9q48 0 81 33t33 81v326h40q17 0 28.5 11.5T880-400v200q0 33-23.5 56.5T800-120q0 17-11.5 28.5T760-80H200Zm-40-120h640v-160H160v160Zm0 0h640-640Z" /></svg>,
  'bathtub_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M275.386-599.333q-31.053 0-53.219-22.114Q200-643.56 200-674.613q0-31.053 22.114-53.22Q244.227-750 275.28-750q31.053 0 53.22 22.114 22.166 22.113 22.166 53.166 0 31.053-22.113 53.22-22.114 22.167-53.167 22.167ZM190.55-80Q173-80 159.833-91.5 146.666-103 146.666-120q-27.5 0-47.083-19.583T80-186.666v-220.001q0-14.166 9.583-23.75Q99.167-440 113.333-440H200v-28.529q0-36.138 25.333-61.471 25.334-25.333 61.586-25.333Q306-555.333 323-548q17 7.333 30.333 21.333l53.333 60q7.334 8 14.834 14.667t15.833 12h296.001v-331.333q0-17.295-11.667-29.648-11.667-12.353-29-12.353-8.588 0-16.461 2.167-7.873 2.167-14.206 8.5l-51.333 51.333q5 17 1.666 34.167Q609-700 600-684.667l-104.667-102q15.624-10.285 33.479-13.143Q546.667-802.667 564-796l51.334-50.667Q630.667-862 650.526-871q19.86-9 42.141-9 45.193 0 76.263 31.667 31.07 31.666 31.07 77V-440h46.667q14.166 0 23.75 9.583 9.583 9.584 9.583 23.75v220.001q0 27.5-19.583 47.083T813.334-120q0 17-13.167 28.5t-31.5 11.5H190.55Zm-43.884-106.666h666.668v-186.668H146.666v186.668Zm0 0h666.668-666.668Z" /></svg>,
  'bed_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M80-240v-200q0-27 11-49t29-39v-112q0-50 35-85t85-35h160q23 0 43 8.5t37 23.5q17-15 37-23.5t43-8.5h160q50 0 85 35t35 85v112q18 17 29 39t11 49v200q0 17-11.5 28.5T840-200q-17 0-28.5-11.5T800-240v-40H160v40q0 17-11.5 28.5T120-200q-17 0-28.5-11.5T80-240Zm440-320h240v-80q0-17-11.5-28.5T720-680H560q-17 0-28.5 11.5T520-640v80Zm-320 0h240v-80q0-17-11.5-28.5T400-680H240q-17 0-28.5 11.5T200-640v80Zm-40 200h640v-80q0-17-11.5-28.5T760-480H200q-17 0-28.5 11.5T160-440v80Zm640 0H160h640Z" /></svg>,
  'bed_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M80-233.333V-450q0-25.667 10.333-47.667 10.334-22 29.667-37v-114.667Q120-696 152-728t78.666-32H404q22.333 0 41.667 9.5Q465-741 480-724.667 495-741 514-750.5t41.333-9.5h173.334q46.666 0 79 32Q840-696 840-649.334v114.667q19.333 15 29.667 37Q880-475.667 880-450v216.667q0 14.333-9.5 23.833-9.5 9.5-23.833 9.5-14.333 0-23.833-9.5-9.5-9.5-9.5-23.833V-280H146.666v46.667q0 14.333-9.5 23.833-9.5 9.5-23.833 9.5Q99-200 89.5-209.5 80-219 80-233.333Zm433.333-323.334h260.001v-92.667q0-19-12.834-31.5-12.833-12.5-31.833-12.5H553.333q-17 0-28.5 13.167t-11.5 30.833v92.667Zm-326.667 0h260.001v-92.667q0-17.666-11.5-30.833-11.5-13.167-28.5-13.167H230.666q-18.333 0-31.166 12.834-12.834 12.833-12.834 31.166v92.667Zm-40 210.001h666.668V-450q0-17-11.5-28.5t-28.5-11.5H186.666q-17 0-28.5 11.5t-11.5 28.5v103.334Zm666.668 0H146.666h666.668Z" /></svg>,
  'bookmark_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-240-168 72q-40 17-76-6.5T200-241v-519q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v519q0 43-36 66.5t-76 6.5l-168-72Zm0-88 200 86v-518H280v518l200-86Zm0-432H280h400-200Z" /></svg>,
  'bookmark_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m480-240-186.667 80Q260-145.667 230-165.337t-30-55.662v-555.668q0-27 19.833-46.833t46.833-19.833h426.668q27 0 46.833 19.833T760-776.667v555.668q0 35.992-30 55.662T666.667-160L480-240Zm0-72 213.334 90.667v-555.334H266.666v555.334L480-312Zm0-464.667H266.666h426.668H480Z" /></svg>,
  'bookmark_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-240-168 72q-40 17-76-6.5T200-241v-519q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v519q0 43-36 66.5t-76 6.5l-168-72Z" /></svg>,
  'bookmark_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m480-240-186.667 80Q260-145.667 230-165.5t-30-55.499v-555.668q0-27 19.833-46.833t46.833-19.833h426.668q27 0 46.833 19.833T760-776.667v555.668q0 35.666-30 55.499-30 19.833-63.333 5.5L480-240Z" /></svg>,
  'bookmark_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m480-240-196 84q-30 13-57-5t-27-50v-574q0-24 18-42t42-18h440q24 0 42 18t18 42v574q0 32-27 50t-57 5l-196-84Z" /></svg>,
  'build_circle_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m620-284 56-56q6-6 6-14t-6-14L540-505q4-11 6-22t2-25q0-57-40.5-97.5T410-690q-17 0-34 4.5T343-673l94 94-56 56-94-94q-8 16-12.5 33t-4.5 34q0 57 40.5 97.5T408-412q13 0 24.5-2t22.5-6l137 136q6 6 14 6t14-6ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'build_circle_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed"><path d="m635.33-276.67 48-46q6.67-6.66 6.67-15 0-8.33-6.67-15l-146.66-147q4.66-13 7.33-25.33 2.67-12.33 2.67-26.33 0-57.67-40.84-98.5-40.83-40.84-98.5-40.84-17 0-34 4.84-17 4.83-33 13.5l90 89.33-54.66 52.67-88.67-88q-8.67 16-13.17 33t-4.5 34.66q0 57 40.17 96.84Q349.67-414 406.67-414q13.66 0 26.5-2.33 12.83-2.34 25.83-7l146.33 146.66q6 6 15 6t15-6ZM480-80q-82.33 0-155.33-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.67T80-480q0-83 31.5-156t85.83-127q54.34-54 127.34-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.33-31.5 155.33-31.5 73-85.5 127.34Q709-143 636-111.5T480-80Zm0-66.67q139.33 0 236.33-97.33t97-236q0-139.33-97-236.33t-236.33-97q-138.67 0-236 97-97.33 97-97.33 236.33 0 138.67 97.33 236 97.33 97.33 236 97.33ZM480-480Z" /></svg>,
  'build_circle_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#e8eaed"><path d="m643-273 44-41q7-7 7-15.5t-7-15.5L535-497q5-14 8-27t3-27q0-58-41-99t-99-41q-17 0-34 5t-33 14l88 87-54 51-86-85q-9 16-13.5 33t-4.5 35q0 57 40 96.5t97 39.5q14 0 27.5-2.5T461-425l151 152q6 6 15.5 6t15.5-6ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'build_circle_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m620-284 56-56q6-6 6-14t-6-14L540-505q4-11 6-22t2-25q0-57-40.5-97.5T410-690q-17 0-34 4.5T343-673l94 94-56 56-94-94q-8 16-12.5 33t-4.5 34q0 57 40.5 97.5T408-412q13 0 24.5-2t22.5-6l137 136q6 6 14 6t14-6ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" /></svg>,
  'build_circle_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed"><path d="m635.33-276.67 48-46q6.67-6.66 6.67-15 0-8.33-6.67-15l-146.66-147q4.66-13 7.33-25.33 2.67-12.33 2.67-26.33 0-57.67-40.84-98.5-40.83-40.84-98.5-40.84-17 0-34 4.84-17 4.83-33 13.5l90 89.33-54.66 52.67-88.67-88q-8.67 16-13.17 33t-4.5 34.66q0 57 40.17 96.84Q349.67-414 406.67-414q13.66 0 26.5-2.33 12.83-2.34 25.83-7l146.33 146.66q6 6 15 6t15-6ZM480-80q-82.33 0-155.33-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.67T80-480q0-83 31.5-156t85.83-127q54.34-54 127.34-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.33-31.5 155.33-31.5 73-85.5 127.34Q709-143 636-111.5T480-80Z" /></svg>,
  'build_circle_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#e8eaed"><path d="m643-273 44-41q7-7 7-15.5t-7-15.5L535-497q5-14 8-27t3-27q0-58-41-99t-99-41q-17 0-34 5t-33 14l88 87-54 51-86-85q-9 16-13.5 33t-4.5 35q0 57 40 96.5t97 39.5q14 0 27.5-2.5T461-425l151 152q6 6 15.5 6t15.5-6ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z" /></svg>,
  'call_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" /></svg>,
  'call_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M796-120q-119 0-240-55.5T333-333Q231-435 175.5-556T120-796q0-18.857 12.571-31.429Q145.143-840 164-840h147.333q14 0 24.333 9.833Q346-820.333 349.333-806l26.619 130.427q2.048 14.906-.619 26.239-2.667 11.334-10.819 19.485L265.666-530q24 41.667 52.5 78.5T381-381.333q35 35.667 73.667 65.5T536-262.666l94.667-96.667q9.667-10.333 23.267-14.5Q667.535-378 680.667-376L806-349.333q14.667 4 24.333 15.53Q840-322.274 840-308v144q0 18.857-12.571 31.429Q814.857-120 796-120ZM233-592l76-76.667-21-104.667H187q3 41.667 13.666 86Q211.333-643 233-592Zm365.333 361.334q40.334 18.333 85.834 29.666 45.5 11.334 89.167 13.667V-288l-100-20.333-75.001 77.667ZM233-592Zm365.333 361.334Z" /></svg>,
  'call_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M795-120q-116 0-236.5-56T335-335Q232-438 176-558.5T120-795q0-19.286 12.857-32.143T165-840h140q14 0 24 10t14 25l26.929 125.641Q372-665 369.5-653.5q-2.5 11.5-10.729 19.726L259-533q26 44 55 82t64 72q37 38 78 69.5t86 55.5l95-98q10-11 23.151-15T686-369l119 26q15 4 25 16.044T840-300v135q0 19.286-12.857 32.143T795-120ZM229-588l81-82-23-110H180q2 42 13.5 88.5T229-588Zm369 363q41 19 89 31t93 14v-107l-103-21-79 83ZM229-588Zm369 363Z" /></svg>,
  'cancel_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-424 116 116q11 11 28 11t28-11q11-11 11-28t-11-28L536-480l116-116q11-11 11-28t-11-28q-11-11-28-11t-28 11L480-536 364-652q-11-11-28-11t-28 11q-11 11-11 28t11 28l116 116-116 116q-11 11-11 28t11 28q11 11 28 11t28-11l116-116Zm0 344q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'cancel_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m480-433.334 124.667 124.667Q614.334-299 628-299q13.667 0 23.333-9.667Q661-318.333 661-332q0-13.666-9.667-23.333L526.666-480l124.667-124.667Q661-614.334 661-628q0-13.667-9.667-23.333Q641.667-661 628-661q-13.666 0-23.333 9.667L480-526.666 355.333-651.333Q345.666-661 332-661q-13.667 0-23.333 9.667Q299-641.667 299-628q0 13.666 9.667 23.333L433.334-480 308.667-355.333Q299-345.666 299-332q0 13.667 9.667 23.333Q318.333-299 332-299q13.666 0 23.333-9.667L480-433.334ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Zm0-66.666q139.333 0 236.334-97.334 97-97.333 97-236 0-139.333-97-236.334-97.001-97-236.334-97-138.667 0-236 97Q146.666-619.333 146.666-480q0 138.667 97.334 236 97.333 97.334 236 97.334ZM480-480Z" /></svg>,
  'cancel_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m480-438 129 129q9 9 21 9t21-9q9-9 9-21t-9-21L522-480l129-129q9-9 9-21t-9-21q-9-9-21-9t-21 9L480-522 351-651q-9-9-21-9t-21 9q-9 9-9 21t9 21l129 129-129 129q-9 9-9 21t9 21q9 9 21 9t21-9l129-129Zm0 358q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'chair_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-17 0-28.5-11.5T160-160v-40q-50 0-85-35t-35-85v-200q0-50 35-85t85-35v-80q0-50 35-85t85-35h400q50 0 85 35t35 85v80q50 0 85 35t35 85v200q0 50-35 85t-85 35v40q0 17-11.5 28.5T760-120q-17 0-28.5-11.5T720-160v-40H240v40q0 17-11.5 28.5T200-120Zm-40-160h640q17 0 28.5-11.5T840-320v-200q0-17-11.5-28.5T800-560q-17 0-28.5 11.5T760-520v160H200v-160q0-17-11.5-28.5T160-560q-17 0-28.5 11.5T120-520v200q0 17 11.5 28.5T160-280Zm120-160h400v-80q0-27 11-49t29-39v-112q0-17-11.5-28.5T680-760H280q-17 0-28.5 11.5T240-720v112q18 17 29 39t11 49v80Zm200 0Zm0 160Zm0-80Z" /></svg>,
  'chair_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M195.333-120q-13.666 0-23.5-9.833-9.833-9.834-9.833-23.5v-41.334h-8.667q-47.222 0-80.278-33.055Q40-260.777 40-308v-229.334q0-50.667 32.333-80 32.334-29.333 77-29.333v-84q0-47.333 31-78.333t78.333-31h443.335q47.333 0 77.999 31 30.667 31 30.667 78.333v84q44.666 0 77 29.333 32.333 29.333 32.333 80V-308q0 47.223-33.056 80.278-33.055 33.055-80.277 33.055H798v41.334q0 13.666-9.833 23.5-9.834 9.833-23.5 9.833-13.667 0-23.5-9.833-9.833-9.834-9.833-23.5v-41.334H228.666v41.334q0 13.666-9.833 23.5Q209-120 195.333-120Zm-42-141.333h653.334q19 0 32.833-13.833Q853.334-289 853.334-308v-229.334q0-19-11.834-30.833-11.833-11.834-30.833-11.834t-30.833 11.834Q768-556.334 768-537.334v170.667H192v-170.667q0-19-11.834-30.833-11.833-11.834-30.833-11.834T118.5-568.167q-11.834 11.833-11.834 30.833V-308q0 19 13.834 32.834 13.833 13.833 32.833 13.833Zm105.333-172h442.668v-104.001q0-29.667 13-50.667t29.667-34.666v-108q0-19-11.834-30.833-11.833-11.834-30.661-11.834H258.494q-18.828 0-30.661 11.834-11.834 11.833-11.834 30.833v108q16.667 13.666 29.667 34.666 13 21 13 50.667v104.001Zm221.334 0Zm0 172Zm0-105.334Z" /></svg>,
  'chair_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M193-120q-12 0-21-9t-9-21v-42h-13q-45.833 0-77.917-32.083Q40-256.167 40-302v-244q0-51 31-77.5t73-26.5v-86q0-46 29-75t75-29h465q46 0 74.5 29t28.5 75v86q42 0 73 26.5t31 77.5v244q0 45.833-32.083 77.917Q855.833-192 810-192h-13v42q0 12-9 21t-21 9q-12 0-21-9t-9-21v-42H223v42q0 12-9 21t-21 9Zm-43-132h660q20 0 35-15t15-35v-244q0-20-12-32t-32-12q-20 0-32 12t-12 32v176H188v-176q0-20-12-32t-32-12q-20 0-32 12t-12 32v244q0 20 15 35t35 15Zm98-178h464v-116q0-31 14-51.5t30-32.5v-106q0-20-12-32t-31.742-12H247.742Q228-780 216-768t-12 32v106q16 12 30 32.5t14 51.5v116Zm232 0Zm0 178Zm0-118Z" /></svg>,
  'chat_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Zm130-99h253q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T523-459H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-399Zm0-130h420q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-589H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-529Zm0-130h420q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-719H270q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T270-659Z" /></svg>,
  'chat_bubble_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m240-240-92 92q-19 19-43.5 8.5T80-177v-623q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240Zm-34-80h594v-480H160v525l46-45Zm-46 0v-480 480Z" /></svg>,
  'chat_bubble_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M240-240 136.666-136.666Q121-121 100.5-129.604 80-138.208 80-160.333v-653.001q0-27 19.833-46.833T146.666-880h666.668q27 0 46.833 19.833T880-813.334v506.668q0 27-19.833 46.833T813.334-240H240Zm-28.667-66.666h602.001v-506.668H146.666v575.002l64.667-68.334Zm-64.667 0v-506.668 506.668Z" /></svg>,
  'chat_bubble_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M240-240 131-131q-14 14-32.5 6.344T80-152v-668q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H240Zm-26-60h606v-520H140v600l74-80Zm-74 0v-520 520Z" /></svg>,
  'check_circle_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m424-408-86-86q-11-11-28-11t-28 11q-11 11-11 28t11 28l114 114q12 12 28 12t28-12l226-226q11-11 11-28t-11-28q-11-11-28-11t-28 11L424-408Zm56 328q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'check_circle_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m422-395.333-94-94Q318.333-499 304-499t-24.667 10.333q-9.666 9.667-9.666 24 0 14.334 9.666 24l119.334 120q10 10 23.333 10 13.333 0 23.333-10L680-555.333q10.333-10.334 10.333-24.667 0-14.333-10.333-24.667-10.333-9.666-25-9.333-14.666.333-24.333 10L422-395.333ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Zm0-66.666q139.333 0 236.334-97.334 97-97.333 97-236 0-139.333-97-236.334-97.001-97-236.334-97-138.667 0-236 97Q146.666-619.333 146.666-480q0 138.667 97.334 236 97.333 97.334 236 97.334ZM480-480Z" /></svg>,
  'check_circle_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m421-389-98-98q-9-9-22-9t-23 10q-9 9-9 22t9 22l122 123q9 9 21 9t21-9l239-239q10-10 10-23t-10-23q-10-9-23.5-8.5T635-603L421-389Zm59 309q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'close_FILL0_wght300_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-437.847 277.076-234.924q-8.307 8.308-20.884 8.5-12.576.193-21.268-8.5-8.693-8.692-8.693-21.076t8.693-21.076L437.847-480 234.924-682.924q-8.308-8.307-8.5-20.884-.193-12.576 8.5-21.268 8.692-8.693 21.076-8.693t21.076 8.693L480-522.153l202.924-202.923q8.307-8.308 20.884-8.5 12.576-.193 21.268 8.5 8.693 8.692 8.693 21.076t-8.693 21.076L522.153-480l202.923 202.924q8.308 8.307 8.5 20.884.193 12.576-8.5 21.268-8.692 8.693-21.076 8.693t-21.076-8.693L480-437.847Z" /></svg>,
  'close_FILL0_wght300_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-444.616 270.307-234.924q-7.23 7.231-17.499 7.423-10.269.193-17.884-7.423-7.616-7.615-7.616-17.691 0-10.077 7.616-17.692L444.616-480 234.924-689.693q-7.231-7.23-7.423-17.499-.193-10.269 7.423-17.884 7.615-7.616 17.691-7.616 10.077 0 17.692 7.616L480-515.384l209.693-209.692q7.23-7.231 17.499-7.423 10.269-.193 17.884 7.423 7.616 7.615 7.616 17.691 0 10.077-7.616 17.692L515.384-480l209.692 209.693q7.231 7.23 7.423 17.499.193 10.269-7.423 17.884-7.615 7.616-17.691 7.616-10.077 0-17.692-7.616L480-444.616Z" /></svg>,
  'close_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>,
  'close_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-433.334 274.666-228q-9.666 9.667-23.333 9.667-13.666 0-23.333-9.667-9.667-9.667-9.667-23.333 0-13.667 9.667-23.333L433.334-480 228-685.334q-9.667-9.666-9.667-23.333 0-13.666 9.667-23.333 9.667-9.667 23.333-9.667 13.667 0 23.333 9.667L480-526.666 685.334-732q9.666-9.667 23.333-9.667 13.666 0 23.333 9.667 9.667 9.667 9.667 23.333 0 13.667-9.667 23.333L526.666-480 732-274.666q9.667 9.666 9.667 23.333 0 13.666-9.667 23.333-9.667 9.667-23.333 9.667-13.667 0-23.333-9.667L480-433.334Z" /></svg>,
  'code_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m193-479 155 155q11 11 11 28t-11 28q-11 11-28 11t-28-11L108-452q-6-6-8.5-13T97-480q0-8 2.5-15t8.5-13l184-184q12-12 28.5-12t28.5 12q12 12 12 28.5T349-635L193-479Zm574-2L612-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L668-268q-12 12-28 11.5T612-269q-12-12-12-28.5t12-28.5l155-155Z" /></svg>,
  'code_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M174.999-481 344-312q9.667 9.667 9.333 23.334-.333 13.666-10 23.333-9.666 9.666-23.666 9.666T296-265.333L103.333-458q-5.333-5.333-7.5-11-2.166-5.667-2.166-12.333 0-6.667 2.166-12.334 2.167-5.666 7.5-11L298-699.334q10-9.999 23.833-9.999 13.834 0 23.834 9.999 10 10 10 23.834 0 13.833-10 23.833L174.999-481Zm610.002-.667L616-650.667q-9.667-9.667-9.333-23.333.333-13.667 10-23.333Q626.333-707 640.333-707T664-697.333l192.667 192.666q5.333 5.334 7.5 11 2.166 5.667 2.166 12.334 0 6.666-2.166 12.333-2.167 5.667-7.5 11L662-263.333q-10 10-23.333 9.5-13.334-.5-23.334-10.5t-10-23.833q0-13.834 10-23.834l169.668-169.667Z" /></svg>,
  'code_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m166-482 176 176q9 9 8.5 21t-9.5 21q-9 9-21.5 9t-21.5-9L101-461q-5-5-7-10t-2-11q0-6 2-11t7-10l200-200q9-9 21.5-9t21.5 9q9 9 9 21.5t-9 21.5L166-482Zm628 0L618-658q-9-9-8.5-21t9.5-21q9-9 21.5-9t21.5 9l197 197q5 5 7 10t2 11q0 6-2 11t-7 10L659-261q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l177-177Z" /></svg>,
  'content_copy_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" /></svg>,
  'content_copy_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M320-213.33q-27 0-46.83-19.84Q253.33-253 253.33-280v-533.33q0-27 19.84-46.84Q293-880 320-880h413.33q27 0 46.84 19.83Q800-840.33 800-813.33V-280q0 27-19.83 46.83-19.84 19.84-46.84 19.84H320Zm0-66.67h413.33v-533.33H320V-280ZM186.67-80q-27 0-46.84-19.83Q120-119.67 120-146.67v-600h66.67v600h480V-80h-480ZM320-280v-533.33V-280Z" /></svg>,
  'content_copy_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M300-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300Zm0-60h440v-560H300v560ZM180-80q-24 0-42-18t-18-42v-620h60v620h500v60H180Zm120-180v-560 560Z" /></svg>,
  'content_copy_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Z" /></svg>,
  'content_copy_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M320-213.33q-27 0-46.83-19.84Q253.33-253 253.33-280v-533.33q0-27 19.84-46.84Q293-880 320-880h413.33q27 0 46.84 19.83Q800-840.33 800-813.33V-280q0 27-19.83 46.83-19.84 19.84-46.84 19.84H320ZM186.67-80q-27 0-46.84-19.83Q120-119.67 120-146.67v-600h66.67v600h480V-80h-480Z" /></svg>,
  'content_copy_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M300-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300ZM180-80q-24 0-42-18t-18-42v-620h60v620h500v60H180Z" /></svg>,
  'credit_card_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z" /></svg>,
  'credit_card_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M880-733.334v506.668q0 27-19.833 46.833T813.334-160H146.666q-27 0-46.833-19.833T80-226.666v-506.668q0-27 19.833-46.833T146.666-800h666.668q27 0 46.833 19.833T880-733.334ZM146.666-634h666.668v-99.334H146.666V-634Zm0 139.333v268.001h666.668v-268.001H146.666Zm0 268.001v-506.668 506.668Z" /></svg>,
  'credit_card_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M880-740v520q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42ZM140-631h680v-109H140v109Zm0 129v282h680v-282H140Zm0 282v-520 520Z" /></svg>,
  'database_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-120q-151 0-255.5-46.5T120-280v-400q0-66 105.5-113T480-840q149 0 254.5 47T840-680v400q0 67-104.5 113.5T480-120Zm0-479q89 0 179-25.5T760-679q-11-29-100.5-55T480-760q-91 0-178.5 25.5T200-679q14 30 101.5 55T480-599Zm0 199q42 0 81-4t74.5-11.5q35.5-7.5 67-18.5t57.5-25v-120q-26 14-57.5 25t-67 18.5Q600-528 561-524t-81 4q-42 0-82-4t-75.5-11.5Q287-543 256-554t-56-25v120q25 14 56 25t66.5 18.5Q358-408 398-404t82 4Zm0 200q46 0 93.5-7t87.5-18.5q40-11.5 67-26t32-29.5v-98q-26 14-57.5 25t-67 18.5Q600-328 561-324t-81 4q-42 0-82-4t-75.5-11.5Q287-343 256-354t-56-25v99q5 15 31.5 29t66.5 25.5q40 11.5 88 18.5t94 7Z" /></svg>,
  'database_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-120q-151 0-255.5-46.5T120-280v-400q0-66 105.5-113T480-840q149 0 254.5 47T840-680v400q0 67-104.5 113.5T480-120Zm0-485q87 0 177.333-26.167Q747.667-657.334 768.667-689 747-720 656.833-746.667 566.667-773.334 480-773.334q-89 0-177.5 25.834Q214-721.667 190.666-689 214-655.667 301.833-630.333 389.667-605 480-605Zm-.667 209q42 0 83-4.333 41-4.334 78.5-12.834 37.501-8.5 71.334-20.833 33.833-12.333 61.167-27.667v-143.334q-28 15.334-61.834 27.667-33.833 12.333-71.667 20.833-37.833 8.5-78.166 13.167-40.334 4.667-82.334 4.667T396-543.334q-41.333-4.667-78.833-13.167-37.501-8.5-70.834-20.833-33.333-12.333-59.667-27.667v143.334Q213-446.333 246-434q33 12.333 70.5 20.833 37.5 8.5 78.833 12.834 41.334 4.333 84 4.333ZM480-186.666q47.333 0 97.167-8 49.833-8 91.5-21.167Q710.334-229 739-245.166q28.667-16.167 34.334-33.167v-116.001Q746-379 712.167-367t-71.334 20.5q-37.5 8.5-78.166 12.833-40.667 4.333-83.334 4.333-42.666 0-84-4.333Q354-338 316.5-346.5T246-367q-33-12-59.334-27.334v117.001q5 16.333 33.167 32.667Q248-228.333 290-215.5q42 12.834 92 20.834t98 8Z" /></svg>,
  'database_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-120q-151 0-255.5-46.5T120-280v-400q0-66 105.5-113T480-840q149 0 254.5 47T840-680v400q0 67-104.5 113.5T480-120Zm0-488q86 0 176.5-26.5T773-694q-27-32-117.5-59T480-780q-88 0-177 26t-117 60q28 35 116 60.5T480-608Zm-1 214q42 0 84-4.5t80.5-13.5q38.5-9 73.5-22t63-29v-155q-29 16-64 29t-74 22q-39 9-80 14t-83 5q-42 0-84-5t-80.5-14q-38.5-9-73-22T180-618v155q27 16 61 29t72.5 22q38.5 9 80.5 13.5t85 4.5Zm1 214q48 0 99-8.5t93.5-22.5q42.5-14 72-31t35.5-35v-125q-28 16-63 28.5T643.5-352q-38.5 9-80 13.5T479-334q-43 0-85-4.5T313.5-352q-38.5-9-72.5-21.5T180-402v126q5 17 34 34.5t72 31q43 13.5 94 22t100 8.5Z" /></svg>,
  'database_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-520q150 0 255-47t105-113q0-66-105-113t-255-47q-150 0-255 47T120-680q0 66 105 113t255 47Zm0 100q41 0 102.5-8.5T701-456q57-19 98-49.5t41-74.5v100q0 44-41 74.5T701-356q-57 19-118.5 27.5T480-320q-41 0-102.5-8.5T259-356q-57-19-98-49.5T120-480v-100q0 44 41 74.5t98 49.5q57 19 118.5 27.5T480-420Zm0 200q41 0 102.5-8.5T701-256q57-19 98-49.5t41-74.5v100q0 44-41 74.5T701-156q-57 19-118.5 27.5T480-120q-41 0-102.5-8.5T259-156q-57-19-98-49.5T120-280v-100q0 44 41 74.5t98 49.5q57 19 118.5 27.5T480-220Z" /></svg>,
  'database_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-520q157.334 0 258.667-48.333Q840-616.667 840-680q0-64-101.333-112T480-840q-157.334 0-258.667 48Q120-744 120-680q0 63.333 101.333 111.667Q322.666-520 480-520Zm0 100q47 0 108.833-8.5 61.834-8.5 117.834-26.833 56-18.334 94.666-47.5Q840-532 840-573.333v100q0 41.333-38.667 70.5-38.666 29.166-94.666 47.5-56 18.333-117.834 26.833Q527-320 480-320q-46.333 0-108.5-8.5t-118.167-27.167q-56-18.666-94.666-47.833Q120-432.667 120-473.333v-100q0 40.666 38.667 69.833 38.666 29.167 94.666 47.833Q309.333-437 371.5-428.5T480-420Zm0 200q47 0 108.833-8.5 61.834-8.5 117.834-26.833 56-18.334 94.666-47.5Q840-332 840-373.333v100q0 41.333-38.667 70.5-38.666 29.166-94.666 47.5-56 18.333-117.834 26.833Q527-120 480-120q-46.333 0-108.5-8.5t-118.167-27.167q-56-18.666-94.666-47.833Q120-232.667 120-273.333v-100q0 40.666 38.667 69.833 38.666 29.167 94.666 47.833Q309.333-237 371.5-228.5T480-220Z" /></svg>,
  'database_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-520q161 0 260.5-49T840-680q0-63-99.5-111.5T480-840q-161 0-260.5 48.5T120-680q0 62 99.5 111T480-520Zm0 100q50 0 112-8.5T709.5-455q55.5-18 93-46.5T840-570v100q0 40-37.5 68.5t-93 46.5Q654-337 592-328.5T480-320q-49 0-111.5-8.5t-118-27q-55.5-18.5-93-47T120-470v-100q0 39 37.5 67.5t93 47q55.5 18.5 118 27T480-420Zm0 200q50 0 112-8.5T709.5-255q55.5-18 93-46.5T840-370v100q0 40-37.5 68.5t-93 46.5Q654-137 592-128.5T480-120q-49 0-111.5-8.5t-118-27q-55.5-18.5-93-47T120-270v-100q0 39 37.5 67.5t93 47q55.5 18.5 118 27T480-220Z" /></svg>,
  'delete_24dp_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM400-280q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280ZM280-720v520-520Z" /></svg>,
  'delete_24dp_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M267.33-120q-27.5 0-47.08-19.58-19.58-19.59-19.58-47.09V-740h-7.34q-14.16 0-23.75-9.62-9.58-9.61-9.58-23.83 0-14.22 9.58-23.72 9.59-9.5 23.75-9.5H352q0-14.33 9.58-23.83 9.59-9.5 23.75-9.5h189.34q14.16 0 23.75 9.58 9.58 9.59 9.58 23.75h158.67q14.16 0 23.75 9.62 9.58 9.62 9.58 23.83 0 14.22-9.58 23.72-9.59 9.5-23.75 9.5h-7.34v553.33q0 27.5-19.58 47.09Q720.17-120 692.67-120H267.33Zm425.34-620H267.33v553.33h425.34V-740ZM398.12-270.67q14.21 0 23.71-9.58t9.5-23.75v-319.33q0-14.17-9.61-23.75-9.62-9.59-23.84-9.59-14.21 0-23.71 9.59-9.5 9.58-9.5 23.75V-304q0 14.17 9.61 23.75 9.62 9.58 23.84 9.58Zm164 0q14.21 0 23.71-9.58t9.5-23.75v-319.33q0-14.17-9.61-23.75-9.62-9.59-23.84-9.59-14.21 0-23.71 9.59-9.5 9.58-9.5 23.75V-304q0 14.17 9.61 23.75 9.62 9.58 23.84 9.58ZM267.33-740v553.33V-740Z" /></svg>,
  'delete_24dp_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M261-120q-24.75 0-42.37-17.63Q201-155.25 201-180v-570h-11q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h158q0-13 8.63-21.5 8.62-8.5 21.37-8.5h204q12.75 0 21.38 8.62Q612-822.75 612-810h158q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5h-11v570q0 24.75-17.62 42.37Q723.75-120 699-120H261Zm438-630H261v570h438v-570ZM397.18-266q12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-339q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v339q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63Zm166 0q12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-339q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v339q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63ZM261-750v570-570Z" /></svg>,
  'delete_24dp_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M280-120q-33 0-56.5-23.5T200-200v-520q-17 0-28.5-11.5T160-760q0-17 11.5-28.5T200-800h160q0-17 11.5-28.5T400-840h160q17 0 28.5 11.5T600-800h160q17 0 28.5 11.5T800-760q0 17-11.5 28.5T760-720v520q0 33-23.5 56.5T680-120H280Zm120-160q17 0 28.5-11.5T440-320v-280q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v280q0 17 11.5 28.5T400-280Zm160 0q17 0 28.5-11.5T600-320v-280q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v280q0 17 11.5 28.5T560-280Z" /></svg>,
  'delete_24dp_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M267.33-120q-27.5 0-47.08-19.58-19.58-19.59-19.58-47.09V-740h-7.34q-14.16 0-23.75-9.62-9.58-9.61-9.58-23.83 0-14.22 9.58-23.72 9.59-9.5 23.75-9.5H352q0-14.33 9.58-23.83 9.59-9.5 23.75-9.5h189.34q14.16 0 23.75 9.58 9.58 9.59 9.58 23.75h158.67q14.16 0 23.75 9.62 9.58 9.62 9.58 23.83 0 14.22-9.58 23.72-9.59 9.5-23.75 9.5h-7.34v553.33q0 27.5-19.58 47.09Q720.17-120 692.67-120H267.33Zm130.79-150.67q14.21 0 23.71-9.58t9.5-23.75v-319.33q0-14.17-9.61-23.75-9.62-9.59-23.84-9.59-14.21 0-23.71 9.59-9.5 9.58-9.5 23.75V-304q0 14.17 9.61 23.75 9.62 9.58 23.84 9.58Zm164 0q14.21 0 23.71-9.58t9.5-23.75v-319.33q0-14.17-9.61-23.75-9.62-9.59-23.84-9.59-14.21 0-23.71 9.59-9.5 9.58-9.5 23.75V-304q0 14.17 9.61 23.75 9.62 9.58 23.84 9.58Z" /></svg>,
  'delete_24dp_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M261-120q-24.75 0-42.37-17.63Q201-155.25 201-180v-570h-11q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h158q0-13 8.63-21.5 8.62-8.5 21.37-8.5h204q12.75 0 21.38 8.62Q612-822.75 612-810h158q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5h-11v570q0 24.75-17.62 42.37Q723.75-120 699-120H261Zm136.18-146q12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-339q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v339q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63Zm166 0q12.82 0 21.32-8.63 8.5-8.62 8.5-21.37v-339q0-12.75-8.68-21.38-8.67-8.62-21.5-8.62-12.82 0-21.32 8.62-8.5 8.63-8.5 21.38v339q0 12.75 8.68 21.37 8.67 8.63 21.5 8.63Z" /></svg>,
  'description_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-240h240q17 0 28.5-11.5T640-280q0-17-11.5-28.5T600-320H360q-17 0-28.5 11.5T320-280q0 17 11.5 28.5T360-240Zm0-160h240q17 0 28.5-11.5T640-440q0-17-11.5-28.5T600-480H360q-17 0-28.5 11.5T320-440q0 17 11.5 28.5T360-400ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h287q16 0 30.5 6t25.5 17l194 194q11 11 17 25.5t6 30.5v447q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-440H560q-17 0-28.5-11.5T520-640ZM240-800v200-200 640-640Z" /></svg>,
  'description_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M352.666-246.667h254.668q14.166 0 23.749-9.617 9.584-9.617 9.584-23.833 0-14.216-9.584-23.716-9.583-9.5-23.749-9.5H352.666q-14.166 0-23.749 9.617-9.584 9.617-9.584 23.833 0 14.216 9.584 23.716 9.583 9.5 23.749 9.5Zm0-166.667h254.668q14.166 0 23.749-9.617 9.584-9.617 9.584-23.833 0-14.216-9.584-23.716-9.583-9.5-23.749-9.5H352.666q-14.166 0-23.749 9.617-9.584 9.617-9.584 23.833 0 14.216 9.584 23.716 9.583 9.5 23.749 9.5ZM226.666-80q-27 0-46.833-19.833T160-146.666v-666.668q0-27 19.833-46.833T226.666-880h319.668q13.63 0 25.981 5.333 12.352 5.334 21.352 14.334l186.666 186.666q9 9 14.334 21.352Q800-639.964 800-626.334v479.668q0 27-19.833 46.833T733.334-80H226.666Zm314.001-576v-157.334H226.666v666.668h506.668v-476.001H574q-14.166 0-23.749-9.584-9.584-9.583-9.584-23.749ZM226.666-813.334v190.667-190.667 666.668-666.668Z" /></svg>,
  'description_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M349-250h262q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T611-310H349q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T349-250Zm0-170h262q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T611-480H349q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T349-420ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h336q12.444 0 23.722 5T599-862l183 183q8 8 13 19.278 5 11.278 5 23.722v496q0 24-18 42t-42 18H220Zm331-584v-156H220v680h520v-494H581q-12.75 0-21.375-8.625T551-664ZM220-820v186-186 680-680Z" /></svg>,
  'do_not_disturb_on_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-440h400v-80H280v80ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'do_not_disturb_on_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M280-448.67h400v-66.66H280v66.66ZM480-80q-82.33 0-155.33-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.67T80-480q0-83 31.5-156t85.83-127q54.34-54 127.34-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.33-31.5 155.33-31.5 73-85.5 127.34Q709-143 636-111.5T480-80Zm0-66.67q139.33 0 236.33-97.33t97-236q0-139.33-97-236.33t-236.33-97q-138.67 0-236 97-97.33 97-97.33 236.33 0 138.67 97.33 236 97.33 97.33 236 97.33ZM480-480Z" /></svg>,
  'do_not_disturb_on_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M280-453h400v-60H280v60ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'do_not_disturb_on_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-440h400v-80H280v80ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" /></svg>,
  'do_not_disturb_on_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M280-448.67h400v-66.66H280v66.66ZM480-80q-82.33 0-155.33-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.67T80-480q0-83 31.5-156t85.83-127q54.34-54 127.34-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.33-31.5 155.33-31.5 73-85.5 127.34Q709-143 636-111.5T480-80Z" /></svg>,
  'do_not_disturb_on_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M280-453h400v-60H280v60ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z" /></svg>,
  'door_front_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-120q-17 0-28.5-11.5T120-160q0-17 11.5-28.5T160-200h40v-560q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v560h40q17 0 28.5 11.5T840-160q0 17-11.5 28.5T800-120H160Zm120-80h400v-560H280v560Zm280-240q17 0 28.5-11.5T600-480q0-17-11.5-28.5T560-520q-17 0-28.5 11.5T520-480q0 17 11.5 28.5T560-440ZM280-760v560-560Z" /></svg>,
  'door_front_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M153.333-120q-14.166 0-23.75-9.617Q120-139.234 120-153.45q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5H200v-586.668q0-27 19.833-46.833T266.666-840h426.668q27 0 46.833 19.833T760-773.334v586.668h46.667q14.166 0 23.75 9.617Q840-167.432 840-153.216q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Zm113.333-66.666h426.668v-586.668H266.666v586.668ZM564.667-446q15.3 0 25.65-10.35 10.35-10.35 10.35-25.65 0-15.3-10.35-25.65-10.35-10.35-25.65-10.35-15.3 0-25.65 10.35-10.35 10.35-10.35 25.65 0 15.3 10.35 25.65 10.35 10.35 25.65 10.35ZM266.666-773.334v586.668-586.668Z" /></svg>,
  'door_open_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440q17 0 28.5-11.5T480-480q0-17-11.5-28.5T440-520q-17 0-28.5 11.5T400-480q0 17 11.5 28.5T440-440ZM280-120v-80l240-40v-445q0-15-9-27t-23-14l-208-34v-80l220 36q44 8 72 41t28 77v444q0 29-19 51.5T533-163l-253 43Zm0-80h400v-560H280v560Zm-120 80q-17 0-28.5-11.5T120-160q0-17 11.5-28.5T160-200h40v-560q0-33 23.5-56.5T280-840h400q33 0 56.5 23.5T760-760v560h40q17 0 28.5 11.5T840-160q0 17-11.5 28.5T800-120H160Z" /></svg>,
  'door_open_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M440.117-446.667q14.216 0 23.716-9.617 9.5-9.617 9.5-23.833 0-14.216-9.617-23.716-9.617-9.5-23.833-9.5-14.216 0-23.716 9.617-9.5 9.617-9.5 23.833 0 14.216 9.617 23.716 9.617 9.5 23.833 9.5ZM266.666-120v-66.666l266.668-46v-459.001q0-14.333-8-25.333-8-11-21.334-13l-237.334-37.334v-65.999l250.668 42.667q36 6 59.333 33.599 23.333 27.6 23.333 64.4V-234q0 24.106-15.786 42.808-15.786 18.702-39.88 22.859L266.666-120Zm0-66.666h426.668v-580.001H266.666v-.667 580.668ZM153.333-120q-14.166 0-23.75-9.617Q120-139.234 120-153.45q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5H200v-580.001q0-27.5 19.583-47.083t47.083-19.583h426.668q27.5 0 47.083 19.583T760-766.667v580.001h46.667q14.166 0 23.75 9.617Q840-167.432 840-153.216q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Z" /></svg>,
  'door_open_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M440.175-450q12.825 0 21.325-8.675 8.5-8.676 8.5-21.5 0-12.825-8.675-21.325-8.676-8.5-21.5-8.5-12.825 0-21.325 8.675-8.5 8.676-8.5 21.5 0 12.825 8.675 21.325 8.676 8.5 21.5 8.5ZM260-120v-60l280-49v-466q0-14-7.5-24.5T512-732l-252-39v-59l266 46q32 5 53 29.9t21 58.1v466q0 21.658-14.179 38.462Q571.642-174.734 550-171l-290 51Zm0-60h440v-590H260v-1 591Zm-110 60q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-180h50v-590q0-24.75 17.625-42.375T260-830h440q24.75 0 42.375 17.625T760-770v590h50q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H150Z" /></svg>,
  'download_for_offline_24dp_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M320-280h320q17 0 28.5-11.5T680-320q0-17-11.5-28.5T640-360H320q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm120-274-36-35q-11-11-27.5-11T348-588q-11 11-11 28t11 28l104 104q12 12 28 12t28-12l104-104q11-11 11.5-27.5T612-588q-11-11-27.5-11.5T556-589l-36 35v-126q0-17-11.5-28.5T480-720q-17 0-28.5 11.5T440-680v126Zm40 474q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'download_for_offline_24dp_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M313.33-280h333.34q14.33 0 23.83-9.5 9.5-9.5 9.5-23.83 0-14.34-9.5-23.84t-23.83-9.5H313.33q-14.33 0-23.83 9.5-9.5 9.5-9.5 23.84 0 14.33 9.5 23.83 9.5 9.5 23.83 9.5Zm132-252-54.66-53.67q-9.67-9-23.17-9.33-13.5-.33-23.5 9.67-9.67 9.66-9.67 23.33 0 13.67 9.67 23.33l110.67 111.34q10 10 23.33 10 13.33 0 23.33-10l112-111.34q9.67-9.66 9.84-23.16.16-13.5-9.84-23.5-9.66-9.67-23.16-9.5-13.5.16-23.5 9.16L512-532v-154.67q0-14.33-9.5-23.83-9.5-9.5-23.83-9.5-14.34 0-23.84 9.5t-9.5 23.83V-532ZM480-80q-82.33 0-155.33-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.67T80-480q0-83 31.5-156t85.83-127q54.34-54 127.34-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.33-31.5 155.33-31.5 73-85.5 127.34Q709-143 636-111.5T480-80Zm0-66.67q139.33 0 236.33-97.33t97-236q0-139.33-97-236.33t-236.33-97q-138.67 0-236 97-97.33 97-97.33 236.33 0 138.67 97.33 236 97.33 97.33 236 97.33ZM480-480Z" /></svg>,
  'download_for_offline_24dp_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M310-280h340q13 0 21.5-8.5T680-310q0-13-8.5-21.5T650-340H310q-13 0-21.5 8.5T280-310q0 13 8.5 21.5T310-280Zm138-241-64-63q-9-8-21-8.5t-21 8.5q-9 9-9 21t9 21l114 115q9 9 21 9t21-9l116-115q9-9 9-21t-9-21q-9-9-21-8.5t-21 8.5l-64 63v-169q0-13-8.5-21.5T478-720q-13 0-21.5 8.5T448-690v169Zm32 441q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'download_for_offline_24dp_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M320-280h320q17 0 28.5-11.5T680-320q0-17-11.5-28.5T640-360H320q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm120-274-36-35q-11-11-27.5-11T348-588q-11 11-11 28t11 28l104 104q12 12 28 12t28-12l104-104q11-11 11.5-27.5T612-588q-11-11-27.5-11.5T556-589l-36 35v-126q0-17-11.5-28.5T480-720q-17 0-28.5 11.5T440-680v126Zm40 474q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" /></svg>,
  'download_for_offline_24dp_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M313.33-280h333.34q14.33 0 23.83-9.5 9.5-9.5 9.5-23.83 0-14.34-9.5-23.84t-23.83-9.5H313.33q-14.33 0-23.83 9.5-9.5 9.5-9.5 23.84 0 14.33 9.5 23.83 9.5 9.5 23.83 9.5Zm132-252-54.66-53.67q-9.67-9-23.17-9.33-13.5-.33-23.5 9.67-9.67 9.66-9.67 23.33 0 13.67 9.67 23.33l110.67 111.34q10 10 23.33 10 13.33 0 23.33-10l112-111.34q9.67-9.66 9.84-23.16.16-13.5-9.84-23.5-9.66-9.67-23.16-9.5-13.5.16-23.5 9.16L512-532v-154.67q0-14.33-9.5-23.83-9.5-9.5-23.83-9.5-14.34 0-23.84 9.5t-9.5 23.83V-532ZM480-80q-82.33 0-155.33-31.5-73-31.5-127.34-85.83Q143-251.67 111.5-324.67T80-480q0-83 31.5-156t85.83-127q54.34-54 127.34-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.33-31.5 155.33-31.5 73-85.5 127.34Q709-143 636-111.5T480-80Z" /></svg>,
  'download_for_offline_24dp_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M310-280h340q13 0 21.5-8.5T680-310q0-13-8.5-21.5T650-340H310q-13 0-21.5 8.5T280-310q0 13 8.5 21.5T310-280Zm138-241-64-63q-9-8-21-8.5t-21 8.5q-9 9-9 21t9 21l114 115q9 9 21 9t21-9l116-115q9-9 9-21t-9-21q-9-9-21-8.5t-21 8.5l-64 63v-169q0-13-8.5-21.5T478-720q-13 0-21.5 8.5T448-690v169Zm32 441q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z" /></svg>,
  'dvr_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-400q17 0 28.5-11.5T320-440q0-17-11.5-28.5T280-480q-17 0-28.5 11.5T240-440q0 17 11.5 28.5T280-400Zm0-160q17 0 28.5-11.5T320-600q0-17-11.5-28.5T280-640q-17 0-28.5 11.5T240-600q0 17 11.5 28.5T280-560Zm120 160h280q17 0 28.5-11.5T720-440q0-17-11.5-28.5T680-480H400q-17 0-28.5 11.5T360-440q0 17 11.5 28.5T400-400Zm0-160h280q17 0 28.5-11.5T720-600q0-17-11.5-28.5T680-640H400q-17 0-28.5 11.5T360-600q0 17 11.5 28.5T400-560ZM160-200q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v480q0 33-23.5 56.5T800-200H640v40q0 17-11.5 28.5T600-120H360q-17 0-28.5-11.5T320-160v-40H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z" /></svg>,
  'dvr_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M279.988-404.667q15.012 0 25.179-10.155 10.166-10.155 10.166-25.166 0-15.012-10.155-25.179-10.155-10.166-25.166-10.166-15.012 0-25.179 10.155-10.166 10.155-10.166 25.166 0 15.012 10.155 25.179 10.155 10.166 25.166 10.166Zm0-160q15.012 0 25.179-10.155 10.166-10.155 10.166-25.166 0-15.012-10.155-25.179-10.155-10.166-25.166-10.166-15.012 0-25.179 10.155-10.166 10.155-10.166 25.166 0 15.012 10.155 25.179 10.155 10.166 25.166 10.166Zm113.345 158h293.334q14.166 0 23.75-9.617Q720-425.901 720-440.117q0-14.216-9.583-23.716-9.584-9.5-23.75-9.5H393.333q-14.166 0-23.75 9.617Q360-454.099 360-439.883q0 14.216 9.583 23.716 9.584 9.5 23.75 9.5Zm0-160h293.334q14.166 0 23.75-9.617Q720-585.901 720-600.117q0-14.216-9.583-23.716-9.584-9.5-23.75-9.5H393.333q-14.166 0-23.75 9.617Q360-614.099 360-599.883q0 14.216 9.583 23.716 9.584 9.5 23.75 9.5ZM146.666-200q-27 0-46.833-19.833T80-266.666v-506.668q0-27 19.833-46.833T146.666-840h666.668q27 0 46.833 19.833T880-773.334v506.668q0 27-19.833 46.833T813.334-200H633.333v46.667q0 14.166-9.583 23.75Q614.167-120 600-120H360q-14.167 0-23.75-9.583-9.583-9.584-9.583-23.75V-200H146.666Zm0-66.666h666.668v-506.668H146.666v506.668Zm0 0v-506.668 506.668Z" /></svg>,
  'dvr_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M279.982-407q14.018 0 23.518-9.482 9.5-9.483 9.5-23.5 0-14.018-9.482-23.518-9.483-9.5-23.5-9.5-14.018 0-23.518 9.482-9.5 9.483-9.5 23.5 0 14.018 9.482 23.518 9.483 9.5 23.5 9.5Zm0-160q14.018 0 23.518-9.482 9.5-9.483 9.5-23.5 0-14.018-9.482-23.518-9.483-9.5-23.5-9.5-14.018 0-23.518 9.482-9.5 9.483-9.5 23.5 0 14.018 9.482 23.518 9.483 9.5 23.5 9.5ZM390-410h300q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-470H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-410Zm0-160h300q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T690-630H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-570ZM140-200q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H630v50q0 12.75-8.625 21.375T600-120H360q-12.75 0-21.375-8.625T330-150v-50H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z" /></svg>,
  'event_available_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m438-342 139-139q12-12 29-12t29 12q12 12 12 29t-12 29L466-254q-12 12-28 12t-28-12l-85-85q-12-12-12-29t12-29q12-12 29-12t29 12l55 55ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" /></svg>,
  'event_available_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m434.667-322.666 155-155.001q10-10 23.667-9.666Q627-487 637-477t10 23.797q0 13.797-10 23.536l-179 179q-10 10-23.333 10-13.334 0-23.334-10L319-342.333q-10-9.74-10-23.537 0-13.797 10-23.796 10-10 23.666-9.667 13.667.333 23.667 9.667l68.334 67ZM186.666-80q-27 0-46.833-19.833T120-146.666v-600.001q0-27 19.833-46.833 19.833-19.834 46.833-19.834h56.667v-31.999q0-14.734 9.967-24.7Q263.267-880 278-880q15.017 0 25.175 9.967 10.158 9.966 10.158 24.7v31.999h333.334v-31.999q0-14.734 9.966-24.7Q666.6-880 681.333-880q15.017 0 25.175 9.967 10.159 9.966 10.159 24.7v31.999h56.667q27 0 46.833 19.834Q840-773.667 840-746.667v600.001q0 27-19.833 46.833T773.334-80H186.666Zm0-66.666h586.668v-420.001H186.666v420.001Zm0-486.667h586.668v-113.334H186.666v113.334Zm0 0v-113.334 113.334Z" /></svg>,
  'event_available_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m433-313 163-163q9-9 21-8.5t21 9.5q9 9 9 21.196 0 12.195-9 20.804L454-249q-9 9-21 9t-21-9l-96-95q-9-8.609-9-20.804Q307-377 316-386q9-9 21-8.5t21 8.5l75 73ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q310-861.6 310-848v28h340v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q715-861.6 715-848v28h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Zm0-490h600v-130H180v130Zm0 0v-130 130Z" /></svg>,
  'event_available_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m438-342 139-139q12-12 29-12t29 12q12 12 12 29t-12 29L466-254q-12 12-28 12t-28-12l-85-85q-12-12-12-29t12-29q12-12 29-12t29 12l55 55ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-40q0-17 11.5-28.5T280-880q17 0 28.5 11.5T320-840v40h320v-40q0-17 11.5-28.5T680-880q17 0 28.5 11.5T720-840v40h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Z" /></svg>,
  'event_available_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m434.667-322.666 155-155.001q10-10 23.667-9.666Q627-487 637-477t10 23.797q0 13.797-10 23.536l-179 179q-10 10-23.333 10-13.334 0-23.334-10L319-342.333q-10-9.74-10-23.537 0-13.797 10-23.796 10-10 23.666-9.667 13.667.333 23.667 9.667l68.334 67ZM186.666-80q-27 0-46.833-19.833T120-146.666v-600.001q0-27 19.833-46.833 19.833-19.834 46.833-19.834h56.667v-31.999q0-14.734 9.967-24.7Q263.267-880 278-880q15.017 0 25.175 9.967 10.158 9.966 10.158 24.7v31.999h333.334v-31.999q0-14.734 9.966-24.7Q666.6-880 681.333-880q15.017 0 25.175 9.967 10.159 9.966 10.159 24.7v31.999h56.667q27 0 46.833 19.834Q840-773.667 840-746.667v600.001q0 27-19.833 46.833T773.334-80H186.666Zm0-66.666h586.668v-420.001H186.666v420.001Z" /></svg>,
  'event_available_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m433-313 163-163q9-9 21-8.5t21 9.5q9 9 9 21.196 0 12.195-9 20.804L454-249q-9 9-21 9t-21-9l-96-95q-9-8.609-9-20.804Q307-377 316-386q9-9 21-8.5t21 8.5l75 73ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q310-861.6 310-848v28h340v-28q0-13.6 9.2-22.8 9.2-9.2 22.8-9.2 14.025 0 23.513 9.2Q715-861.6 715-848v28h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Z" /></svg>,
  'expand_circle_down_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-453-95-95q-11-11-27.5-11T329-548q-12 12-12 28.5t12 28.5l123 123q12 12 28 12t28-12l124-124q12-12 11.5-28T631-548q-12-11-28-11.5T575-548l-95 95Zm0 373q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'expand_circle_down_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-441.666 375.666-546q-9.666-9.667-23.166-9.667T329-546q-10 10-10 23.833 0 13.834 10 23.833l127.667 127.667q10 10 23.333 10 13.333 0 23.333-10L632-499.334q10-9.999 9.5-23.333Q641-536 631-546q-10-9.667-23.333-9.833Q594.334-556 584.334-546L480-441.666ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Zm0-66.666q139.333 0 236.334-97.334 97-97.333 97-236 0-139.333-97-236.334-97.001-97-236.334-97-138.667 0-236 97Q146.666-619.333 146.666-480q0 138.667 97.334 236 97.333 97.334 236 97.334ZM480-480Z" /></svg>,
  'expand_circle_down_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-436 371-545q-9-9-21-9t-21 9q-9 9-9 21.5t9 21.5l130 130q9 9 21 9t21-9l131-131q9-9 8.5-21t-9.5-21q-9-9-21-9t-21 9L480-436Zm0 356q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'expand_circle_up_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-507 95 95q11 11 27.5 11t28.5-11q12-12 12-28.5T631-469L508-592q-12-12-28-12t-28 12L328-468q-12 12-11.5 28t12.5 28q12 11 28 11.5t28-11.5l95-95Zm0 427q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'expand_circle_up_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-518.334 584.334-414q9.666 9.667 23.166 9.667T631-414q10-10 10-23.833 0-13.834-10-23.833L503.333-589.333q-10-10-23.333-10-13.333 0-23.333 10L328-460.666q-10 9.999-9.5 23.333Q319-424 329-414q10 9.667 23.333 9.833 13.333.167 23.333-9.833L480-518.334ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-155.667 31.5-72.666 85.5-127Q251-817 324-848.5T480-880q83 0 155.667 31.5 72.666 31.5 127 85.833 54.333 54.334 85.833 127Q880-563 880-480t-31.5 156q-31.5 73-85.833 127-54.334 54-127 85.5Q563-80 480-80Zm0-66.666q138.667 0 236-97Q813.334-340.667 813.334-480q0-138.667-97.334-236-97.333-97.334-236-97.334-139.333 0-236.334 97.334-97 97.333-97 236 0 139.333 97 236.334 97.001 97 236.334 97ZM480-480Z" /></svg>,
  'expand_circle_up_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m480-524 109 109q9 9 21 9t21-9q9-9 9-21.5t-9-21.5L501-588q-9-9-21-9t-21 9L328-457q-9 9-8.5 21t9.5 21q9 9 21 9t21-9l109-109Zm0 444q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-155.5t85.5-127q54-54.5 127-86T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 83-31.5 156t-86 127q-54.5 54-127 85.5T480-80Zm0-60q141 0 240.5-99T820-480q0-141-99.5-240.5T480-820q-142 0-241 99.5T140-480q0 142 99 241t241 99Zm0-340Z" /></svg>,
  'expand_more_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-362q-8 0-15-2.5t-13-8.5L268-557q-11-11-11-28t11-28q11-11 28-11t28 11l156 156 156-156q11-11 28-11t28 11q11 11 11 28t-11 28L508-373q-6 6-13 8.5t-15 2.5Z" /></svg>,
  'expand_more_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-358.667q-6.667 0-12.333-2.166-5.667-2.167-11-7.5L263.333-561.667q-9.666-9.666-9.666-23.333 0-13.667 9.666-23.333Q273-618 287-618q14 0 23.666 9.667L480-438.999l169.334-169.334Q659-618 672.667-618q13.666 0 23.333 9.667 9.667 9.666 9.667 23.666T696-561L503.333-368.333q-5.333 5.333-11 7.5-5.666 2.166-12.333 2.166Z" /></svg>,
  'expand_more_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-357q-6 0-11-2t-10-7L261-564q-9-9-9-21t9-21q9-9 21.5-9t21.5 9l176 176 176-176q9-9 21-9t21 9q9 9 9 21.5t-9 21.5L501-366q-5 5-10 7t-11 2Z" /></svg>,
  'fitness_center_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M282-622 168-508q-11 11-27.5 11.5T112-508q-11-11-11.5-27.5T111-564l29-30-28-28q-12-12-12-28t12-28l56-56-29-30q-11-11-11-27.5t12-28.5q11-11 27.5-11.5T196-821l30 29 56-56q12-12 28-12t28 12l28 28 30-29q11-11 27.5-11t28.5 12q11 11 11 28t-11 28L338-678l340 340 114-114q11-11 27.5-11.5T848-452q11 11 11.5 27.5T849-396l-29 30 28 28q12 12 12 28t-12 28l-56 56 29 30q11 11 11 27.5T820-140q-11 11-27.5 11.5T764-139l-30-29-56 56q-12 12-28 12t-28-12l-28-28-30 29q-11 11-27.5 11T508-112q-11-11-11-28t11-28l114-114-340-340Z" /></svg>,
  'fitness_center_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M272.666-640.667 154-522q-9.667 9.666-23.167 9.833-13.5.167-23.5-9.833-9-9-9.5-22.834-.5-13.833 8.5-23.833L140-603.334 107.333-636q-10-10-10-23.334 0-13.333 10-23.333L168.667-744 145-768q-9.666-9.667-9.666-23.167 0-13.5 9.999-23.5 9-8.999 22.834-9.499 13.833-.5 23.833 8.499L216.666-792l60.667-60.667q10-10 23.333-10 13.334 0 23.334 10L356.666-820l34.667-33.667q9.667-9 23.167-9 13.5 0 23.5 10 9.666 9.667 9.666 23.333 0 13.667-9.666 23.334L319.333-687.334l368.001 368.001L806-438q9.667-9.666 23.167-9.833 13.5-.167 23.5 9.833 9 9 9.5 22.834.5 13.833-8.5 23.833L820-356.666 852.667-324q10 10 10 23.334 0 13.333-10 23.333l-62 62 23.666 24Q824-181.667 824-168.167t-10 23.5q-9.667 9.667-23.167 9.833-13.5.167-23.5-9.499L743.334-168l-60.667 60.667q-10 10-23.333 10-13.334 0-23.334-10L603.334-140l-34.667 33.667q-9.667 9-23.167 9-13.5 0-23.5-10-9.666-9.667-9.666-23.333 0-13.667 9.666-23.334l118.667-118.666-368.001-368.001Z" /></svg>,
  'fitness_center_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M268-650 147-529q-9 9-21 9t-21-9q-8-8-8.5-20.5T104-571l36-37-35-35q-9-9-9-21t9-21l64-64-21-21q-9-9-9-21t9-21q8-8 20.5-8.5T190-813l22 21 63-63q9-9 21-9t21 9l35 35 37-36q9-8 21-8t21 9q9 9 9 21t-9 21L310-692l382 382 121-121q9-9 21-9t21 9q8 8 8.5 20.5T856-389l-36 37 35 35q9 9 9 21t-9 21l-65 65 21 21q9 9 9 21t-9 21q-9 9-21 9t-21-9l-21-21-63 63q-9 9-21 9t-21-9l-35-35-37 36q-9 8-21 8t-21-9q-9-9-9-21t9-21l121-121-382-382Z" /></svg>,
  'fullscreen_24dp_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M200-200h80q17 0 28.5 11.5T320-160q0 17-11.5 28.5T280-120H160q-17 0-28.5-11.5T120-160v-120q0-17 11.5-28.5T160-320q17 0 28.5 11.5T200-280v80Zm560 0v-80q0-17 11.5-28.5T800-320q17 0 28.5 11.5T840-280v120q0 17-11.5 28.5T800-120H680q-17 0-28.5-11.5T640-160q0-17 11.5-28.5T680-200h80ZM200-760v80q0 17-11.5 28.5T160-640q-17 0-28.5-11.5T120-680v-120q0-17 11.5-28.5T160-840h120q17 0 28.5 11.5T320-800q0 17-11.5 28.5T280-760h-80Zm560 0h-80q-17 0-28.5-11.5T640-800q0-17 11.5-28.5T680-840h120q17 0 28.5 11.5T840-800v120q0 17-11.5 28.5T800-640q-17 0-28.5-11.5T760-680v-80Z" /></svg>,
  'fullscreen_24dp_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M186.67-186.67H282q14.17 0 23.75 9.62t9.58 23.83q0 14.22-9.58 23.72-9.58 9.5-23.75 9.5H153.33q-14.16 0-23.75-9.58-9.58-9.59-9.58-23.75V-282q0-14.17 9.62-23.75 9.61-9.58 23.83-9.58 14.22 0 23.72 9.58 9.5 9.58 9.5 23.75v95.33Zm586.66 0V-282q0-14.17 9.62-23.75t23.83-9.58q14.22 0 23.72 9.58 9.5 9.58 9.5 23.75v128.67q0 14.16-9.58 23.75-9.59 9.58-23.75 9.58H678q-14.17 0-23.75-9.62-9.58-9.61-9.58-23.83 0-14.22 9.58-23.72 9.58-9.5 23.75-9.5h95.33ZM186.67-773.33V-678q0 14.17-9.62 23.75t-23.83 9.58q-14.22 0-23.72-9.58-9.5-9.58-9.5-23.75v-128.67q0-14.16 9.58-23.75 9.59-9.58 23.75-9.58H282q14.17 0 23.75 9.62 9.58 9.61 9.58 23.83 0 14.22-9.58 23.72-9.58 9.5-23.75 9.5h-95.33Zm586.66 0H678q-14.17 0-23.75-9.62t-9.58-23.83q0-14.22 9.58-23.72 9.58-9.5 23.75-9.5h128.67q14.16 0 23.75 9.58 9.58 9.59 9.58 23.75V-678q0 14.17-9.62 23.75-9.61 9.58-23.83 9.58-14.22 0-23.72-9.58-9.5-9.58-9.5-23.75v-95.33Z" /></svg>,
  'fullscreen_24dp_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M180-180h103q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5H150q-12.75 0-21.37-8.63Q120-137.25 120-150v-133q0-12.75 8.68-21.38 8.67-8.62 21.5-8.62 12.82 0 21.32 8.62 8.5 8.63 8.5 21.38v103Zm600 0v-103q0-12.75 8.68-21.38 8.67-8.62 21.5-8.62 12.82 0 21.32 8.62 8.5 8.63 8.5 21.38v133q0 12.75-8.62 21.37Q822.75-120 810-120H677q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h103ZM180-780v103q0 12.75-8.68 21.37-8.67 8.63-21.5 8.63-12.82 0-21.32-8.63-8.5-8.62-8.5-21.37v-133q0-12.75 8.63-21.38Q137.25-840 150-840h133q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5H180Zm600 0H677q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h133q12.75 0 21.38 8.62Q840-822.75 840-810v133q0 12.75-8.68 21.37-8.67 8.63-21.5 8.63-12.82 0-21.32-8.63-8.5-8.62-8.5-21.37v-103Z" /></svg>,
  'fullscreen_exit_24dp_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M240-240h-80q-17 0-28.5-11.5T120-280q0-17 11.5-28.5T160-320h120q17 0 28.5 11.5T320-280v120q0 17-11.5 28.5T280-120q-17 0-28.5-11.5T240-160v-80Zm480 0v80q0 17-11.5 28.5T680-120q-17 0-28.5-11.5T640-160v-120q0-17 11.5-28.5T680-320h120q17 0 28.5 11.5T840-280q0 17-11.5 28.5T800-240h-80ZM240-720v-80q0-17 11.5-28.5T280-840q17 0 28.5 11.5T320-800v120q0 17-11.5 28.5T280-640H160q-17 0-28.5-11.5T120-680q0-17 11.5-28.5T160-720h80Zm480 0h80q17 0 28.5 11.5T840-680q0 17-11.5 28.5T800-640H680q-17 0-28.5-11.5T640-680v-120q0-17 11.5-28.5T680-840q17 0 28.5 11.5T720-800v80Z" /></svg>,
  'fullscreen_exit_24dp_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M248.67-248.67h-95.34q-14.16 0-23.75-9.61-9.58-9.62-9.58-23.84 0-14.21 9.58-23.71 9.59-9.5 23.75-9.5H282q14.17 0 23.75 9.58t9.58 23.75v128.67q0 14.16-9.61 23.75-9.62 9.58-23.84 9.58-14.21 0-23.71-9.58-9.5-9.59-9.5-23.75v-95.34Zm462.66 0v95.34q0 14.16-9.61 23.75-9.62 9.58-23.84 9.58-14.21 0-23.71-9.58-9.5-9.59-9.5-23.75V-282q0-14.17 9.58-23.75t23.75-9.58h128.67q14.16 0 23.75 9.61 9.58 9.62 9.58 23.84 0 14.21-9.58 23.71-9.59 9.5-23.75 9.5h-95.34ZM248.67-711.33v-95.34q0-14.16 9.61-23.75 9.62-9.58 23.84-9.58 14.21 0 23.71 9.58 9.5 9.59 9.5 23.75V-678q0 14.17-9.58 23.75T282-644.67H153.33q-14.16 0-23.75-9.61-9.58-9.62-9.58-23.84 0-14.21 9.58-23.71 9.59-9.5 23.75-9.5h95.34Zm462.66 0h95.34q14.16 0 23.75 9.61 9.58 9.62 9.58 23.84 0 14.21-9.58 23.71-9.59 9.5-23.75 9.5H678q-14.17 0-23.75-9.58T644.67-678v-128.67q0-14.16 9.61-23.75 9.62-9.58 23.84-9.58 14.21 0 23.71 9.58 9.5 9.59 9.5 23.75v95.34Z" /></svg>,
  'fullscreen_exit_24dp_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M253-253H150q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h133q12.75 0 21.38 8.62Q313-295.75 313-283v133q0 12.75-8.68 21.37-8.67 8.63-21.5 8.63-12.82 0-21.32-8.63-8.5-8.62-8.5-21.37v-103Zm454 0v103q0 12.75-8.68 21.37-8.67 8.63-21.5 8.63-12.82 0-21.32-8.63-8.5-8.62-8.5-21.37v-133q0-12.75 8.63-21.38Q664.25-313 677-313h133q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5H707ZM253-707v-103q0-12.75 8.68-21.38 8.67-8.62 21.5-8.62 12.82 0 21.32 8.62 8.5 8.63 8.5 21.38v133q0 12.75-8.62 21.37Q295.75-647 283-647H150q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h103Zm454 0h103q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5H677q-12.75 0-21.37-8.63Q647-664.25 647-677v-133q0-12.75 8.68-21.38 8.67-8.62 21.5-8.62 12.82 0 21.32 8.62 8.5 8.63 8.5 21.38v103Z" /></svg>,
  'grid_view_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-520q-33 0-56.5-23.5T120-600v-160q0-33 23.5-56.5T200-840h160q33 0 56.5 23.5T440-760v160q0 33-23.5 56.5T360-520H200Zm0 400q-33 0-56.5-23.5T120-200v-160q0-33 23.5-56.5T200-440h160q33 0 56.5 23.5T440-360v160q0 33-23.5 56.5T360-120H200Zm400-400q-33 0-56.5-23.5T520-600v-160q0-33 23.5-56.5T600-840h160q33 0 56.5 23.5T840-760v160q0 33-23.5 56.5T760-520H600Zm0 400q-33 0-56.5-23.5T520-200v-160q0-33 23.5-56.5T600-440h160q33 0 56.5 23.5T840-360v160q0 33-23.5 56.5T760-120H600ZM200-600h160v-160H200v160Zm400 0h160v-160H600v160Zm0 400h160v-160H600v160Zm-400 0h160v-160H200v160Zm400-400Zm0 240Zm-240 0Zm0-240Z" /></svg>,
  'grid_view_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M186.666-513.333q-27.5 0-47.083-19.583Q120-552.5 120-579.999v-193.335q0-27.5 19.583-47.083T186.666-840h193.335q27.499 0 47.083 19.583 19.583 19.583 19.583 47.083v193.335q0 27.499-19.583 47.083-19.584 19.583-47.083 19.583H186.666Zm0 393.333q-27.5 0-47.083-19.583T120-186.666v-193.335q0-27.499 19.583-47.083 19.583-19.583 47.083-19.583h193.335q27.499 0 47.083 19.583 19.583 19.584 19.583 47.083v193.335q0 27.5-19.583 47.083Q407.5-120 380.001-120H186.666Zm393.333-393.333q-27.499 0-47.083-19.583-19.583-19.584-19.583-47.083v-193.335q0-27.5 19.583-47.083Q552.5-840 579.999-840h193.335q27.5 0 47.083 19.583T840-773.334v193.335q0 27.499-19.583 47.083-19.583 19.583-47.083 19.583H579.999Zm0 393.333q-27.499 0-47.083-19.583-19.583-19.583-19.583-47.083v-193.335q0-27.499 19.583-47.083 19.584-19.583 47.083-19.583h193.335q27.5 0 47.083 19.583Q840-407.5 840-380.001v193.335q0 27.5-19.583 47.083T773.334-120H579.999ZM186.666-579.999h193.335v-193.335H186.666v193.335Zm393.333 0h193.335v-193.335H579.999v193.335Zm0 393.333h193.335v-193.335H579.999v193.335Zm-393.333 0h193.335v-193.335H186.666v193.335Zm393.333-393.333Zm0 199.998Zm-199.998 0Zm0-199.998Z" /></svg>,
  'grid_view_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M180-510q-24.75 0-42.375-17.625T120-570v-210q0-24.75 17.625-42.375T180-840h210q24.75 0 42.375 17.625T450-780v210q0 24.75-17.625 42.375T390-510H180Zm0 390q-24.75 0-42.375-17.625T120-180v-210q0-24.75 17.625-42.375T180-450h210q24.75 0 42.375 17.625T450-390v210q0 24.75-17.625 42.375T390-120H180Zm390-390q-24.75 0-42.375-17.625T510-570v-210q0-24.75 17.625-42.375T570-840h210q24.75 0 42.375 17.625T840-780v210q0 24.75-17.625 42.375T780-510H570Zm0 390q-24.75 0-42.375-17.625T510-180v-210q0-24.75 17.625-42.375T570-450h210q24.75 0 42.375 17.625T840-390v210q0 24.75-17.625 42.375T780-120H570ZM180-570h210v-210H180v210Zm390 0h210v-210H570v210Zm0 390h210v-210H570v210Zm-390 0h210v-210H180v210Zm390-390Zm0 180Zm-180 0Zm0-180Z" /></svg>,
  'help_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm2 160q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Zm4-172q25 0 43.5 16t18.5 40q0 22-13.5 39T502-525q-23 20-40.5 44T444-427q0 14 10.5 23.5T479-394q15 0 25.5-10t13.5-25q4-21 18-37.5t30-31.5q23-22 39.5-48t16.5-58q0-51-41.5-83.5T484-720q-38 0-72.5 16T359-655q-7 12-4.5 25.5T368-609q14 8 29 5t25-17q11-15 27.5-23t34.5-8Z" /></svg>,
  'help_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M482.019-244.667q17.648 0 29.814-12.185Q524-269.038 524-286.685q0-17.648-12.185-29.815-12.186-12.166-29.834-12.166-17.647 0-29.814 12.185Q440-304.296 440-286.648q0 17.648 12.186 29.815 12.185 12.166 29.833 12.166ZM480.177-80q-82.822 0-155.666-31.5t-127.178-85.833Q143-251.667 111.5-324.56 80-397.454 80-480.333q0-82.88 31.5-155.773Q143-709 197.333-763q54.334-54 127.227-85.5Q397.454-880 480.333-880q82.88 0 155.773 31.5Q709-817 763-763t85.5 127Q880-563 880-480.177q0 82.822-31.5 155.666T763-197.456q-54 54.21-127 85.833Q563-80 480.177-80Zm.156-66.666q139 0 236.001-97.334 97-97.333 97-236.333t-96.875-236.001q-96.876-97-236.459-97-138.667 0-236 96.875Q146.666-619.583 146.666-480q0 138.667 97.334 236 97.333 97.334 236.333 97.334ZM480-480Zm3.178-177.333q28.489 0 49.989 17.666 21.5 17.667 21.5 44.797 0 24.87-14.932 43.489-14.932 18.619-33.735 35.048Q483-497 465.833-473.41q-17.166 23.59-17.166 53.077 0 12 9.1 19.5t21.233 7.5q13 0 21.75-8.667T512-423.667q3.333-21 16.666-37.833Q542-478.333 558.82-492.522 583-513.333 599.167-540q16.166-26.667 16.166-58.409 0-50.591-38.833-83.758-38.833-33.166-91.468-33.166-36.365 0-69.865 15.333-33.5 15.333-53.5 45.667-7 10.666-5.834 22.833 1.167 12.167 10.813 19.167 12.277 8 25.431 5 13.154-3 21.923-15 12.166-16.936 30.417-25.968 18.25-9.032 38.761-9.032Z" /></svg>,
  'home_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-200h120v-200q0-17 11.5-28.5T400-440h160q17 0 28.5 11.5T600-400v200h120v-360L480-740 240-560v360Zm-80 0v-360q0-19 8.5-36t23.5-28l240-180q21-16 48-16t48 16l240 180q15 11 23.5 28t8.5 36v360q0 33-23.5 56.5T720-120H560q-17 0-28.5-11.5T520-160v-200h-80v200q0 17-11.5 28.5T400-120H240q-33 0-56.5-23.5T160-200Zm320-270Z" /></svg>,
  'home_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M226.666-186.666h140.001V-400q0-14.167 9.583-23.75t23.75-9.583h160q14.167 0 23.75 9.583t9.583 23.75v213.334h140.001v-380.001L480-756.667l-253.334 190v380.001Zm-66.666 0v-380.001q0-15.833 7.083-30 7.084-14.166 19.584-23.333L440-810q17.453-13.333 39.893-13.333T520-810l253.333 190q12.5 9.167 19.584 23.333 7.083 14.167 7.083 30v380.001q0 27.5-19.583 47.083T733.334-120H560q-14.167 0-23.75-9.583-9.583-9.584-9.583-23.75v-213.334h-93.334v213.334q0 14.166-9.583 23.75Q414.167-120 400-120H226.666q-27.5 0-47.083-19.583T160-186.666ZM480-472Z" /></svg>,
  'home_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M220-180h150v-220q0-12.75 8.625-21.375T400-430h160q12.75 0 21.375 8.625T590-400v220h150v-390L480-765 220-570v390Zm-60 0v-390q0-14.25 6.375-27T184-618l260-195q15.68-12 35.84-12Q500-825 516-813l260 195q11.25 8.25 17.625 21T800-570v390q0 24.75-17.625 42.375T740-120H560q-12.75 0-21.375-8.625T530-150v-220H430v220q0 12.75-8.625 21.375T400-120H220q-24.75 0-42.375-17.625T160-180Zm320-293Z" /></svg>,
  'id_card_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M720-440q17 0 28.5-11.5T760-480q0-17-11.5-28.5T720-520H600q-17 0-28.5 11.5T560-480q0 17 11.5 28.5T600-440h120Zm0-120q17 0 28.5-11.5T760-600q0-17-11.5-28.5T720-640H600q-17 0-28.5 11.5T560-600q0 17 11.5 28.5T600-560h120ZM360-440q-36 0-65 6.5T244-413q-21 13-32 29.5T201-348q0 12 9 20t22 8h256q13 0 22-8.5t9-21.5q0-17-11-33t-32-30q-22-14-51-20.5t-65-6.5Zm0-40q33 0 56.5-23.5T440-560q0-33-23.5-56.5T360-640q-33 0-56.5 23.5T280-560q0 33 23.5 56.5T360-480ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z" /></svg>,
  'id_card_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M726.667-446.667q14.166 0 23.75-9.617Q760-465.901 760-480.117q0-14.216-9.583-23.716-9.584-9.5-23.75-9.5h-120q-14.167 0-23.75 9.617t-9.583 23.833q0 14.216 9.583 23.716 9.583 9.5 23.75 9.5h120Zm0-120q14.166 0 23.75-9.617Q760-585.901 760-600.117q0-14.216-9.583-23.716-9.584-9.5-23.75-9.5h-120q-14.167 0-23.75 9.617t-9.583 23.833q0 14.216 9.583 23.716 9.583 9.5 23.75 9.5h120ZM360-436q-37.333 0-66.092 6.5-28.758 6.5-50.575 20.5Q223-397.333 212-380.41q-11 16.923-11 36.41 0 10.286 7.452 17.143Q215.903-320 226.667-320h266.666q10.764 0 18.215-7.178Q519-334.355 519-345.333q0-17.463-10.667-33.898-10.666-16.436-31-29.769-22-14-51-20.5T360-436Zm-.053-53.334q31.053 0 53.22-22.113 22.166-22.114 22.166-53.167t-22.113-53.219Q391.106-640 360.053-640t-53.22 22.114q-22.166 22.113-22.166 53.166 0 31.053 22.113 53.22 22.114 22.166 53.167 22.166ZM146.666-160q-27 0-46.833-19.833T80-226.666v-506.668q0-27 19.833-46.833T146.666-800h666.668q27 0 46.833 19.833T880-733.334v506.668q0 27-19.833 46.833T813.334-160H146.666Zm0-66.666h666.668v-506.668H146.666v506.668Zm0 0v-506.668 506.668Z" /></svg>,
  'id_card_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M730-450q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T730-510H610q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T610-450h120Zm0-120q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T730-630H610q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T610-570h120ZM360-434q-38 0-66.638 6.5Q264.725-421 243-407q-20 11-31 28.135-11 17.134-11 36.865 0 9.429 6.677 15.714Q214.355-320 224-320h272q9.645 0 16.323-6.517Q519-333.033 519-343q0-17.694-10.5-34.347Q498-394 478-407q-22-14-51-20.5t-67-6.5Zm-.08-60q30.08 0 51.58-21.42 21.5-21.421 21.5-51.5 0-30.08-21.42-51.58-21.421-21.5-51.5-21.5-30.08 0-51.58 21.42-21.5 21.421-21.5 51.5 0 30.08 21.42 51.58 21.421 21.5 51.5 21.5ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm0-60h680v-520H140v520Zm0 0v-520 520Z" /></svg>,
  'id_card_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M720-440q17 0 28.5-11.5T760-480q0-17-11.5-28.5T720-520H600q-17 0-28.5 11.5T560-480q0 17 11.5 28.5T600-440h120Zm0-120q17 0 28.5-11.5T760-600q0-17-11.5-28.5T720-640H600q-17 0-28.5 11.5T560-600q0 17 11.5 28.5T600-560h120ZM360-440q-36 0-65 6.5T244-413q-21 13-32 29.5T201-348q0 12 9 20t22 8h256q13 0 22-8.5t9-21.5q0-17-11-33t-32-30q-22-14-51-20.5t-65-6.5Zm0-40q33 0 56.5-23.5T440-560q0-33-23.5-56.5T360-640q-33 0-56.5 23.5T280-560q0 33 23.5 56.5T360-480ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Z" /></svg>,
  'id_card_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M726.667-446.667q14.166 0 23.75-9.617Q760-465.901 760-480.117q0-14.216-9.583-23.716-9.584-9.5-23.75-9.5h-120q-14.167 0-23.75 9.617t-9.583 23.833q0 14.216 9.583 23.716 9.583 9.5 23.75 9.5h120Zm0-120q14.166 0 23.75-9.617Q760-585.901 760-600.117q0-14.216-9.583-23.716-9.584-9.5-23.75-9.5h-120q-14.167 0-23.75 9.617t-9.583 23.833q0 14.216 9.583 23.716 9.583 9.5 23.75 9.5h120ZM360-436q-37.333 0-66.092 6.5-28.758 6.5-50.575 20.5Q223-397.333 212-380.41q-11 16.923-11 36.41 0 10.286 7.452 17.143Q215.903-320 226.667-320h266.666q10.764 0 18.215-7.178Q519-334.355 519-345.333q0-17.463-10.667-33.898-10.666-16.436-31-29.769-22-14-51-20.5T360-436Zm-.053-53.334q31.053 0 53.22-22.113 22.166-22.114 22.166-53.167t-22.113-53.219Q391.106-640 360.053-640t-53.22 22.114q-22.166 22.113-22.166 53.166 0 31.053 22.113 53.22 22.114 22.166 53.167 22.166ZM146.666-160q-27 0-46.833-19.833T80-226.666v-506.668q0-27 19.833-46.833T146.666-800h666.668q27 0 46.833 19.833T880-733.334v506.668q0 27-19.833 46.833T813.334-160H146.666Z" /></svg>,
  'id_card_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M730-450q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T730-510H610q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T610-450h120Zm0-120q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T730-630H610q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T610-570h120ZM360-434q-38 0-66.638 6.5Q264.725-421 243-407q-20 11-31 28.135-11 17.134-11 36.865 0 9.429 6.677 15.714Q214.355-320 224-320h272q9.645 0 16.323-6.517Q519-333.033 519-343q0-17.694-10.5-34.347Q498-394 478-407q-22-14-51-20.5t-67-6.5Zm-.08-60q30.08 0 51.58-21.42 21.5-21.421 21.5-51.5 0-30.08-21.42-51.58-21.421-21.5-51.5-21.5-30.08 0-51.58 21.42-21.5 21.421-21.5 51.5 0 30.08 21.42 51.58 21.421 21.5 51.5 21.5ZM140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Z" /></svg>,
  'info_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-280q17 0 28.5-11.5T520-320v-160q0-17-11.5-28.5T480-520q-17 0-28.5 11.5T440-480v160q0 17 11.5 28.5T480-280Zm0-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'info_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M482.117-280q14.216 0 23.716-9.583 9.5-9.584 9.5-23.75v-173.334q0-14.166-9.617-23.75Q496.099-520 481.883-520q-14.216 0-23.716 9.583-9.5 9.584-9.5 23.75v173.334q0 14.166 9.617 23.75Q467.901-280 482.117-280Zm-2.129-316q15.012 0 25.179-9.966 10.166-9.967 10.166-24.7 0-15.3-10.155-25.65-10.155-10.35-25.166-10.35-15.012 0-25.179 10.35-10.166 10.35-10.166 25.65 0 14.733 10.155 24.7Q464.977-596 479.988-596Zm.189 516q-82.822 0-155.666-31.5t-127.178-85.833Q143-251.667 111.5-324.56 80-397.454 80-480.333q0-82.88 31.5-155.773Q143-709 197.333-763q54.334-54 127.227-85.5Q397.454-880 480.333-880q82.88 0 155.773 31.5Q709-817 763-763t85.5 127Q880-563 880-480.177q0 82.822-31.5 155.666T763-197.456q-54 54.21-127 85.833Q563-80 480.177-80Zm.156-66.666q139 0 236.001-97.334 97-97.333 97-236.333t-96.875-236.001q-96.876-97-236.459-97-138.667 0-236 96.875Q146.666-619.583 146.666-480q0 138.667 97.334 236 97.333 97.334 236.333 97.334ZM480-480Z" /></svg>,
  'key_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-400q-33 0-56.5-23.5T200-480q0-33 23.5-56.5T280-560q33 0 56.5 23.5T360-480q0 33-23.5 56.5T280-400Zm0 160q-100 0-170-70T40-480q0-100 70-170t170-70q67 0 121.5 33t86.5 87h335q8 0 15.5 3t13.5 9l80 80q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L805-325q-5 5-12 8t-14 4q-7 1-14-1t-13-7l-52-39-57 43q-5 4-11 6t-12 2q-6 0-12.5-2t-11.5-6l-61-43h-47q-32 54-86.5 87T280-240Zm0-80q56 0 98.5-34t56.5-86h125l58 41v.5-.5l82-61 71 55 75-75h-.5.5l-40-40v-.5.5H435q-14-52-56.5-86T280-640q-66 0-113 47t-47 113q0 66 47 113t113 47Z" /></svg>,
  'key_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M280-408q-29.667 0-50.833-21.167Q208-450.333 208-480q0-29.667 21.167-50.833Q250.333-552 280-552q29.667 0 50.833 21.167Q352-509.667 352-480q0 29.667-21.167 50.833Q309.667-408 280-408Zm0 168q-100 0-170-70T40-480q0-100 70-170t170-70q70.333 0 124.5 33.667 54.167 33.666 85.5 97.667h341q6.667 0 12.5 2.333t11.167 7.667L936-497.333Q941.333-492 943.5-486t2.167 12.667q0 6.666-2.5 12.333t-7.834 11L809.667-331q-4.334 4.333-10 6.666Q794-322 788.334-321.667q-5.667.333-11-1-5.334-1.333-10.667-5L703.333-374l-65.666 48.333q-4.334 3.333-9.334 4.667-5 1.333-10.333 1.333t-10.5-1.667q-5.167-1.666-9.5-5l-58.333-45H490q-27.333 58-81.166 94.667Q355-240 280-240Zm0-66.666q57.333 0 104.167-37Q431-380.666 445-438h117.667L618-394.333q-.667 0 0 .166.667.167 0-.166h.333H618l86-62.334L782.334-397H782h.334l81.667-77.667q-.334 0 .166.334.5.333-.166-.334h.333-.333L816.667-522v-.167.167H445q-12.666-54.667-58.833-93Q340-653.334 280-653.334q-72 0-122.667 50.667T106.666-480q0 72 50.667 122.667T280-306.666Z" /></svg>,
  'key_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M280-412q-28 0-48-20t-20-48q0-28 20-48t48-20q28 0 48 20t20 48q0 28-20 48t-48 20Zm0 172q-100 0-170-70T40-480q0-100 70-170t170-70q72 0 126 34t85 103h344q6 0 11 2t10 7l82 82q5 5 7 10.5t2 11.5q0 6-2.5 11t-7.5 10L812-334q-4 4-9 6t-10 2q-5 0-9.5-1t-9.5-4l-69-50-70 51q-4 3-8.5 4t-9.5 1q-5 0-9.5-1.5T599-331l-57-46h-51q-25 60-78.5 98.5T280-240Zm0-60q58 0 107-38.5t63-98.5h114l54 45q-1 0 0 0h.5-.5l88-63 82 62h-.5.5l85-79 .5.5-.5-.5h.5-.5l-51-51H450q-12-56-60-96.5T280-660q-75 0-127.5 52.5T100-480q0 75 52.5 127.5T280-300Z" /></svg>,
  'keyboard_double_arrow_down_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-313 156-155q11-11 27.5-11.5T692-468q11 11 11 28t-11 28L508-228q-6 6-13 8.5t-15 2.5q-8 0-15-2.5t-13-8.5L268-412q-11-11-11.5-27.5T268-468q11-11 28-11t28 11l156 155Zm0-240 156-155q11-11 27.5-11.5T692-708q11 11 11 28t-11 28L508-468q-6 6-13 8.5t-15 2.5q-8 0-15-2.5t-13-8.5L268-652q-11-11-11.5-27.5T268-708q11-11 28-11t28 11l156 155Z" /></svg>,
  'keyboard_double_arrow_down_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m480-293.666 170-169.667q9.667-9.667 23.167-9.833 13.5-.167 23.5 9.833 9.666 9.666 9.666 23.333 0 13.667-9.666 23.333L503.333-223.333q-5.333 5.333-11 7.5-5.666 2.166-12.333 2.166t-12.333-2.166q-5.667-2.167-11-7.5L263.333-416.667q-9.666-9.666-9.833-23.166-.167-13.5 9.833-23.5Q273-473 286.666-473q13.667 0 23.334 9.667l170 169.667Zm0-248.667L650-712q9.667-9.667 23.167-9.833 13.5-.167 23.5 9.833 9.666 9.667 9.666 23.333 0 13.667-9.666 23.333L503.333-472q-5.333 5.333-11 7.5-5.666 2.167-12.333 2.167t-12.333-2.167q-5.667-2.167-11-7.5L263.333-665.334Q253.667-675 253.5-688.5q-.167-13.5 9.833-23.5 9.667-9.667 23.333-9.667 13.667 0 23.334 9.667l170 169.667Z" /></svg>,
  'keyboard_double_arrow_down_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m480-284 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L501-221q-5 5-10 7t-11 2q-6 0-11-2t-10-7L261-419q-9-9-9-21t9-21q9-9 21-9t21 9l177 177Zm0-253 177-177q9-9 21-9t21 9q9 9 9 21t-9 21L501-474q-5 5-10 7t-11 2q-6 0-11-2t-10-7L261-672q-9-9-9-21t9-21q9-9 21-9t21 9l177 177Z" /></svg>,
  'kitchen_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-640q-17 0-28.5-11.5T320-680v-40q0-17 11.5-28.5T360-760q17 0 28.5 11.5T400-720v40q0 17-11.5 28.5T360-640Zm0 360q-17 0-28.5-11.5T320-320v-120q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440v120q0 17-11.5 28.5T360-280ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80H240Zm0-80h480v-360H240v360Zm0-440h480v-200H240v200Z" /></svg>,
  'kitchen_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M345.883-646.667q-14.217 0-23.717-9.583-9.5-9.583-9.5-23.75v-52q0-14.167 9.617-23.75t23.834-9.583q14.216 0 23.716 9.583 9.5 9.583 9.5 23.75v52q0 14.167-9.617 23.75t-23.833 9.583Zm0 360.667q-14.217 0-23.717-9.583-9.5-9.584-9.5-23.75V-450q0-14.167 9.617-23.75t23.834-9.583q14.216 0 23.716 9.583 9.5 9.583 9.5 23.75v130.667q0 14.166-9.617 23.75Q360.099-286 345.883-286ZM226.666-80q-27.5 0-47.083-19.583T160-146.666v-666.668q0-27.5 19.583-47.083T226.666-880h506.668q27.5 0 47.083 19.583T800-813.334v666.668q0 27.5-19.583 47.083T733.334-80H226.666Zm0-66.666h506.668V-532H226.666v385.334Zm0-452.001h506.668v-214.667H226.666v214.667Z" /></svg>,
  'kitchen_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M338.825-650Q326-650 317.5-658.625T309-680v-58q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T369-738v58q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Zm0 361Q326-289 317.5-297.625T309-319v-136q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T369-455v136q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625ZM220-80q-24.75 0-42.375-17.625T160-140v-680q0-24.75 17.625-42.375T220-880h520q24.75 0 42.375 17.625T800-820v680q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-398H220v398Zm0-458h520v-222H220v222Z" /></svg>,
  'label_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M807-434 666-234q-11 16-28.5 25t-37.5 9H200q-33 0-56.5-23.5T120-280v-400q0-33 23.5-56.5T200-760h400q20 0 37.5 9t28.5 25l141 200q15 21 15 46t-15 46ZM600-280l142-200-142-200H200v400h400ZM200-680v400-400Z" /></svg>,
  'label_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M813-441.334 669.333-238.667q-12.333 17.334-30.166 28Q621.333-200 600-200H186.666q-27.5 0-47.083-19.583T120-266.666v-426.668q0-27.5 19.583-47.083T186.666-760H600q21.333 0 39.167 10.667 17.833 10.666 30.166 28L813-518.666q12.333 17.647 12.333 38.657 0 21.009-12.333 38.675ZM607.334-266.666l150-213.334-150-213.334H186.666v426.668h420.668ZM186.666-693.334v426.668-426.668Z" /></svg>,
  'label_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M816-445 671-241q-13 18-31 29.5T600-200H180q-24.75 0-42.375-17.625T120-260v-440q0-24.75 17.625-42.375T180-760h420q22 0 40 11.5t31 29.5l145 204q11 15.972 11 34.986Q827-461 816-445ZM611-260l154-220-154-220H180v440h431ZM180-700v440-440Z" /></svg>,
  'landscape_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M120-240q-25 0-36-22t4-42l160-213q6-8 14.5-12t17.5-4q9 0 17.5 4t14.5 12l148 197h300L560-586l-68 90q-12 16-28 16.5t-28-8.5q-12-9-16-24.5t8-31.5l100-133q6-8 14.5-12t17.5-4q9 0 17.5 4t14.5 12l280 373q15 20 4 42t-36 22H120Zm340-80h300-312 68.5H460Zm-260 0h160l-80-107-80 107Zm0 0h160-160Z" /></svg>,
  'landscape_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M106.666-240q-20.744 0-29.872-18.333-9.127-18.333 3.206-35l173.333-231.001q5-6.666 12.084-9.999 7.083-3.334 14.583-3.334t14.583 3.334q7.084 3.333 12.084 9.999L470-306.666h316.667L560-608.001l-90.001 119.335q-10 13.49-23.333 13.911-13.333.422-23.333-7.109-10-7.53-13.333-20.5-3.334-12.969 6.666-26.302l116.667-155.668q5-6.666 12.084-9.999 7.083-3.334 14.583-3.334t14.583 3.334q7.084 3.333 12.084 9.999L880-293.333q12.333 16.667 3.206 35Q874.078-240 853.334-240H106.666Zm336.667-66.666h343.334H460h56.833-73.5Zm-270 0h213.334L280-449.001 173.333-306.666Zm0 0h213.334-213.334Z" /></svg>,
  'landscape_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M100-240q-18.617 0-26.808-16.5Q65-273 76-288l180-240q4.5-6 10.875-9T280-540q6.75 0 13.125 3T304-528l171 228h325L560-619 459-485q-9 12.235-21 12.618-12 .382-21-6.414-9-6.796-12-18.5T411-521l125-167q4.5-6 10.875-9T560-700q6.75 0 13.125 3T584-688l300 400q11 15 2.808 31.5Q878.617-240 860-240H100Zm335-60h365-334 51-82Zm-275 0h240L280-460 160-300Zm0 0h240-240Z" /></svg>,
  'leaderboard_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-200h160v-320H160v320Zm240 0h160v-560H400v560Zm240 0h160v-240H640v240Zm-560 0v-320q0-33 23.5-56.5T160-600h160v-160q0-33 23.5-56.5T400-840h160q33 0 56.5 23.5T640-760v240h160q33 0 56.5 23.5T880-440v240q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200Z" /></svg>,
  'leaderboard_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M146.666-186.666h178.001v-346.668H146.666v346.668Zm244.667 0h177.334v-586.668H391.333v586.668Zm244 0h178.001v-266.668H635.333v266.668Zm-555.333 0v-346.668q0-27.5 19.583-47.083T146.666-600h178.001v-173.334q0-27.5 19.583-47.083T391.333-840h177.334q27.5 0 47.083 19.583t19.583 47.083V-520h178.001q27.5 0 47.083 19.583T880-453.334v266.668q0 27.5-19.583 47.083T813.334-120H146.666q-27.5 0-47.083-19.583T80-186.666Z" /></svg>,
  'leaderboard_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M140-180h187v-360H140v360Zm247 0h186v-600H387v600Zm246 0h187v-280H633v280Zm-553 0v-360q0-24.75 17.625-42.375T140-600h187v-180q0-24.75 17.625-42.375T387-840h186q24.75 0 42.375 17.625T633-780v260h187q24.75 0 42.375 17.625T880-460v280q0 24.75-17.625 42.375T820-120H140q-24.75 0-42.375-17.625T80-180Z" /></svg>,
  'license_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0 320L293-58q-20 7-36.5-5T240-95v-254q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v254q0 20-16.5 32T667-58l-187-62Zm0-200q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z" /></svg>,
  'license_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-446.667q-47.333 0-80.333-33t-33-80.333q0-47.333 33-80.333t80.333-33q47.333 0 80.333 33t33 80.333q0 47.333-33 80.333t-80.333 33Zm0 327.334L287-54.667Q271-49 256.833-59.333q-14.166-10.334-14.166-27v-259.334Q200-391 180-446.333 160-501.667 160-560q0-135.333 92.333-227.667Q344.667-880 480-880t227.667 92.333Q800-695.333 800-560q0 58.333-20 113.667Q760-391 717.333-345.667v259.334q0 16.666-14.166 27Q689-49 673-54.667l-193-64.666Zm0-187.333q106 0 179.667-73.667T733.334-560q0-106-73.667-179.667T480-813.334q-106 0-179.667 73.667T226.666-560q0 106 73.667 179.667T480-306.666Zm-170.667 171L480-186l170.667 50.334V-291q-38.333 26-82.5 38.5T480-240q-44 0-88.167-12.5t-82.5-38.5v155.334Zm170.667-78Z" /></svg>,
  'lightbulb_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM360-200q-17 0-28.5-11.5T320-240q0-17 11.5-28.5T360-280h240q17 0 28.5 11.5T640-240q0 17-11.5 28.5T600-200H360Zm-30-120q-69-41-109.5-110T180-580q0-125 87.5-212.5T480-880q125 0 212.5 87.5T780-580q0 81-40.5 150T630-320H330Zm24-80h252q45-32 69.5-79T700-580q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 54 24.5 101t69.5 79Zm126 0Z" /></svg>,
  'lightbulb_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-80q-33.67 0-57.17-23.5t-23.5-57.17h161.34q0 33.67-23.5 57.17T480-80ZM352-215.33q-14.17 0-23.75-9.62t-9.58-23.83q0-14.22 9.58-23.72 9.58-9.5 23.75-9.5h256q14.17 0 23.75 9.62 9.58 9.61 9.58 23.83 0 14.22-9.58 23.72-9.58 9.5-23.75 9.5H352ZM325.33-336q-67-42.33-106.16-108.33-39.17-66-39.17-147 0-123 88.5-211.5t211.5-88.5q123 0 211.5 88.5t88.5 211.5q0 81-38.83 147-38.84 66-106.5 108.33H325.33ZM348-402.67h264.44q47.23-32 74.06-81.75 26.83-49.75 26.83-106.91 0-96.67-68.33-165-68.33-68.34-165-68.34t-165 68.34q-68.33 68.33-68.33 165 0 57.16 26.83 106.91t74.5 81.75Zm132 0Z" /></svg>,
  'lightbulb_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-80q-34 0-57.5-23.5T399-161h162q0 34-23.5 57.5T480-80ZM348-223q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h264q12.75 0 21.38 8.68 8.62 8.67 8.62 21.5 0 12.82-8.62 21.32-8.63 8.5-21.38 8.5H348Zm-25-121q-66-43-104.5-107.5T180-597q0-122 89-211t211-89q122 0 211 89t89 211q0 81-38 145.5T637-344H323Zm22-60h270.67Q664-436 692-487.13q28-51.13 28-109.87 0-99-70.5-169.5T480-837q-99 0-169.5 70.5T240-597q0 58.74 28 109.87T345-404Zm135 0Z" /></svg>,
  'lightbulb_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM360-200q-17 0-28.5-11.5T320-240q0-17 11.5-28.5T360-280h240q17 0 28.5 11.5T640-240q0 17-11.5 28.5T600-200H360Zm-30-120q-69-41-109.5-110T180-580q0-125 87.5-212.5T480-880q125 0 212.5 87.5T780-580q0 81-40.5 150T630-320H330Z" /></svg>,
  'lightbulb_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-80q-33.67 0-57.17-23.5t-23.5-57.17h161.34q0 33.67-23.5 57.17T480-80ZM352-215.33q-14.33 0-23.83-9.5-9.5-9.5-9.5-23.84 0-14.33 9.5-23.83 9.5-9.5 23.83-9.5h256q14.33 0 23.83 9.5 9.5 9.5 9.5 23.83 0 14.34-9.5 23.84t-23.83 9.5H352ZM325.33-336q-67-42.33-106.16-108.33-39.17-66-39.17-147 0-123 88.5-211.5t211.5-88.5q123 0 211.5 88.5t88.5 211.5q0 81-38.83 147-38.84 66-106.5 108.33H325.33Z" /></svg>,
  'lightbulb_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-80q-34 0-57.5-23.5T399-161h162q0 34-23.5 57.5T480-80ZM348-223q-13 0-21.5-8.5T318-253q0-13 8.5-21.5T348-283h264q13 0 21.5 8.5T642-253q0 13-8.5 21.5T612-223H348Zm-25-121q-66-43-104.5-107.5T180-597q0-122 89-211t211-89q122 0 211 89t89 211q0 81-38 145.5T637-344H323Z" /></svg>,
  'list_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-600q-17 0-28.5-11.5T280-640q0-17 11.5-28.5T320-680h480q17 0 28.5 11.5T840-640q0 17-11.5 28.5T800-600H320Zm0 160q-17 0-28.5-11.5T280-480q0-17 11.5-28.5T320-520h480q17 0 28.5 11.5T840-480q0 17-11.5 28.5T800-440H320Zm0 160q-17 0-28.5-11.5T280-320q0-17 11.5-28.5T320-360h480q17 0 28.5 11.5T840-320q0 17-11.5 28.5T800-280H320ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" /></svg>,
  'list_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M320-613.334q-14.167 0-23.75-9.617t-9.583-23.833q0-14.216 9.583-23.716 9.583-9.5 23.75-9.5h486.667q14.166 0 23.75 9.617Q840-660.766 840-646.55q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H320Zm0 166.667q-14.167 0-23.75-9.617t-9.583-23.833q0-14.216 9.583-23.716 9.583-9.5 23.75-9.5h486.667q14.166 0 23.75 9.617Q840-494.099 840-479.883q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H320ZM320-280q-14.167 0-23.75-9.617t-9.583-23.833q0-14.216 9.583-23.716 9.583-9.5 23.75-9.5h486.667q14.166 0 23.75 9.617Q840-327.432 840-313.216q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H320ZM153.333-613.334q-13.666 0-23.5-9.833Q120-633 120-647q0-14 9.833-23.5 9.834-9.5 23.833-9.5 14 0 23.5 9.583 9.5 9.584 9.5 23.75 0 13.667-9.583 23.5t-23.75 9.833Zm0 166.667q-13.666 0-23.5-9.833Q120-466.333 120-480.333q0-14 9.833-23.5 9.834-9.5 23.833-9.5 14 0 23.5 9.583t9.5 23.75q0 13.667-9.583 23.5t-23.75 9.833Zm0 166.667q-13.666 0-23.5-9.833Q120-299.667 120-313.666q0-14 9.833-23.5 9.834-9.5 23.833-9.5 14 0 23.5 9.583t9.5 23.75q0 13.666-9.583 23.5Q167.5-280 153.333-280Z" /></svg>,
  'list_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M320-620q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-680h490q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-620H320Zm0 170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-510h490q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-450H320Zm0 170q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T320-340h490q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-280H320ZM150-620q-12 0-21-9t-9-21.5q0-12.5 9-21t21.5-8.5q12.5 0 21 8.625T180-650q0 12-8.625 21T150-620Zm0 170q-12 0-21-9t-9-21.5q0-12.5 9-21t21.5-8.5q12.5 0 21 8.625T180-480q0 12-8.625 21T150-450Zm0 170q-12 0-21-9t-9-21.5q0-12.5 9-21t21.5-8.5q12.5 0 21 8.625T180-310q0 12-8.625 21T150-280Z" /></svg>,
  'local_fire_department_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-80q-134 0-227-93t-93-227q0-113 67-217t184-182q22-15 45.5-1.5T480-760v52q0 34 23.5 57t57.5 23q17 0 32.5-7.5T621-657q8-10 20.5-12.5T665-664q63 45 99 115t36 149q0 134-93 227T480-80ZM240-400q0 52 21 98.5t60 81.5q-1-5-1-9v-9q0-32 12-60t35-51l113-111 113 111q23 23 35 51t12 60v9q0 4-1 9 39-35 60-81.5t21-98.5q0-50-18.5-94.5T648-574q-20 13-42 19.5t-45 6.5q-62 0-107.5-41T401-690q-78 66-119.5 140.5T240-400Zm240 52-57 56q-11 11-17 25t-6 29q0 32 23.5 55t56.5 23q33 0 56.5-23t23.5-55q0-16-6-29.5T537-292l-57-56Z" /></svg>,
  'local_fire_department_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-80q-134 0-227-93t-93-227q0-115 70-222.333Q300-729.667 422.334-807q18.666-12.333 38.166-.834 19.5 11.5 19.5 34.5V-708q0 34 23.5 57t57.5 23q17.667 0 33.167-7.5t27.5-22.167q8-9.333 18.833-12.5 10.833-3.166 20.5 3.5 65 45 102 115.667t37 151q0 134-93 227T480-80ZM226.666-400q0 59.334 26 111.834 26.001 52.5 73.001 88.833-3-9.667-4.334-19.333Q320-228.333 320-238q0-32 12-60t35-51l113-111 113 111q23 23 35 51t12 60q0 9.667-1.333 19.334-1.334 9.666-4.334 19.333 47-36.333 73.001-88.833 26-52.5 26-111.834 0-52.667-21.5-101.834-21.5-49.166-61.834-89.5-20.667 14.334-43.333 22.167Q584-561.334 561-561.334q-61.333 0-103.167-41.333Q416-644 413.667-706v-13.334q-87.334 66-137.167 151.167Q226.666-483 226.666-400ZM480-366.667l-66.334 65.333q-13 13-20 29.001-7 16-7 34.333 0 38 27.167 64.667T480-146.666q39 0 66.167-26.667T573.334-238q0-18.667-7-34.5-7-15.834-20-28.834L480-366.667Z" /></svg>,
  'location_on_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-186q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 79q-14 0-28-5t-25-15q-65-60-115-117t-83.5-110.5q-33.5-53.5-51-103T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 45-17.5 94.5t-51 103Q698-301 648-244T533-127q-11 10-25 15t-28 5Zm0-453Zm0 80q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Z" /></svg>,
  'location_on_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-167.999Q609.334-286 671.334-382.166q62-96.167 62-169.834 0-114.86-73.361-188.097Q586.611-813.334 480-813.334q-106.611 0-179.973 73.237Q226.666-666.86 226.666-552q0 73.667 63 169.834Q352.667-286 480-167.999Zm-.167 65.666q-11.833 0-23.5-4-11.666-4-20.666-12.667-49.666-45.333-99-97.332-49.333-52-88.5-107.834Q209-379.999 184.5-437.833 160-495.666 160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 56.334-24.5 114.167-24.5 57.834-63.667 113.667-39.167 55.834-88.5 107.834-49.334 51.999-99 97.332-9 8.667-20.833 12.667-11.833 4-23.667 4ZM480-560Zm.059 73.333q30.274 0 51.774-21.559t21.5-51.833q0-30.274-21.559-51.774t-51.833-21.5q-30.274 0-51.774 21.559t-21.5 51.833q0 30.274 21.559 51.774t51.833 21.5Z" /></svg>,
  'location_on_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-107q-14 0-28-5t-25-15q-65-60-115-117t-83.5-110.5q-33.5-53.5-51-103T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 45-17.5 94.5t-51 103Q698-301 648-244T533-127q-11 10-25 15t-28 5Zm0-373q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Z" /></svg>,
  'location_on_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M479.833-102.333q-11.833 0-23.5-4-11.666-4-20.666-12.667-49.666-45.333-99-97.332-49.333-52-88.5-107.834Q209-379.999 184.5-437.833 160-495.666 160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 56.334-24.5 114.167-24.5 57.834-63.667 113.667-39.167 55.834-88.5 107.834-49.334 51.999-99 97.332-9 8.667-20.833 12.667-11.833 4-23.667 4Zm.226-384.334q30.274 0 51.774-21.559t21.5-51.833q0-30.274-21.559-51.774t-51.833-21.5q-30.274 0-51.774 21.559t-21.5 51.833q0 30.274 21.559 51.774t51.833 21.5Z" /></svg>,
  'location_on_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M479.75-100q-10.75 0-21.25-3.5T440-115q-42-38-91-87.5T258-309q-42-57-70-119t-28-124q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 62-28 124t-70 119q-42 57-91 106.5T520-115q-8 8-18.75 11.5t-21.5 3.5Zm.339-390Q509-490 529.5-510.589q20.5-20.588 20.5-49.5Q550-589 529.411-609.5q-20.588-20.5-49.5-20.5Q451-630 430.5-609.411q-20.5 20.588-20.5 49.5Q410-531 430.589-510.5q20.588 20.5 49.5 20.5Z" /></svg>,
  'lock_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z" /></svg>,
  'lock_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M226.666-80q-27.5 0-47.083-19.583T160-146.666v-422.668q0-27.499 19.583-47.083Q199.166-636 226.666-636h60.001v-90.667q0-80.233 56.57-136.783Q399.808-920 480.071-920q80.262 0 136.762 56.55t56.5 136.783V-636h60.001q27.5 0 47.083 19.583Q800-596.833 800-569.334v422.668q0 27.5-19.583 47.083T733.334-80H226.666Zm0-66.666h506.668v-422.668H226.666v422.668ZM480.112-280q32.221 0 55.055-22.521Q558-325.042 558-356.667q0-31-22.945-55.166Q512.109-436 479.888-436q-32.221 0-55.055 24.167Q402-387.667 402-356.333 402-325 424.945-302.5q22.946 22.5 55.167 22.5ZM353.333-636h253.334v-90.667q0-52.778-36.922-89.722-36.921-36.945-89.667-36.945-52.745 0-89.745 36.945-37 36.944-37 89.722V-636ZM226.666-146.666v-422.668 422.668Z" /></svg>,
  'lock_open_right_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-160h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM240-160v-400 400Zm0 80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h280v-80q0-83 58.5-141.5T720-920q71 0 124 43t70 108q5 17-6.5 33T880-720q-17 0-28-7t-16-23q-11-38-42.5-64T720-840q-50 0-85 35t-35 85v80h120q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Z" /></svg>,
  'lock_open_right_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M226.666-146.666h506.668v-422.668H226.666v422.668ZM480.112-280q32.221 0 55.055-22.521Q558-325.042 558-356.667q0-31-22.945-55.166Q512.109-436 479.888-436q-32.221 0-55.055 24.167Q402-387.667 402-356.333 402-325 424.945-302.5q22.946 22.5 55.167 22.5ZM226.666-146.666v-422.668 422.668Zm0 66.666q-27.5 0-47.083-19.583T160-146.666v-422.668q0-27.499 19.583-47.083Q199.166-636 226.666-636h313.335v-90.667q0-80.233 56.55-136.783Q653.1-920 733.334-920q71 0 123.333 44t66 109.667q3 13.666-6.583 26.666-9.584 13-22.917 13-14.167 0-23.5-6.333t-12.333-20.333q-9.667-42.667-43.967-71.334t-80.033-28.667q-52.778 0-89.723 36.945-36.944 36.944-36.944 89.722V-636h126.667q27.5 0 47.083 19.583Q800-596.833 800-569.334v422.668q0 27.5-19.583 47.083T733.334-80H226.666Z" /></svg>,
  'lock_open_right_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M220-140h520v-434H220v434Zm260.168-140Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM220-140v-434 434Zm0 60q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h330v-96q0-78.85 55.575-134.425Q661.15-920 740-920q71 0 123 44.5T927-765q2 12-6.625 23.5T899.75-730q-12.75 0-21.25-6T868-755q-9-45-44.7-75T740-860q-54.167 0-92.083 37.917Q610-784.167 610-730v96h130q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Z" /></svg>,
  'mail_FILL0_wght300_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M172.309-180.001q-30.308 0-51.308-21t-21-51.308v-455.382q0-30.308 21-51.308t51.308-21h615.382q30.308 0 51.308 21t21 51.308v455.382q0 30.308-21 51.308t-51.308 21H172.309ZM800-662.309 499.461-469.924q-4.615 2.615-9.538 4.115t-9.923 1.5q-5 0-9.923-1.5t-9.538-4.115L160-662.309v410q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h615.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-410ZM480-520l313.846-200H166.154L480-520ZM160-662.309v9.23-45.729 1.192V-720v22.384-1.269 45.806-9.23V-240v-422.309Z" /></svg>,
  'mail_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm640-480L501-453q-5 3-10.5 4.5T480-447q-5 0-10.5-1.5T459-453L160-640v400h640v-400ZM480-520l320-200H160l320 200ZM160-640v10-59 1-32 32-.5 58.5-10 400-400Z" /></svg>,
  'mail_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M146.666-160q-27 0-46.833-19.833T80-226.666v-506.668q0-27 19.833-46.833T146.666-800h666.668q27 0 46.833 19.833T880-733.334v506.668q0 27-19.833 46.833T813.334-160H146.666Zm666.668-510.001L497.667-466.334q-4.334 2.334-8.5 3.834Q485-461 480-461t-9.167-1.5q-4.166-1.5-8.5-3.834L146.666-670.001v443.335h666.668v-443.335ZM480-521.333l330.667-212.001H150l330 212.001ZM146.666-670.001v8-45.878.822-26.277 26-.775V-662.001v-8 443.335-443.335Z" /></svg>,
  'menu_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-240q-17 0-28.5-11.5T120-280q0-17 11.5-28.5T160-320h640q17 0 28.5 11.5T840-280q0 17-11.5 28.5T800-240H160Zm0-200q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520h640q17 0 28.5 11.5T840-480q0 17-11.5 28.5T800-440H160Zm0-200q-17 0-28.5-11.5T120-680q0-17 11.5-28.5T160-720h640q17 0 28.5 11.5T840-680q0 17-11.5 28.5T800-640H160Z" /></svg>,
  'menu_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M153.333-240q-14.166 0-23.75-9.617Q120-259.234 120-273.45q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h653.334q14.166 0 23.75 9.617Q840-287.432 840-273.216q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Zm0-206.667q-14.166 0-23.75-9.617Q120-465.901 120-480.117q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h653.334q14.166 0 23.75 9.617Q840-494.099 840-479.883q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Zm0-206.667q-14.166 0-23.75-9.617Q120-672.568 120-686.784q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h653.334q14.166 0 23.75 9.617Q840-700.766 840-686.55q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5H153.333Z" /></svg>,
  'menu_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M150-240q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-300h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-240H150Zm0-210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-510h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-450H150Zm0-210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150-720h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-660H150Z" /></svg>,
  'mic_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-400q-50 0-85-35t-35-85v-240q0-50 35-85t85-35q50 0 85 35t35 85v240q0 50-35 85t-85 35Zm0-240Zm-40 480v-83q-92-13-157.5-78T203-479q-2-17 9-29t28-12q17 0 28.5 11.5T284-480q14 70 69.5 115T480-320q72 0 127-45.5T676-480q4-17 15.5-28.5T720-520q17 0 28 12t9 29q-14 91-79 157t-158 79v83q0 17-11.5 28.5T480-120q-17 0-28.5-11.5T440-160Zm40-320q17 0 28.5-11.5T520-520v-240q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760v240q0 17 11.5 28.5T480-480Z" /></svg>,
  'mic_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-415.334q-45.333 0-76.333-32.278-31-32.277-31-78.388v-247.334q0-44.444 31.294-75.555 31.294-31.111 76-31.111T556-848.889q31.333 31.111 31.333 75.555V-526q0 46.111-31 78.388-31 32.278-76.333 32.278Zm0-232Zm-33.333 494.001v-98.334q-94.667-11.667-163.5-77.667-68.834-66-80.834-162.333-2-14.236 7.395-24.284Q219.123-526 233.395-526q14.271 0 23.771 9.833T269.333-492Q282-414.667 342.307-365.333 402.614-316 479.764-316 558-316 618-365.5 678-415 690.667-492q2.667-14.334 12.284-24.167Q712.568-526 726.784-526q14.216 0 23.549 10.049 9.334 10.048 7.334 24.284-12 95-80.334 161.667-68.333 66.666-164 78.333v98.334q0 14.166-9.617 23.75Q494.099-120 479.883-120q-14.216 0-23.716-9.583-9.5-9.584-9.5-23.75ZM480-482q17.667 0 29.167-12.833 11.5-12.834 11.5-31.167v-247.334q0-17-11.692-28.5T480-813.334q-17.283 0-28.975 11.5t-11.692 28.5V-526q0 18.333 11.5 31.167Q462.333-482 480-482Z" /></svg>,
  'monetization_on_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M324-370q14 48 43.5 77.5T444-252v17q0 14 10.5 24.5T479-200q14 0 24.5-10.5T514-235v-15q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-15q0-14-10.5-24.5T481-760q-14 0-24.5 10.5T446-725v15q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'monetization_on_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M324-362q18.667 46.667 48.833 74.167 30.167 27.5 75.834 39.166V-225q0 12 9.166 20.834Q467-195.333 479-195.333q12 0 20.833-9.167 8.834-9.166 8.834-21.166v-21.001q57.333-7.666 92-38 34.666-30.333 34.666-83.999 0-48.001-27.333-81.001-27.334-32.999-97.333-61Q452-533.333 427-551q-25-17.667-25-47.667Q402-628 423.167-645q21.166-17 58.833-17 30.667 0 51.333 14.5Q554-633 566.667-606.667l53.333-24q-15-35-43.5-57t-65.833-25.666v-21.001q0-12-8.834-20.833Q493-764 481-764q-12 0-21.167 8.833-9.166 8.833-9.166 20.833v21.001Q400-705 371-673.667t-29 75q0 48.334 29.167 77.334 29.166 29 88.833 52.666 65.667 26.334 90.5 47.334 24.834 21 24.834 52.667 0 32.333-25.5 50.5Q524.333-300 486.667-300q-37 0-65.834-21.5Q392-343 380-382l-56 20ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Zm0-66.666q139.333 0 236.334-97.334 97-97.333 97-236 0-139.333-97-236.334-97.001-97-236.334-97-138.667 0-236 97Q146.666-619.333 146.666-480q0 138.667 97.334 236 97.333 97.334 236 97.334ZM480-480Z" /></svg>,
  'monetization_on_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M324-358q21 46 51.5 72.5T451-247v27q0 11 8.5 19t19.5 8q11 0 19-8.5t8-19.5v-24q61-7 95-37.5t34-81.5q0-51-29-83t-98-61q-58-24-84-43t-26-51q0-31 22.5-49t61.5-18q30 0 52 14t37 42l48-23q-17-35-45-55t-66-24v-24q0-11-8-19t-19-8q-11 0-19.5 8t-8.5 19v24q-51 7-80.5 37.5T343-602q0 49 30 78t90 54q67 28 92 50.5t25 55.5q0 32-26.5 51.5T487-293q-39 0-69.5-22T375-375l-51 17ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>,
  'page_info_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M710-150q-63 0-106.5-43.5T560-300q0-63 43.5-106.5T710-450q63 0 106.5 43.5T860-300q0 63-43.5 106.5T710-150Zm0-80q29 0 49.5-20.5T780-300q0-29-20.5-49.5T710-370q-29 0-49.5 20.5T640-300q0 29 20.5 49.5T710-230Zm-270-30H200q-17 0-28.5-11.5T160-300q0-17 11.5-28.5T200-340h240q17 0 28.5 11.5T480-300q0 17-11.5 28.5T440-260ZM250-510q-63 0-106.5-43.5T100-660q0-63 43.5-106.5T250-810q63 0 106.5 43.5T400-660q0 63-43.5 106.5T250-510Zm0-80q29 0 49.5-20.5T320-660q0-29-20.5-49.5T250-730q-29 0-49.5 20.5T180-660q0 29 20.5 49.5T250-590Zm510-30H520q-17 0-28.5-11.5T480-660q0-17 11.5-28.5T520-700h240q17 0 28.5 11.5T800-660q0 17-11.5 28.5T760-620Zm-50 320ZM250-660Z" /></svg>,
  'page_info_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M710-143.333q-63 0-106.5-43.5t-43.5-106.5q0-63 43.5-106.5t106.5-43.5q63 0 106.5 43.5t43.5 106.5q0 63-43.5 106.5t-106.5 43.5Zm0-66.666q35 0 59.167-24.167t24.167-59.167q0-35-24.167-59.167T710-376.667q-35 0-59.167 24.167t-24.167 59.167q0 35 24.167 59.167T710-209.999ZM446.667-260H166.666q-14.167 0-23.75-9.617t-9.583-23.833q0-14.216 9.583-23.716 9.583-9.5 23.75-9.5h280.001q14.166 0 23.75 9.617Q480-307.432 480-293.216q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5ZM250-516.667q-63 0-106.5-43.5t-43.5-106.5q0-63 43.5-106.5t106.5-43.5q63 0 106.5 43.5t43.5 106.5q0 63-43.5 106.5t-106.5 43.5Zm0-66.666q35 0 59.167-24.167t24.167-59.167q0-35-24.167-59.167T250-750.001q-35 0-59.167 24.167t-24.167 59.167q0 35 24.167 59.167T250-583.333Zm543.334-50.001H513.333q-14.166 0-23.75-9.617Q480-652.568 480-666.784q0-14.216 9.583-23.716 9.584-9.5 23.75-9.5h280.001q14.167 0 23.75 9.617t9.583 23.833q0 14.216-9.583 23.716-9.583 9.5-23.75 9.5ZM710-293.333ZM250-666.667Z" /></svg>,
  'pause_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M600-200q-33 0-56.5-23.5T520-280v-400q0-33 23.5-56.5T600-760h80q33 0 56.5 23.5T760-680v400q0 33-23.5 56.5T680-200h-80Zm-320 0q-33 0-56.5-23.5T200-280v-400q0-33 23.5-56.5T280-760h80q33 0 56.5 23.5T440-680v400q0 33-23.5 56.5T360-200h-80Zm320-80h80v-400h-80v400Zm-320 0h80v-400h-80v400Zm0-400v400-400Zm320 0v400-400Z" /></svg>,
  'pause_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M590-200q-27.5 0-47.083-19.583-19.584-19.583-19.584-47.083v-426.668q0-27.5 19.584-47.083Q562.5-760 590-760h103.334q27.5 0 47.083 19.583T760-693.334v426.668q0 27.5-19.583 47.083T693.334-200H590Zm-323.334 0q-27.5 0-47.083-19.583T200-266.666v-426.668q0-27.5 19.583-47.083T266.666-760H370q27.5 0 47.083 19.583 19.584 19.583 19.584 47.083v426.668q0 27.5-19.584 47.083Q397.5-200 370-200H266.666ZM590-266.666h103.334v-426.668H590v426.668Zm-323.334 0H370v-426.668H266.666v426.668Zm0-426.668v426.668-426.668Zm323.334 0v426.668-426.668Z" /></svg>,
  'pause_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M585-200q-24.75 0-42.375-17.625T525-260v-440q0-24.75 17.625-42.375T585-760h115q24.75 0 42.375 17.625T760-700v440q0 24.75-17.625 42.375T700-200H585Zm-325 0q-24.75 0-42.375-17.625T200-260v-440q0-24.75 17.625-42.375T260-760h115q24.75 0 42.375 17.625T435-700v440q0 24.75-17.625 42.375T375-200H260Zm325-60h115v-440H585v440Zm-325 0h115v-440H260v440Zm0-440v440-440Zm325 0v440-440Z" /></svg>,
  'pause_circle_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-320q17 0 28.5-11.5T440-360v-240q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v240q0 17 11.5 28.5T400-320Zm160 0q17 0 28.5-11.5T600-360v-240q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v240q0 17 11.5 28.5T560-320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'pause_circle_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M400.117-320q14.216 0 23.716-9.583 9.5-9.584 9.5-23.75v-253.334q0-14.166-9.617-23.75Q414.099-640 399.883-640q-14.216 0-23.716 9.583-9.5 9.584-9.5 23.75v253.334q0 14.166 9.617 23.75Q385.901-320 400.117-320Zm160 0q14.216 0 23.716-9.583 9.5-9.584 9.5-23.75v-253.334q0-14.166-9.617-23.75Q574.099-640 559.883-640q-14.216 0-23.716 9.583-9.5 9.584-9.5 23.75v253.334q0 14.166 9.617 23.75Q545.901-320 560.117-320Zm-79.94 240q-82.822 0-155.666-31.5t-127.178-85.833Q143-251.667 111.5-324.56 80-397.454 80-480.333q0-82.88 31.5-155.773Q143-709 197.333-763q54.334-54 127.227-85.5Q397.454-880 480.333-880q82.88 0 155.773 31.5Q709-817 763-763t85.5 127Q880-563 880-480.177q0 82.822-31.5 155.666T763-197.456q-54 54.21-127 85.833Q563-80 480.177-80Zm.156-66.666q139 0 236.001-97.334 97-97.333 97-236.333t-96.875-236.001q-96.876-97-236.459-97-138.667 0-236 96.875Q146.666-619.583 146.666-480q0 138.667 97.334 236 97.333 97.334 236.333 97.334ZM480-480Z" /></svg>,
  'payments_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm400 240H120q-33 0-56.5-23.5T40-240v-400q0-17 11.5-28.5T80-680q17 0 28.5 11.5T120-640v400h640q17 0 28.5 11.5T800-200q0 17-11.5 28.5T760-160ZM280-400v-320 320Z" /></svg>,
  'payments_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M546.666-426.666q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM239.999-293.333q-27.5 0-47.083-19.583t-19.583-47.083v-373.335q0-27.5 19.583-47.083T239.999-800h613.335q27.5 0 47.083 19.583T920-733.334v373.335q0 27.5-19.583 47.083t-47.083 19.583H239.999Zm93.334-66.666H760q0-39 27.167-66.167t66.167-27.167V-640q-39 0-66.167-27.167T760-733.334H333.333q0 39-27.167 66.167T239.999-640v186.667q39 0 66.167 27.167t27.167 66.167ZM766.667-160H106.666q-27.5 0-47.083-19.583T40-226.666v-420.001q0-14.166 9.617-23.75Q59.234-680 73.45-680q14.216 0 23.716 9.583 9.5 9.584 9.5 23.75v420.001h660.001q14.166 0 23.75 9.617Q800-207.432 800-193.216q0 14.216-9.583 23.716-9.584 9.5-23.75 9.5ZM239.999-359.999v-373.335 373.335Z" /></svg>,
  'payments_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M540-420q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM220-280q-24.75 0-42.375-17.625T160-340v-400q0-24.75 17.625-42.375T220-800h640q24.75 0 42.375 17.625T920-740v400q0 24.75-17.625 42.375T860-280H220Zm100-60h440q0-42 29-71t71-29v-200q-42 0-71-29t-29-71H320q0 42-29 71t-71 29v200q42 0 71 29t29 71Zm450 180H100q-24.75 0-42.375-17.625T40-220v-430q0-12.75 8.675-21.375Q57.351-680 70.175-680 83-680 91.5-671.375T100-650v430h670q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-160ZM220-340v-400 400Z" /></svg>,
  'person_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-240v-32q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v32q0 33-23.5 56.5T720-160H240q-33 0-56.5-23.5T160-240Zm80 0h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" /></svg>,
  'person_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-480.667q-66 0-109.667-43.666Q326.667-568 326.667-634t43.666-109.666Q414-787.333 480-787.333t109.667 43.667Q633.333-700 633.333-634t-43.666 109.667Q546-480.667 480-480.667ZM160-226.666V-260q0-36.666 18.5-64.166T226.667-366Q292-396.333 354.333-411.5 416.667-426.666 480-426.666t125.333 15.5q62 15.5 127.281 45.296 30.534 14.417 48.96 41.81Q800-296.666 800-260v33.334q0 27.5-19.583 47.083T733.334-160H226.666q-27.5 0-47.083-19.583T160-226.666Zm66.666 0h506.668V-260q0-14.333-8.167-27t-20.5-19Q644-335.667 590.333-347.833 536.667-360 480-360t-111 12.167Q314.667-335.667 254.667-306q-12.334 6.333-20.167 19-7.834 12.667-7.834 27v33.334ZM480-547.333q37 0 61.833-24.833Q566.667-597 566.667-634t-24.834-61.833Q517-720.667 480-720.667t-61.833 24.834Q393.333-671 393.333-634t24.834 61.834Q443-547.333 480-547.333ZM480-634Zm0 407.334Z" /></svg>,
  'person_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-220v-34q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-292 800-254v34q0 24.75-17.625 42.375T740-160H220q-24.75 0-42.375-17.625T160-220Zm60 0h520v-34q0-16-9.5-30.5T707-306q-64-31-117-42.5T480-360q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570-631q0-39-25.5-64.5T480-721q-39 0-64.5 25.5T390-631q0 39 25.5 64.5T480-541Zm0-90Zm0 411Z" /></svg>,
  'pets_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M180-475q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm180-160q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm240 0q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm180 160q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM266-75q-45 0-75.5-34.5T160-191q0-52 35.5-91t70.5-77q29-31 50-67.5t50-68.5q22-26 51-43t63-17q34 0 63 16t51 42q28 32 49.5 69t50.5 69q35 38 70.5 77t35.5 91q0 47-30.5 81.5T694-75q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z" /></svg>,
  'pets_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M173.239-481.667q-39.239 0-66.239-27.094-27-27.094-27-66.333t27.094-66.239q27.094-27 66.333-27t66.239 27.094q27 27.094 27 66.333t-27.094 66.239q-27.093 27-66.333 27Zm183.334-166.667q-39.24 0-66.24-27.094-27-27.093-27-66.333 0-39.239 27.094-66.239 27.094-27 66.333-27Q396-835 423-807.906q27 27.094 27 66.333t-27.094 66.239q-27.094 27-66.333 27Zm246.667 0q-39.24 0-66.24-27.094-27-27.093-27-66.333Q510-781 537.094-808q27.094-27 66.333-27 39.24 0 66.24 27.094 27 27.094 27 66.333t-27.094 66.239q-27.094 27-66.333 27Zm183.333 166.667q-39.239 0-66.239-27.094-27-27.094-27-66.333t27.094-66.239q27.093-27 66.333-27 39.239 0 66.239 27.094 27 27.094 27 66.333t-27.094 66.239q-27.094 27-66.333 27ZM266-75q-43 0-71.167-32.517-28.166-32.517-28.166-76.816 0-45.333 28.833-80 28.833-34.666 59.833-67.333 24.333-25 44-53.499 19.666-28.5 40.666-56.5 26.667-38 60.334-69.001 33.667-31 79.667-31t80 30.667q34.001 30.667 60.667 69.334 20.667 28 40.167 56.333t43.833 53.666q31 32.667 59.833 67.333 28.833 34.667 28.833 80 0 44.299-28.166 76.816Q737-75 694-75q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z" /></svg>,
  'pets_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M169.859-485Q132-485 106-511.141t-26-64Q80-613 106.141-639t64-26Q208-665 234-638.859t26 64Q260-537 233.859-511t-64 26Zm185-170Q317-655 291-681.141t-26-64Q265-783 291.141-809t64-26Q393-835 419-808.859t26 64Q445-707 418.859-681t-64 26Zm250 0Q567-655 541-681.141t-26-64Q515-783 541.141-809t64-26Q643-835 669-808.859t26 64Q695-707 668.859-681t-64 26Zm185 170Q752-485 726-511.141t-26-64Q700-613 726.141-639t64-26Q828-665 854-638.859t26 64Q880-537 853.859-511t-64 26ZM266-75q-42 0-69-31.526T170-181q0-42 25.5-74.5T250-318q22-22 41-46.5t36-50.5q29-44 65-82t88-38q52 0 88.5 38t65.5 83q17 26 35.5 50t40.5 46q29 30 54.5 62.5T790-181q0 42.948-27 74.474Q736-75 694-75q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z" /></svg>,
  'play_arrow_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-273v-414q0-17 12-28.5t28-11.5q5 0 10.5 1.5T381-721l326 207q9 6 13.5 15t4.5 19q0 10-4.5 19T707-446L381-239q-5 3-10.5 4.5T360-233q-16 0-28-11.5T320-273Zm80-207Zm0 134 210-134-210-134v268Z" /></svg>,
  'play_arrow_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M320-263v-438q0-15 10.044-24.167 10.045-9.167 23.437-9.167 4.185 0 8.769 1.167 4.583 1.167 8.75 3.5L715.667-510q7.667 5.333 11.5 12.333T731-482q0 8.667-3.833 15.667-3.833 6.999-11.5 12.333L371-234.333q-4.189 2.333-8.798 3.5-4.608 1.166-8.797 1.166-13.405 0-23.405-9.166Q320-248 320-263Zm66.666-219Zm0 158.667L636.001-482 386.666-640.667v317.334Z" /></svg>,
  'play_arrow_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M320-258v-450q0-14 9.067-22 9.066-8 21.155-8 3.778 0 7.903 1t7.875 3l354 226q7 5 10.5 11t3.5 14q0 8-3.5 14T720-458L366-232q-3.784 2-7.946 3t-7.946 1Q338-228 329-236t-9-22Zm60-225Zm0 171 269-171-269-171v342Z" /></svg>,
  'play_circle_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m426-330 195-125q14-9 14-25t-14-25L426-630q-15-10-30.5-1.5T380-605v250q0 18 15.5 26.5T426-330Zm54 250q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>,
  'play_circle_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M420.667-331.333 620.333-459q12-7.667 12-21t-12-21L420.667-628.667q-12.334-8.666-25.5-1.5Q382-623 382-607.667v255.334q0 15.333 13.167 22.5 13.166 7.166 25.5-1.5ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Zm0-66.666q139.333 0 236.334-97.334 97-97.333 97-236 0-139.333-97-236.334-97.001-97-236.334-97-138.667 0-236 97Q146.666-619.333 146.666-480q0 138.667 97.334 236 97.333 97.334 236 97.334ZM480-480Z" /></svg>,
  'podcasts_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-120v-291q-18-11-29-28.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 23-11 41t-29 28v291q0 17-11.5 28.5T480-80q-17 0-28.5-11.5T440-120Zm-207-99q-12 12-29 12t-28-13q-45-53-70.5-119T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 75-25.5 141T784-220q-11 13-27.5 13.5T728-218q-11-11-11-28t11-30q34-42 53-94t19-110q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 58 19 109.5t54 93.5q11 13 11.5 29.5T233-219Zm113-113q-12 12-29 12.5T290-333q-23-31-36.5-68T240-480q0-100 70-170t170-70q100 0 170 70t70 170q0 42-13.5 79.5T670-333q-10 13-27 13.5T614-331q-11-11-11.5-28t9.5-31q13-20 20.5-42.5T640-480q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 26 7.5 48t20.5 42q10 14 9.5 30.5T346-332Z" /></svg>,
  'podcasts_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M446.667-113.333v-294.334Q426-417.333 413-436.5q-13-19.167-13-43.5 0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 24.333-13 43.667-13 19.333-33.667 28.666v294.334q0 14.333-9.5 23.833Q494.333-80 480-80q-14.333 0-23.833-9.5-9.5-9.5-9.5-23.833Zm-218.334-101q-10 10-24.666 10-14.667 0-23.667-11Q133-269 106.5-336T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 77-26.5 144T780-215.333q-9 11-23.5 11.5t-24.5-9.5q-9.667-9.667-9-24 .667-14.333 10.333-25.333 38.001-44 59.001-99.334 21-55.333 21-118 0-139.333-97-236.334-97.001-97-236.334-97t-236.334 97q-97 97.001-97 236.334 0 62.667 21 117.834Q188.666-307 227-263q9.666 11 10.5 24.834.833 13.833-9.167 23.833Zm113-113q-10 10-24.333 10.166-14.333.167-23-11.166Q269-360 254.5-398T240-480q0-100 70-170t170-70q100 0 170 70t70 170q0 44-14.5 82.167-14.5 38.166-39.5 69.5-8.667 11-22.667 11.5-14 .5-24-9.5-9.666-9.667-9.833-24-.166-14.333 8.5-25.667 16.334-22 25.834-48.166 9.5-26.167 9.5-55.834 0-72-50.667-122.667T480-653.334q-72 0-122.667 50.667T306.666-480q0 30 9.5 56T342-376q8.666 11.334 8.833 25.167.167 13.833-9.5 23.5Z" /></svg>,
  'podcasts_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M450-110v-296q-22-9-36-29t-14-45q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 25-14 45t-36 29v296q0 13-8.5 21.5T480-80q-13 0-21.5-8.5T450-110ZM226-212q-9 9-22.5 9T182-213q-48-54-75-121.5T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 78-27 145.5T778-213q-8 10-21.5 10.5T734-211q-9-9-8-22t10-23q40-45 62-102t22-122q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 65 22 122t62 102q9 10 10 22.5t-8 21.5Zm113-113q-9 9-22 9t-21-10q-26-32-41-70.5T240-480q0-100 70-170t170-70q100 0 170 70t70 170q0 45-15 83.5T664-326q-8 10-20.5 10.5T622-324q-9-9-9-22t8-23q18-23 28.5-51t10.5-60q0-75-52.5-127.5T480-660q-75 0-127.5 52.5T300-480q0 32 10.5 60t28.5 51q8 10 8.5 22.5T339-325Z" /></svg>,
  'policy_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-84q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 75-23.5 146.5T709-240q-8 11-21.5 11.5T664-238L560-342q-18 11-38.5 16.5T480-320q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 22-5.5 42.5T618-398l60 60q20-41 31-86t11-92v-189l-240-90-240 90v189q0 121 68 220t172 132q16-5 31.5-12t30.5-16q14-8 30.5-6t26.5 16q10 14 6.5 30T588-127q-20 12-40 21.5T505-88q-6 2-12 3t-13 1Zm0-316q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm8-77Z" /></svg>,
  'policy_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480-84q-5.667 0-11-.833-5.333-.834-10.667-2.5-137-46.334-217.666-167.834Q160-376.667 160-520.071v-193.596q0-21.171 12.083-38.108 12.084-16.937 31.25-24.558L456.667-871q12-4.333 23.333-4.333 11.333 0 23.333 4.333l253.334 94.667q19.166 7.621 31.25 24.558Q800-734.838 800-713.667V-520q0 71.666-19.167 141.5Q761.667-308.667 719-248.667q-8 11-21.5 12.167-13.5 1.166-23.5-8.167L562-353.334q-18.667 11-39.607 17.5-20.941 6.5-42.393 6.5-63.333 0-108-44.333-44.666-44.333-44.666-108 0-63.666 44.666-108.333 44.667-44.667 108-44.667T588-590q44.666 44.667 44.666 108.667 0 21.333-5.833 42.166-5.833 20.834-18.167 38.5l78.668 76.001q22.666-42.334 34.333-93Q733.334-468.333 733.334-520v-195.323L480-808.334l-253.334 93.011V-520q0 127.667 71 231 71 103.334 182.334 139.667Q500.667-155 526.834-169q26.167-14 48.5-30.334 11.334-8 24.833-6.666 13.5 1.333 22.167 13 8.667 11.666 6.167 25.666t-14.5 22.333q-26 18.667-53.667 32.501-27.667 13.833-58.667 25.167-5.334 1.666-10.667 2.5-5.333.833-11 .833Zm.136-312q35.531 0 60.697-24.833Q566-445.667 566-481.667t-25.302-61.166Q515.395-568 479.864-568q-35.531 0-60.697 25.167Q394-517.667 394-481.667t25.302 60.834Q444.605-396 480.136-396Zm2.531-82.333Z" /></svg>,
  'policy_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-84q-5 0-10-.75T460-87q-138-47-219-168.5t-81-266.606V-718q0-19.257 10.875-34.662Q181.75-768.068 199-775l260-97q11-4 21-4t21 4l260 97q17.25 6.932 28.125 22.338Q800-737.257 800-718v196q0 70-17 139t-59 130q-8 11-21.5 12.5T679-248L563-359q-19 11-40.161 18-21.162 7-42.839 7-62 0-105.5-43T331-482.5q0-62.5 43.5-106T480-632q62 0 105.5 43.5T629-482q0 21-6 42t-19 38l88 84q24-43 36-96.5T740-522v-198.484L480-815l-260 94.516V-522q0 131 72.5 236.5T480-142q23-6 54.5-23.5T592-203q10-8 22-7t20 11.5q8 10.5 6 23.5t-13 21q-29 22-60.5 38T500-87q-5 1.5-10 2.25T480-84Zm.204-310Q517-394 543-419.5t26-63q0-37.5-26.204-63.5-26.203-26-63-26Q443-572 417-546t-26 63.5q0 37.5 26.204 63 26.203 25.5 63 25.5ZM480-479Z" /></svg>,
  'print_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M320-120q-33 0-56.5-23.5T240-200v-80h-80q-33 0-56.5-23.5T80-360v-160q0-51 35-85.5t85-34.5h560q51 0 85.5 34.5T880-520v160q0 33-23.5 56.5T800-280h-80v80q0 33-23.5 56.5T640-120H320ZM160-360h80q0-33 23.5-56.5T320-440h320q33 0 56.5 23.5T720-360h80v-160q0-17-11.5-28.5T760-560H200q-17 0-28.5 11.5T160-520v160Zm480-280v-120H320v120h-80v-120q0-33 23.5-56.5T320-840h320q33 0 56.5 23.5T720-760v120h-80Zm80 180q17 0 28.5-11.5T760-500q0-17-11.5-28.5T720-540q-17 0-28.5 11.5T680-500q0 17 11.5 28.5T720-460Zm-80 260v-160H320v160h320ZM160-560h640-640Z" /></svg>,
  'print_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M308-120q-27.5 0-47.083-19.583-19.584-19.583-19.584-47.083v-104.001h-94.667q-27.5 0-47.083-19.583T80-357.333v-177.334q0-47.033 32-78.85 32-31.816 78.666-31.816h578.668q47.033 0 78.849 31.816Q880-581.7 880-534.667v177.334q0 27.5-19.583 47.083t-47.083 19.583h-94.667v104.001q0 27.5-19.584 47.083Q679.5-120 652-120H308ZM146.666-357.333h94.667V-368q0-27.5 19.584-47.083Q280.5-434.667 308-434.667h344q27.5 0 47.083 19.584Q718.667-395.5 718.667-368v10.667h94.667V-534.81q0-18.857-12.65-31.357t-31.35-12.5H190.666q-18.7 0-31.35 12.65-12.65 12.65-12.65 31.35v177.334Zm505.334-288v-128.001H308v128.001h-66.667v-128.001q0-27.5 19.584-47.083Q280.5-840 308-840h344q27.5 0 47.083 19.583 19.584 19.583 19.584 47.083v128.001H652Zm80.667 163.332q13.667 0 23.5-9.833t9.833-23.5q0-13.666-9.833-23.5-9.833-9.833-23.5-9.833t-23.5 9.833q-9.833 9.834-9.833 23.5 0 13.667 9.833 23.5t23.5 9.833ZM652-186.666V-368H308v181.334h344ZM146.666-578.667h666.668-666.668Z" /></svg>,
  'print_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M302-120q-24.75 0-42.375-17.625T242-180v-116H140q-24.75 0-42.375-17.625T80-356v-186q0-45.05 30.5-75.525Q141-648 186-648h588q45.05 0 75.525 30.475Q880-587.05 880-542v186q0 24.75-17.625 42.375T820-296H718v116q0 24.75-17.625 42.375T658-120H302ZM140-356h102v-16q0-24.75 17.625-42.375T302-432h356q24.75 0 42.375 17.625T718-372v16h102v-186.215Q820-562 806.775-575 793.55-588 774-588H186q-19.55 0-32.775 13.225Q140-561.55 140-542v186Zm518-292v-132H302v132h-60v-132q0-24.75 17.625-42.375T302-840h356q24.75 0 42.375 17.625T718-780v132h-60Zm81 155q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm-81 313v-192H302v192h356ZM140-588h680-680Z" /></svg>,
  'problem_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-320q17 0 28.5-11.5T320-360q0-17-11.5-28.5T280-400q-17 0-28.5 11.5T240-360q0 17 11.5 28.5T280-320Zm0-120q17 0 28.5-11.5T320-480v-120q0-17-11.5-28.5T280-640q-17 0-28.5 11.5T240-600v120q0 17 11.5 28.5T280-440Zm160 80h240q17 0 28.5-11.5T720-400q0-17-11.5-28.5T680-440H440q-17 0-28.5 11.5T400-400q0 17 11.5 28.5T440-360Zm0-160h240q17 0 28.5-11.5T720-560q0-17-11.5-28.5T680-600H440q-17 0-28.5 11.5T400-560q0 17 11.5 28.5T440-520ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z" /></svg>,
  'problem_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M267.333-321.333q13.667 0 23.5-9.834 9.833-9.833 9.833-23.5 0-13.666-9.833-23.499Q281-388 267.333-388t-23.5 9.834Q234-368.333 234-354.667q0 13.667 9.833 23.5 9.833 9.834 23.5 9.834ZM267.45-448q14.216 0 23.716-9.584 9.5-9.583 9.5-23.749v-127.334q0-14.166-9.617-23.75Q281.432-642 267.216-642 253-642 243.5-632.417q-9.5 9.584-9.5 23.75v127.334q0 14.166 9.617 23.749Q253.234-448 267.45-448ZM440-368h252.667q14.167 0 23.75-9.617T726-401.45q0-14.217-9.583-23.717-9.583-9.5-23.75-9.5H440q-14.167 0-23.75 9.617t-9.583 23.834q0 14.216 9.583 23.716 9.583 9.5 23.75 9.5Zm0-167.334h252.667q14.167 0 23.75-9.617T726-568.784q0-14.216-9.583-23.716-9.583-9.5-23.75-9.5H440q-14.167 0-23.75 9.617t-9.583 23.833q0 14.216 9.583 23.716 9.583 9.5 23.75 9.5ZM141.333-160q-27 0-46.833-19.833-19.834-19.833-19.834-46.833v-506.668q0-27 19.834-46.833Q114.333-800 141.333-800h677.334q27 0 46.833 19.833t19.833 46.833v506.668q0 27-19.833 46.833T818.667-160H141.333Zm0-66.666h677.334v-506.668H141.333v506.668Zm0 0v-506.668 506.668Z" /></svg>,
  'problem_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M261-322q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm.175-130q12.825 0 21.325-8.625T291-482v-131q0-12.75-8.675-21.375-8.676-8.625-21.5-8.625-12.825 0-21.325 8.625T231-613v131q0 12.75 8.675 21.375 8.676 8.625 21.5 8.625ZM440-372h259q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T699-432H440q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T440-372Zm0-171h259q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T699-603H440q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T440-543ZM132-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h696q24 0 42 18t18 42v520q0 24-18 42t-42 18H132Zm0-60h696v-520H132v520Zm0 0v-520 520Z" /></svg>,
  'real_estate_agent_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m558-144 238-74q-5-9-14.5-15.5T760-240H558q-27 0-43-2t-33-8l-57-19q-16-5-23-20t-2-31q5-16 19.5-23.5T450-346l42 14q17 5 38.5 8t58.5 4h11q0-11-6.5-21T578-354l-234-86h-64v220l278 76Zm-21 78-257-72q-8 26-31.5 42T200-80h-80q-33 0-56.5-23.5T40-160v-280q0-33 23.5-56.5T120-520h224q7 0 14 1.5t13 3.5l235 87q33 12 53.5 42t20.5 66h80q50 0 85 33t35 87q0 22-11.5 34.5T833-145L583-67q-11 4-23 4t-23-3Zm-417-94h80v-280h-80v280Zm440-722q12 0 23.5 3.5T606-867l200 143q16 11 25 28t9 37v219q0 17-11.5 28.5T800-400q-17 0-28.5-11.5T760-440v-220L560-800 360-660v20q0 17-11.5 28.5T320-600q-17 0-28.5-11.5T280-640v-19q0-20 9-37t25-28l200-143q11-8 22.5-11.5T560-882Zm0 102Zm-40 140q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-80 80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z" /></svg>,
  'real_estate_agent_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m560.667-121.333 244.667-76.666q-1.667-16.334-15.167-27.834t-31.5-10.834H552q-19.667.667-35.333-2-15.667-2.666-31.334-8l-63-19.666q-13.333-4.334-19.666-17.334-6.334-12.999-2-26.333 4.333-13.333 16.685-19.833 12.352-6.5 25.981-1.5L500-312.666q13 4.333 27.833 6.667 14.833 2.333 34.5 2.666h41q0-17.667-11.5-31.333-11.5-13.667-27.833-20.001l-223.333-84h-77.334v232.001l297.334 85.333Zm-15.667 64-281.667-80q-4 26.666-25.5 42Q216.333-80 196.667-80h-90.001q-27.5 0-47.083-19.583T40-146.666v-292.001q0-27.499 19.583-47.083 19.583-19.583 47.083-19.583H340q5.889 0 11.778 1.167Q357.666-503 363-501l223.666 83.667q39 14.667 62.167 44.667T672-303.333h86.667q50.555 0 85.944 35.667T880-179.999q0 14.666-7.5 24.833Q865-145 850.334-140.333L583-57.666Q573.886-55 563.943-55 554-55 545-57.333Zm-438.334-89.333H196v-292.001h-89.334v292.001Zm459.873-744.668q10.128 0 19.961 3.167Q596.333-885 605.333-879L812-731.334q13.333 9 20.667 23.667Q840-693 840-677.001v267.002q0 14.166-9.617 23.75-9.617 9.583-23.833 9.583-14.216 0-23.716-9.583-9.5-9.584-9.5-23.75v-268.002L566.667-822.667 360-678.001v72.669q0 14.166-9.617 23.75-9.617 9.583-23.833 9.583-14.216 0-23.716-9.583-9.5-9.584-9.5-23.75v-71.669q0-15.999 7.333-30.666t20.667-23.667L528-879q9-6 18.706-9.167t19.833-3.167Zm.128 75.333Zm-40 182.668q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-80 80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z" /></svg>,
  'real_estate_agent_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m562-110 248-78q0-20-15.5-34T758-235H549q-16 1-31.5-2t-30.5-8l-66-20q-12-4-18-16t-2-24q4-12 15.278-18T440-324l64 21q11 4 22.5 6t22.5 2h56q2 0 0 0 0-21-14-36.5T557-355l-218-83h-84v238l307 90Zm-13 57-294-84q-2 27-22.5 42T195-80h-95q-24.75 0-42.375-17.625T40-140v-298q0-24.75 17.625-42.375T100-498h238q5.333 0 10.667 1Q354-496 359-494l218 82q42 16 66.5 46t24.5 71h90q50.833 0 86.417 37Q880-221 880-170q0 11-5.5 20T859-138L583-53q-8.171 2-17.086 2Q557-51 549-53Zm-449-87h94v-298h-94v298Zm469.809-756Q579-896 588-893q9 3 17 8l210 150q12 8 18.5 21.5T840-686v291q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T780-395v-292L570-834 360-687v99q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T300-588v-98q0-14 6.5-27.5T325-735l210-150q8-5 16.808-8 8.809-3 18.001-3Zm.191 62Zm-40 204q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm-80 80q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Zm80 0q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6Z" /></svg>,
  'request_quote_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-240v20q0 8 6 14t14 6h40q8 0 14-6t6-14v-20h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h120q17 0 28.5-11.5T600-520q0-17-11.5-28.5T560-560h-40v-20q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14v20h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H400q-17 0-28.5 11.5T360-280q0 17 11.5 28.5T400-240h40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h360l200 200v520q0 33-23.5 56.5T720-80H240Zm0-80h480v-480H600q-17 0-28.5-11.5T560-680v-120H240v640Zm0-640v160-160 640-640Z" /></svg>,
  'request_quote_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M446.667-240.667v23.334q0 6.666 5 11.666t11.666 5h33.334q6.666 0 11.666-5t5-11.666v-23.334h53.334q14.166 0 23.75-9.583Q600-259.833 600-274v-126.667q0-14.166-9.583-23.75-9.584-9.583-23.75-9.583H426.666v-60h140.001q14.166 0 23.75-9.617Q600-513.234 600-527.451q0-14.216-9.583-23.716-9.584-9.5-23.75-9.5h-53.334V-584q0-6.667-5-11.667t-11.666-5h-33.334q-6.666 0-11.666 5t-5 11.667v23.333h-53.334q-14.166 0-23.75 9.584Q360-541.5 360-527.334v126.667q0 14.167 9.583 23.75 9.584 9.583 23.75 9.583h140.001v60.001H393.333q-14.166 0-23.75 9.617Q360-288.099 360-273.883q0 14.216 9.583 23.716 9.584 9.5 23.75 9.5h53.334ZM226.666-80q-27 0-46.833-19.833T160-146.666v-666.668q0-27 19.833-46.833T226.666-880h360.667L800-667.333v520.667q0 27-19.833 46.833T733.334-80H226.666Zm0-66.666h506.668V-636H589.333q-14.167 0-23.75-9.583T556-669.333v-144.001H226.666v666.668Zm0-666.668V-636v-177.334 666.668-666.668Z" /></svg>,
  'request_quote_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M450-241v25q0 6 4.5 10.5T465-201h30q6 0 10.5-4.5T510-216v-25h60q12.75 0 21.375-8.625T600-271v-130q0-12.75-8.625-21.375T570-431H420v-70h150q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T570-561h-60v-25q0-6-4.5-10.5T495-601h-30q-6 0-10.5 4.5T450-586v25h-60q-12.75 0-21.375 8.625T360-531v130q0 12.75 8.625 21.375T390-371h150v70H390q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T390-241h60ZM220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm0-60h520v-494H584q-12.75 0-21.375-8.625T554-664v-156H220v680Zm0-680v186-186 680-680Z" /></svg>,
  'rotate_90_degrees_ccw_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M520-80q-37 0-72.5-7.5T378-109q-15-6-20-21.5t1-30.5q6-15 21-21.5t30 .5q26 11 53.5 16.5T520-160q117 0 198.5-81.5T800-440q0-117-82-198.5T519-720h-7l36 36q11 11 11 28t-11 28q-11 11-28 11t-28-11L388-732q-5-5-8-12.5t-3-15.5q0-8 3-15.5t8-12.5l104-104q11-11 28-11t28 11q11 11 11 28t-11 28l-36 36h7q150 0 255.5 105T880-440q0 150-105 255T520-80ZM280-217q-8 0-15-2.5t-13-8.5L68-412q-6-6-8.5-13T57-440q0-8 2.5-15t8.5-13l184-184q6-6 13-8.5t15-2.5q8 0 15 2.5t13 8.5l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L308-228q-6 6-13 8.5t-15 2.5Zm0-97 126-126-126-126-126 126 126 126Zm0-126Z" /></svg>,
  'rotate_90_degrees_ccw_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M520-80q-44.334 0-85.834-10.5T353.333-121q-12.334-6-16.334-19.5t2-25.833q5.334-12.334 18.334-17.167 13-4.833 25.333 1.5 32 17 66.5 26.167 34.5 9.167 70.834 9.167 122.333 0 207.834-85.5 85.5-85.501 85.5-207.834 0-123-85.667-209.5T519-736h-23l47.333 47.333Q553-679 553-665.334q0 13.667-9.667 23.334-9.666 9.666-23.333 9.666-13.667 0-23.333-9.666l-104-104q-5-5-7.334-10.834-2.333-5.833-2.333-12.5 0-6.666 2.333-12.499 2.334-5.834 7.334-10.834l104-104q9.666-9.666 23.333-9.666 13.667 0 23.333 9.666Q553-887 553-873.334q0 13.667-9.667 23.334L496-802.667h23q150.667 0 255.833 106Q880-590.667 880-440q0 150-105 255T520-80ZM268-227q-6.667 0-12.334-2.167-5.666-2.167-11-7.5L63.333-418q-5.333-5.333-7.5-11-2.166-5.667-2.166-12.333 0-6.667 2.166-12.334 2.167-5.666 7.5-11L244.666-646q5.334-5.333 11-7.5 5.667-2.166 12.334-2.166 6.666 0 12.333 2.166 5.667 2.167 11 7.5l181.333 181.333q5.333 5.334 7.5 11 2.167 5.667 2.167 12.334 0 6.666-2.167 12.333t-7.5 11L291.333-236.667q-5.333 5.333-11 7.5T268-227Zm0-79 132.667-132.667L268-571.333 135.333-438.667 268-306Zm0-132.667Z" /></svg>,
  'rotate_90_degrees_ccw_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M520-80q-48 0-92.5-12T341-127q-11-6-14.5-18.5T329-169q5-11 17-15t23 2q35 20 73 31t78 11q125 0 212.5-87.5T820-440q0-126-87.5-215T519-744h-31l53 53q9 9 9 21t-9 21q-9 9-21 9t-21-9L395-753q-5-5-7-10t-2-11q0-6 2-11t7-10l104-104q9-9 21-9t21 9q9 9 9 21t-9 21l-53 53h31q151 0 256 106.5T880-440q0 150-105 255T520-80ZM262-232q-6 0-11-2t-10-7L61-421q-5-5-7-10t-2-11q0-6 2-11t7-10l180-180q5-5 10-7t11-2q6 0 11 2t10 7l180 180q5 5 7 10t2 11q0 6-2 11t-7 10L283-241q-5 5-10 7t-11 2Zm0-70 136-136-136-136-136 136 136 136Zm0-136Z" /></svg>,
  'rotate_90_degrees_cw_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-80q-150 0-255-105T80-440q0-150 105.5-255T441-800h7l-36-36q-11-11-11-28t11-28q11-11 28-11t28 11l104 104q5 5 8 12.5t3 15.5q0 8-3 15.5t-8 12.5L468-628q-11 11-28 11t-28-11q-11-11-11-28t11-28l36-36h-7q-117 0-199 81.5T160-440q0 117 81.5 198.5T440-160q29 0 56.5-5.5T550-182q15-7 30-.5t21 21.5q6 15 1 30.5T582-109q-34 14-69.5 21.5T440-80Zm212-148L468-412q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l184-184q6-6 13-8.5t15-2.5q8 0 15 2.5t13 8.5l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L708-228q-6 6-13 8.5t-15 2.5q-8 0-15-2.5t-13-8.5Zm28-86 126-126-126-126-126 126 126 126Zm0-126Z" /></svg>,
  'rotate_90_degrees_cw_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M440-80q-150 0-255-105T80-440q0-150.667 105.167-256.667 105.166-106 255.833-106h23L416.667-850Q407-859.667 407-873.334q0-13.666 9.667-23.333 9.666-9.666 23.333-9.666 13.667 0 23.333 9.666l104 104q5 5 7.334 10.834Q577-776 577-769.334q0 6.667-2.333 12.5Q572.333-751 567.333-746l-104 104q-9.666 9.666-23.333 9.666-13.667 0-23.333-9.666Q407-651.667 407-665.334q0-13.666 9.667-23.333L464-736h-23q-123 0-208.667 86.5T146.666-440q0 122.333 85.5 207.834 85.501 85.5 207.834 85.5 36.334 0 70.834-9.167t66.5-26.167q12.333-6.333 25.333-1.5 13 4.833 18.334 17.167 6 12.333 2 25.833-4 13.5-16.334 19.5-39.333 20-80.833 30.5T440-80Zm228.667-156.667L487.334-418q-5.333-5.333-7.5-11t-2.167-12.333q0-6.667 2.167-12.334 2.167-5.666 7.5-11L668.667-646q5.333-5.333 11-7.5 5.667-2.166 12.333-2.166 6.667 0 12.334 2.166 5.666 2.167 11 7.5l181.333 181.333q5.333 5.334 7.5 11 2.166 5.667 2.166 12.334 0 6.666-2.166 12.333-2.167 5.667-7.5 11L715.334-236.667q-5.334 5.333-11 7.5Q698.667-227 692-227q-6.666 0-12.333-2.167t-11-7.5ZM692-306l132.667-132.667L692-571.333 559.333-438.667 692-306Zm0-132.667Z" /></svg>,
  'rotate_90_degrees_cw_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M440-80q-150 0-255-105T80-440q0-151 105-257.5T441-804h31l-53-53q-9-9-9-21t9-21q9-9 21-9t21 9l104 104q5 5 7 10t2 11q0 6-2 11t-7 10L461-649q-9 9-21 9t-21-9q-9-9-9-21t9-21l53-53h-31q-126 0-213.5 89T140-440q0 125 87.5 212.5T440-140q40 0 78-11t73-31q11-6 23-2t17 15q6 11 2.5 23.5T619-127q-42 23-86.5 35T440-80Zm237-161L497-421q-5-5-7-10t-2-11q0-6 2-11t7-10l180-180q5-5 10-7t11-2q6 0 11 2t10 7l180 180q5 5 7 10t2 11q0 6-2 11t-7 10L719-241q-5 5-10 7t-11 2q-6 0-11-2t-10-7Zm21-61 136-136-136-136-136 136 136 136Zm0-136Z" /></svg>,
  'schedule_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M520-496v-144q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640v159q0 8 3 15.5t9 13.5l132 132q11 11 28 11t28-11q11-11 11-28t-11-28L520-496ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z" /></svg>,
  'schedule_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M515.333-493.333v-162.001q0-14.333-9.5-23.833-9.5-9.5-23.833-9.5-14.333 0-23.833 9.5-9.5 9.5-9.5 23.833v175.001q0 6.666 2.333 12.5 2.334 5.833 7 11.166l140 143.334q9.667 10.333 24.334 10 14.666-.334 24.333-10 9.666-9.667 9.666-24t-9.666-24l-131.334-132ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-82.333 31.5-155.333t85.833-127.334Q251.667-817 324.667-848.5T480-880q82.333 0 155.333 31.5t127.334 85.833Q817-708.333 848.5-635.333T880-480q0 82.333-31.5 155.333t-85.833 127.334Q708.333-143 635.333-111.5T480-80Zm0-400Zm0 333.334q137.667 0 235.5-97.834 97.834-97.833 97.834-235.5T715.5-715.5q-97.833-97.834-235.5-97.834T244.5-715.5q-97.834 97.833-97.834 235.5T244.5-244.5q97.833 97.834 235.5 97.834Z" /></svg>,
  'schedule_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M513-492v-171q0-13-8.5-21.5T483-693q-13 0-21.5 8.5T453-663v183q0 6 2 11t6 10l144 149q9 10 22.5 9.5T650-310q9-9 9-22t-9-22L513-492ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-82 31.5-155t86-127.5Q252-817 325-848.5T480-880q82 0 155 31.5t127.5 86Q817-708 848.5-635T880-480q0 82-31.5 155t-86 127.5Q708-143 635-111.5T480-80Zm0-400Zm0 340q140 0 240-100t100-240q0-140-100-240T480-820q-140 0-240 100T140-480q0 140 100 240t240 100Z" /></svg>,
  'search_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M380-320q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l224 224q11 11 11 28t-11 28q-11 11-28 11t-28-11L532-372q-30 24-69 38t-83 14Zm0-80q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>,
  'search_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M378.667-326q-108.441 0-183.554-75.167Q120-476.333 120-583.333T195.167-765.5q75.166-75.167 182.5-75.167 107.333 0 182.166 75.167 74.834 75.167 74.834 182.267 0 43.233-14 82.9-14 39.666-40.667 73l236 234.667q9.667 9.37 9.667 23.851 0 14.482-9.667 24.148Q806.333-135 791.852-135T768-144.667L532.667-380q-30 25.333-69.64 39.666Q423.388-326 378.667-326ZM378-392.666q79.167 0 134.583-55.834Q568-504.333 568-583.333q0-79.001-55.417-134.834Q457.167-774 378-774q-79.722 0-135.528 55.833t-55.806 134.834q0 79 55.806 134.833Q298.278-392.666 378-392.666Z" /></svg>,
  'sell_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M570-104q-23 23-57 23t-57-23L104-456q-11-11-17.5-26T80-514v-286q0-33 23.5-56.5T160-880h286q17 0 32 6.5t26 17.5l352 353q23 23 23 56.5T856-390L570-104Zm-57-56 286-286-353-354H160v286l353 354ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640ZM160-800Z" /></svg>,
  'sell_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M562.666-99.333Q543-79.667 514.667-79.667q-28.333 0-48-19.666L99.333-466.667Q89-477 84.5-489.334 80-501.667 80-515.333v-298.001q0-28.333 19.166-47.499Q118.334-880 146.666-880h298.001q13.666 0 26.666 4.5t23.333 14.833l366.001 366.334Q881-474 881-445.833q0 28.166-20.333 48.499l-298.001 298Zm-46.333-46 298.001-298-369.667-370.001H146.666v298.001l369.667 370ZM250-656q22.333 0 38.5-16.167 16.166-16.167 16.166-38.5t-16.166-38.5q-16.167-16.166-38.5-16.166-22.334 0-38.5 16.166-16.167 16.167-16.167 38.5t16.167 38.5Q227.666-656 250-656ZM146.666-813.334Z" /></svg>,
  'sell_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M559-97q-18 18-43.5 18T472-97L97-472q-10-10-13.5-21T80-516v-304q0-26 17-43t43-17h304q12 0 24 3.5t22 13.5l373 373q19 19 19 44.5T863-401L559-97Zm-41-41 304-304-378-378H140v304l378 378ZM245-664q21 0 36.5-15.5T297-716q0-21-15.5-36.5T245-768q-21 0-36.5 15.5T193-716q0 21 15.5 36.5T245-664ZM140-820Z" /></svg>,
  'send_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M792-443 176-183q-20 8-38-3.5T120-220v-520q0-22 18-33.5t38-3.5l616 260q25 11 25 37t-25 37ZM200-280l474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" /></svg>,
  'send_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M806.667-449 166.666-179.667Q150-173 135-182.5T120-210v-540q0-18 15-27.5t31.666-2.833L806.667-511Q827-502 827-480t-20.333 31ZM186.666-261.999 707.334-480 186.666-700.001v158.668L428-480l-241.334 60v158.001Zm0 0v-438.002V-261.999Z" /></svg>,
  'settings_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M433-80q-27 0-46.5-18T363-142l-9-66q-13-5-24.5-12T307-235l-62 26q-25 11-50 2t-39-32l-47-82q-14-23-8-49t27-43l53-40q-1-7-1-13.5v-27q0-6.5 1-13.5l-53-40q-21-17-27-43t8-49l47-82q14-23 39-32t50 2l62 26q11-8 23-15t24-12l9-66q4-26 23.5-44t46.5-18h94q27 0 46.5 18t23.5 44l9 66q13 5 24.5 12t22.5 15l62-26q25-11 50-2t39 32l47 82q14 23 8 49t-27 43l-53 40q1 7 1 13.5v27q0 6.5-2 13.5l53 40q21 17 27 43t-8 49l-48 82q-14 23-39 32t-50-2l-60-26q-11 8-23 15t-24 12l-9 66q-4 26-23.5 44T527-80h-94Zm7-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" /></svg>,
  'settings_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M425-80q-18.333 0-32.166-12-13.834-12-16.5-29.999l-13-84.668Q346.333-213 328.5-223.333 310.666-233.667 296.333-245l-78 35.333Q200.666-202 183-208q-17.667-6-27.667-22.333L101-327q-10-16.333-5.667-34.333t19.333-29.666l71.001-52.667q-1.667-8.334-2-18.167-.334-9.834-.334-18.167 0-8.333.334-18.167.333-9.833 2-18.167l-71.001-52.667q-15-11.666-19.333-29.666Q91-616.667 101-633l54.333-96.667Q165.333-746 183-752q17.666-6 35.333 1.667l78 35.333q14.333-11.333 32.334-21.667 18-10.333 34.667-16l13-85.334Q379-856 392.834-868q13.833-12 32.166-12h110q18.333 0 32.167 12 13.833 12 16.499 29.999l13 84.668Q613.667-747 631.833-737q18.167 10 31.834 22l78-35.333q17.667-7.667 35-1.667T804-729.667L859-633q10 16.333 5.667 34.666-4.333 18.334-19.333 29.333l-71.001 51.334q1.667 9 2 18.834.334 9.833.334 18.833 0 9-.334 18.5Q776-452 774-443l71 52.001q15 10.999 19.333 29.333 4.334 18.333-5.666 34.666L804-230.333Q794-214 776.334-208q-17.667 6-35.333-1.667L663.667-245q-14.333 11.333-32 22t-35.001 16.333l-13 84.668Q581-104 567.167-91.999 553.333-80 535-80H425Zm12.333-66.666h85l14-110q32.334-8 60.834-24.5T649-321l103.667 44.334 39.667-70.667L701-415q4.334-16 6.667-32.167Q710-463.333 710-480q0-16.667-2-32.833Q706-529 701-545l91.334-67.667-39.667-70.667L649-638.667q-22.666-25-50.833-41.833-28.167-16.834-61.834-22.834l-13.666-110h-85l-14 110q-33 7.334-61.501 23.834Q333.666-663 311-639l-103.667-44.334-39.667 70.667L259-545.333Q254.666-529 252.333-513T250-480q0 16.667 2.333 32.667T259-415l-91.334 67.667 39.667 70.667L311-321.333q23.333 23.667 51.833 40.167t60.834 24.5l13.666 110Zm43.334-200.001q55.333 0 94.333-39T614-480q0-55.333-39-94.333t-94.333-39q-55.667 0-94.5 39-38.834 39-38.834 94.333t38.834 94.333q38.833 39 94.5 39ZM480-480Z" /></svg>,
  'settings_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M421-80q-14 0-25-9t-13-23l-15-94q-19-7-40-19t-37-25l-86 40q-14 6-28 1.5T155-226L97-330q-8-13-4.5-27t15.5-23l80-59q-2-9-2.5-20.5T185-480q0-9 .5-20.5T188-521l-80-59q-12-9-15.5-23t4.5-27l58-104q8-13 22-17.5t28 1.5l86 40q16-13 37-25t40-18l15-95q2-14 13-23t25-9h118q14 0 25 9t13 23l15 94q19 7 40.5 18.5T669-710l86-40q14-6 27.5-1.5T804-734l59 104q8 13 4.5 27.5T852-580l-80 57q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l80 58q12 8 15.5 22.5T863-330l-58 104q-8 13-22 17.5t-28-1.5l-86-40q-16 13-36.5 25.5T592-206l-15 94q-2 14-13 23t-25 9H421Zm15-60h88l14-112q33-8 62.5-25t53.5-41l106 46 40-72-94-69q4-17 6.5-33.5T715-480q0-17-2-33.5t-7-33.5l94-69-40-72-106 46q-23-26-52-43.5T538-708l-14-112h-88l-14 112q-34 7-63.5 24T306-642l-106-46-40 72 94 69q-4 17-6.5 33.5T245-480q0 17 2.5 33.5T254-413l-94 69 40 72 106-46q24 24 53.5 41t62.5 25l14 112Zm44-210q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Zm0-130Z" /></svg>,
  'smoking_rooms_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M140-200q-25 0-42.5-17.5T80-260q0-25 17.5-42.5T140-320h510q13 0 21.5 8.5T680-290v60q0 13-8.5 21.5T650-200H140Zm610 0q-13 0-21.5-8.5T720-230v-60q0-13 8.5-21.5T750-320q13 0 21.5 8.5T780-290v60q0 13-8.5 21.5T750-200Zm100 0q-13 0-21.5-8.5T820-230v-60q0-13 8.5-21.5T850-320q13 0 21.5 8.5T880-290v60q0 13-8.5 21.5T850-200ZM750-360q-13 0-21.5-8.5T720-390v-22q0-39-23-59.5T642-492h-62q-56 0-95-39t-39-95q0-56 39-95t95-39q13 0 21.5 8.5T610-730q0 13-8.5 21.5T580-700q-30 0-52 21t-22 53q0 32 22 53t52 21h62q56 0 97 36t41 90v36q0 13-8.5 21.5T750-360Zm100 0q-13 0-21.5-8.5T820-390v-60q0-66-46-114t-114-48q-13 0-21.5-8.5T630-642q0-13 8.5-21.5T660-672q30 0 52-22t22-52q0-30-22-52t-52-22q-13 0-21.5-8.5T630-850q0-13 8.5-21.5T660-880q56 0 95 39t39 95q0 29-11 52.5T754-650q56 26 91 80t35 120v60q0 13-8.5 21.5T850-360Z" /></svg>,
  'smoking_rooms_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M133.333-200q-22.222 0-37.778-15.578Q80-231.157 80-253.412q0-22.254 15.555-37.754 15.556-15.5 37.778-15.5H660q11.556 0 19.111 7.564 7.556 7.565 7.556 19.135v53.398q0 11.569-7.556 19.069Q671.556-200 660-200H133.333Zm623.256 0Q745-200 737.5-207.565q-7.5-7.564-7.5-19.134v-53.398q0-11.569 7.578-19.069 7.577-7.5 19.166-7.5t19.089 7.564q7.5 7.565 7.5 19.135v53.398q0 11.569-7.577 19.069-7.578 7.5-19.167 7.5Zm96.667 0q-11.589 0-19.089-7.565-7.5-7.564-7.5-19.134v-53.398q0-11.569 7.577-19.069 7.578-7.5 19.167-7.5 11.589 0 19.089 7.564 7.5 7.565 7.5 19.135v53.398q0 11.569-7.578 19.069-7.577 7.5-19.166 7.5Zm-96.667-150Q745-350 737.5-357.555q-7.5-7.556-7.5-19.111v-28q0-41.001-24-63.834-24-22.833-58-22.833h-68q-54.667 0-92-40.667t-37.333-95.333q0-54.667 37.333-92 37.333-37.334 92-37.334 11.556 0 19.111 7.578 7.556 7.577 7.556 19.167 0 11.589-7.556 19.089-7.555 7.5-19.111 7.5-33.333 0-54.667 21-21.333 21-21.333 55T525.333-569q21.334 24.334 54.377 24.334H648q54.918 0 95.126 36.666 40.207 36.667 40.207 90.667v40.667q0 11.555-7.577 19.111Q768.178-350 756.589-350Zm96.667 0q-11.589 0-19.089-7.555-7.5-7.556-7.5-19.111v-70.001q0-72-48.667-120t-121.333-48q-11.556 0-19.111-7.577Q630-629.822 630-641.411q0-11.589 7.556-19.089 7.555-7.5 19.111-7.5 32.666 0 54.333-23.333 21.667-23.334 21.667-56 0-32.667-21.667-56-21.667-23.334-54.333-23.334-11.556 0-19.111-7.577Q630-841.822 630-853.411q0-11.589 7.556-19.089 7.555-7.5 19.111-7.5 54.666 0 92 39Q786-802 786-747.333q0 31.666-12 55.5Q762-668 742.666-650 800-628 840-573.333q40 54.666 40 126.666v70.001q0 11.555-7.578 19.111Q864.845-350 853.256-350Z" /></svg>,
  'smoking_rooms_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M130-200q-20.833 0-35.417-14.618Q80-229.235 80-250.118 80-271 94.583-285.5 109.167-300 130-300h535q10.833 0 17.917 7.097Q690-285.806 690-274.951v50.097Q690-214 682.917-207q-7.084 7-17.917 7H130Zm629.884 0Q749-200 742-207.097t-7-17.952v-50.097Q735-286 742.116-293q7.117-7 18-7Q771-300 778-292.903t7 17.952v50.097Q785-214 777.884-207q-7.117 7-18 7Zm95 0Q844-200 837-207.097t-7-17.952v-50.097Q830-286 837.116-293q7.117-7 18-7Q866-300 873-292.903t7 17.952v50.097Q880-214 872.884-207q-7.117 7-18 7Zm-95-145Q749-345 742-352.083q-7-7.084-7-17.917v-31q0-42-24.5-66T651-491h-71q-54 0-90.5-41.5T453-628q0-54 36.5-90.5T580-755q10.833 0 17.917 7.116 7.083 7.117 7.083 18Q605-719 597.917-712q-7.084 7-17.917 7-35 0-56 21t-21 56q0 35 21 61t55.565 26H651q54.377 0 94.188 37Q785-467 785-413v43q0 10.833-7.116 17.917-7.117 7.083-18 7.083Zm95 0Q844-345 837-352.083q-7-7.084-7-17.917v-75q0-75-50-123t-125-48q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q630-652 637.083-659q7.084-7 17.917-7 34 0 55.5-24t21.5-58q0-34-21.5-58T655-830q-10.833 0-17.917-7.116-7.083-7.117-7.083-18Q630-866 637.083-873q7.084-7 17.917-7 54 0 90.5 39t36.5 93q0 33-12.5 57T737-650q58 20 100.5 75T880-445v75q0 10.833-7.116 17.917-7.117 7.083-18 7.083Z" /></svg>,
  'square_foot_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M208-120q-37 0-62.5-25.5T120-208v-548q0-29 27-40.5t47 8.5l90 90-54 54 28 28 54-54 104 104-54 54 28 28 54-54 104 104-54 54 28 28 54-54 104 104-54 54 28 28 54-54 80 80q20 20 8.5 47T756-120H208Zm32-120h332L240-572v332Z" /></svg>,
  'square_foot_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M202.667-120q-34.334 0-58.5-24.167Q120-168.333 120-202.667v-541.999q0-34.334 32-47.501 32-13.166 56.667 11.501l59.999 59.999-46 45.999L250-647.334l46-46 112.666 112.667-45.999 46L390-507.334l46-46L549.333-440l-45.999 46 27.333 27.333 46-46L689.334-300l-46 46 27.333 27.333 46-46 56.666 56.666q26.667 26.667 12.833 61.334Q772.333-120 735.999-120H202.667Zm10.666-93.333h419.336L213.333-632.669v419.336Z" /></svg>,
  'square_foot_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M200-120q-33 0-56.5-23.5T120-200v-539q0-37 34.5-51t61.5 13l45 45-42 42 27 27 42-42 117 117-42 42 27 27 42-42 118 118-42 42 27 27 42-42 117 117-42 42 27 27 42-42 45 45q30 30 15 68.5T726-120H200Zm0-80h463L200-663v463Z" /></svg>,
  'support_agent_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-120q-17 0-28.5-11.5T440-160q0-17 11.5-28.5T480-200h280v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v204q0 17-11.5 28.5T160-240q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H480ZM360-400q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z" /></svg>,
  'support_agent_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M473.33-120q-14.16 0-23.75-9.62-9.58-9.61-9.58-23.83 0-14.22 9.58-23.72 9.59-9.5 23.75-9.5h300V-484q0-121.46-85.38-206.06-85.38-84.61-207.95-84.61t-207.95 84.61q-85.38 84.6-85.38 206.06v217.33q0 11.34-7.67 19-7.67 7.67-19 7.67-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v62.33q0 27.5-19.58 47.09Q800.83-120 773.33-120h-300ZM359.88-406.67q-14.21 0-23.71-9.61-9.5-9.62-9.5-23.84 0-14.21 9.61-23.71 9.62-9.5 23.84-9.5 14.21 0 23.71 9.61 9.5 9.62 9.5 23.84 0 14.21-9.61 23.71-9.62 9.5-23.84 9.5Zm240 0q-14.21 0-23.71-9.61-9.5-9.62-9.5-23.84 0-14.21 9.61-23.71 9.62-9.5 23.84-9.5 14.21 0 23.71 9.61 9.5 9.62 9.5 23.84 0 14.21-9.61 23.71-9.62 9.5-23.84 9.5ZM241-462q-7-106 64-182t177-76q87.67 0 152.83 57.17Q700-605.67 714-519q-89.67-1-164.17-49.67-74.5-48.66-115.02-129.33Q419-618 367.5-555.5T241-462Z" /></svg>,
  'support_agent_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M470-120q-12.75 0-21.37-8.68-8.63-8.67-8.63-21.5 0-12.82 8.63-21.32 8.62-8.5 21.37-8.5h310v-304q0-123.69-87.32-209.84Q605.36-780 480-780q-125.36 0-212.68 86.16Q180-607.69 180-484v224q0 8.5-5.75 14.25T160-240q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v69q0 24.75-17.62 42.37Q804.75-120 780-120H470ZM359.82-410q-12.82 0-21.32-8.68-8.5-8.67-8.5-21.5 0-12.82 8.68-21.32 8.67-8.5 21.5-8.5 12.82 0 21.32 8.68 8.5 8.67 8.5 21.5 0 12.82-8.68 21.32-8.67 8.5-21.5 8.5Zm240 0q-12.82 0-21.32-8.68-8.5-8.67-8.5-21.5 0-12.82 8.68-21.32 8.67-8.5 21.5-8.5 12.82 0 21.32 8.68 8.5 8.67 8.5 21.5 0 12.82-8.68 21.32-8.67 8.5-21.5 8.5ZM241-462q-7-106 64-182t177-76q87 0 151 57.5T711-519q-89-1-162.5-50T434.72-698Q419-618 367.5-555.5T241-462Z" /></svg>,
  'upload_file_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-367v127q0 17 11.5 28.5T480-200q17 0 28.5-11.5T520-240v-127l36 36q6 6 13.5 9t15 2.5q7.5-.5 14.5-3.5t13-9q11-12 11.5-28T612-388L508-492q-6-6-13-8.5t-15-2.5q-8 0-15 2.5t-13 8.5L348-388q-12 12-11.5 28t12.5 28q12 11 28 11.5t28-11.5l35-35ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h287q16 0 30.5 6t25.5 17l194 194q11 11 17 25.5t6 30.5v447q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-440H560q-17 0-28.5-11.5T520-640ZM240-800v200-200 640-640Z" /></svg>,
  'upload_file_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M448-391.001v156.334q0 14.167 9.617 23.75 9.617 9.584 23.833 9.584 14.217 0 23.717-9.584 9.5-9.583 9.5-23.75v-156.334l52.667 52.667q4 4 14.5 1.667 10.5-2.334 20-5.167 9.5-2.833 14.5-3.5 5-.667-2.334 6.667 9.667-10 9.5-23.333-.167-13.333-10.167-23.333l-110-108q-5.333-5.334-11.089-7.5Q486.489-503 479.911-503t-12.244 2.167q-5.667 2.166-11 7.5L347.333-384q-10 10-9.833 23.333.167 13.334 10.167 23.594 10 9.406 23.333 9.573 13.333.166 23.333-9.833L448-391.001ZM226.666-80q-27 0-46.833-19.833T160-146.666v-666.668q0-27 19.833-46.833T226.666-880h319.668q13.63 0 25.981 5.333 12.352 5.334 21.352 14.334l186.666 186.666q9 9 14.334 21.352Q800-639.964 800-626.334v479.668q0 27-19.833 46.833T733.334-80H226.666Zm314.001-576v-157.334H226.666v666.668h506.668v-476.001H574q-14.166 0-23.749-9.584-9.584-9.583-9.584-23.749ZM226.666-813.334v190.667-190.667 666.668-666.668Z" /></svg>,
  'verified_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m438-452-58-57q-11-11-27.5-11T324-508q-11 11-11 28t11 28l86 86q12 12 28 12t28-12l170-170q12-12 11.5-28T636-592q-12-12-28.5-12.5T579-593L438-452ZM326-90l-58-98-110-24q-15-3-24-15.5t-7-27.5l11-113-75-86q-10-11-10-26t10-26l75-86-11-113q-2-15 7-27.5t24-15.5l110-24 58-98q8-13 22-17.5t28 1.5l104 44 104-44q14-6 28-1.5t22 17.5l58 98 110 24q15 3 24 15.5t7 27.5l-11 113 75 86q10 11 10 26t-10 26l-75 86 11 113q2 15-7 27.5T802-212l-110 24-58 98q-8 13-22 17.5T584-74l-104-44-104 44q-14 6-28 1.5T326-90Zm52-72 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Z" /></svg>,
  'verified_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m437.333-439.333-68-69.667q-9.667-10.333-23.833-10.333-14.167 0-24.833 10-10.334 10.333-10.334 24.666T320.667-460L414-367.333q10 10 23.333 10 13.334 0 23.334-10l178.666-178.001q10-10 9.834-23.999-.167-14-10.5-24-10-9.334-23.833-9.167-13.834.167-23.167 9.5L437.333-439.333ZM330-86l-60.667-103.333L150-214q-12.333-2.333-20.333-13.167-8-10.833-6-23.166l13-117.667L59-458q-8.667-9-8.667-22T59-502l77.667-89.333-13-117.667q-2-12.333 6-23.167 8-10.833 20.333-13.166L269.333-770 330-874q6.667-11 18.667-15.167 12-4.166 24 1.167L480-839.333 587.333-888q12-5.333 24-1.5T630-874.667L691.333-770 810-745.333q12.333 2.333 20.333 13.166 8 10.834 6 23.167l-13 117.667L901-502q8.667 9 8.667 22T901-458l-77.667 90 13 117.667q2 12.333-6 23.166-8 10.834-20.333 13.167l-118.667 24.667-61.333 104q-6.667 11-18.667 14.833-12 3.833-24-1.5L480-120.667 372.667-72q-12 5.333-24 1.167Q336.667-75 330-86Zm44.667-60.666L480-191.333l108 44.667 63.334-98.667L766-274l-11.333-116.667L833.334-480l-78.667-91.333L766-688l-114.666-26.667L586-813.334l-106 44.667-108-44.667-63.334 98.667L194-688l11.333 116.667L126.666-480l78.667 89.333L194-272l114.666 26.667 66.001 98.667ZM480-480Z" /></svg>,
  'verified_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m437-433-73-76q-9-10-22-10t-23 9q-10 10-10 23t10 23l97 96q9 9 21 9t21-9l183-182q9-9 9-22t-10-22q-9-8-21.5-7.5T598-593L437-433ZM332-84l-62-106-124-25q-11-2-18.5-12t-5.5-21l14-120-79-92q-8-8-8-20t8-20l79-91-14-120q-2-11 5.5-21t18.5-12l124-25 62-107q6-10 17-14t22 1l109 51 109-51q11-5 22-1.5t17 13.5l63 108 123 25q11 2 18.5 12t5.5 21l-14 120 79 91q8 8 8 20t-8 20l-79 92 14 120q2 11-5.5 21T814-215l-123 25-63 107q-6 10-17 13.5T589-71l-109-51-109 51q-11 5-22 1t-17-14Zm41-55 107-45 110 45 67-100 117-30-12-119 81-92-81-94 12-119-117-28-69-100-108 45-110-45-67 100-117 28 12 119-81 94 81 92-12 121 117 28 70 100Zm107-341Z" /></svg>,
  'visibility_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-134 0-244.5-72T61-462q-5-9-7.5-18.5T51-500q0-10 2.5-19.5T61-538q64-118 174.5-190T480-800q134 0 244.5 72T899-538q5 9 7.5 18.5T909-500q0 10-2.5 19.5T899-462q-64 118-174.5 190T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>,
  'visibility_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M480.078-326.667q72.255 0 122.755-50.578 50.5-50.579 50.5-122.833 0-72.255-50.578-122.755-50.579-50.5-122.833-50.5-72.255 0-122.755 50.578-50.5 50.579-50.5 122.833 0 72.255 50.578 122.755 50.579 50.5 122.833 50.5Zm-.235-62.666q-46.176 0-78.343-32.324-32.167-32.323-32.167-78.5 0-46.176 32.324-78.343 32.323-32.167 78.5-32.167 46.176 0 78.343 32.324 32.167 32.323 32.167 78.5 0 46.176-32.324 78.343-32.323 32.167-78.5 32.167ZM480-200q-134.667 0-245.167-74.667-110.5-74.666-176.5-192.666-4.333-7.75-6.5-15.931-2.166-8.18-2.166-16.792 0-8.611 2.166-16.753 2.167-8.143 6.5-15.858 66-118 176.5-192.666Q345.333-800 480-800t245.167 74.667q110.5 74.666 176.5 192.666 4.333 7.75 6.5 15.931 2.166 8.18 2.166 16.792 0 8.611-2.166 16.753-2.167 8.143-6.5 15.858-66 118-176.5 192.666Q614.667-200 480-200Zm0-300Zm-.112 233.334q118.445 0 217.612-63.5Q796.667-393.667 848.667-500q-52-106.333-151.054-169.834-99.055-63.5-217.501-63.5-118.445 0-217.612 63.5Q163.333-606.333 110.666-500q52.667 106.333 151.721 169.834 99.055 63.5 217.501 63.5Z" /></svg>,
  'visibility_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-135 0-245.5-76T57-470q-4-7.125-6-14.646-2-7.521-2-15.437 0-7.917 2-15.381 2-7.465 6-14.536 67-118 177.5-194T480-800q135 0 245.5 76T903-530q4 7.125 6 14.646 2 7.521 2 15.437 0 7.917-2 15.381-2 7.465-6 14.536-67 118-177.5 194T480-200Zm0-300Zm-.169 240Q601-260 702.5-325.5 804-391 857-500q-53-109-154.331-174.5-101.332-65.5-222.5-65.5Q359-740 257.5-674.5 156-609 102-500q54 109 155.331 174.5 101.332 65.5 222.5 65.5Z" /></svg>,
  'visibility_off_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m644-428-58-58q11-53-27.5-90.5T466-606l-58-58q17-8 35-12t37-4q75 0 127.5 52.5T660-500q0 19-4 37t-12 35Zm128 126-58-56q38-29 68-64.5t50-77.5q-50-101-144.5-160.5T480-720q-29 0-56.5 4T368-704l-62-62q42-17 85.5-25.5T480-800q137 0 250.5 72T904-534q4 8 6 16.5t2 17.5q0 9-1.5 17.5T905-466q-22 49-56 90.5T772-302ZM480-200q-134 0-245-72.5T60-463q-5-8-7.5-17.5T50-500q0-10 2-19t7-18q20-40 46.5-76.5T166-680l-83-84q-11-12-10.5-28.5T84-820q11-11 28-11t28 11l680 680q11 11 11.5 27.5T820-84q-11 11-28 11t-28-11L624-222q-35 11-71 16.5t-73 5.5ZM222-624q-29 26-53 57t-41 67q50 101 144.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>,
  'visibility_off_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="m634-422-48.667-48.666Q605-533 558.833-578.833q-46.167-45.834-108.167-26.5L402-654q17.667-10 37.667-14.666 20-4.667 40.333-4.667 72.333 0 122.833 50.5T653.333-500q0 20.333-4.667 40.333Q644-439.667 634-422Zm128.666 128-45.999-45.333Q759.334-371 794-410.833q34.667-39.833 54.667-89.167-49.333-106.333-146.833-169.834-97.5-63.5-215.167-63.5-36.334 0-71.834 4.667T345.999-710l-51.333-52q44.667-19.667 92.167-28.833 47.5-9.167 96.5-9.167 137 0 249.834 74.333Q846-651.333 906-528.667q3.333 6.667 5.333 13.834 2 7.166 2 14.833t-1.5 14.833q-1.5 7.167-4.833 13.834-24.667 53-61 97.833T762.666-294ZM480-200q-135.333 0-246.667-74.833Q122-349.667 56.667-469q-4.334-7.333-6.167-14.833-1.833-7.5-1.833-16.167 0-8.667 2-16t5.666-15q22.667-45.333 52.5-85.833 29.834-40.501 67.834-73.834l-97.667-98q-9.667-10-9.167-23.5t9.5-23.167Q89-845 103-845q14 0 23.666 9.666l704.001 704.001q9 9 9.167 22.166Q840-96 830.667-86q-9 10.333-23 10.333t-23.666-9.666L640-226.667Q601-213 561-206.5q-40 6.5-81 6.5ZM222.667-644.001q-37.001 28-65.667 64.334-28.667 36.333-46.334 79.667Q162-391.666 263.166-329.166q101.167 62.5 222.167 62.5 26.001 0 51.668-1.834 25.666-1.833 49.667-11.5L532-335.334q-13 5-26 6.834-13 1.833-26 1.833-72.333 0-122.833-50.5T306.667-500q0-13 2.167-26 2.166-13 6.5-26l-92.667-92.001Zm309.666 125.668Zm-127.666 63.666Z" /></svg>,
  'visibility_off_FILL0_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m629-419-44-44q24-67-26-117t-116-25l-44-44q18-11 39-16t42-5q71 0 120.5 49.5T650-500q0 21-5 42t-16 39Zm129 129-40-40q45-33 82-75t57-95q-49-109-148-174.5T490-740q-40 0-79.5 5T335-713l-46-47q46-21 95.5-30.5T485-800q137 0 249.5 75.5T907-526q3 6 5 12.5t2 13.5q0 7-1.5 13.5T908-474q-26 55-63.5 101.5T758-290Zm-278 90q-136 0-247.5-76T55-472q-4-7-5.5-13.5T48-500q0-8 2-14.5t5-13.5q24-48 55.5-90.5T182-696L77-801q-9-9-8.5-21t8.5-21q9-9 21.5-9t21.5 9l716 716q8 8 8 19.5T836-87q-8 10-20.5 10T794-86L648-229q-41 15-83 22t-85 7ZM223-654q-41 29-72 68t-49 86q52 112 156.5 176T488-260q29 0 58-1.5t55-14.5l-64-64q-14 6-28.5 8t-28.5 2q-71 0-120.5-49.5T310-500q0-14 2.5-28.5T320-557l-97-97Zm305 142Zm-116 58Z" /></svg>,
  'wallet_FILL1_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-480h480q22 0 42 5t38 16v-21q0-33-23.5-56.5T720-720H240q-33 0-56.5 23.5T160-640v21q18-11 38-16t42-5Zm-74 130 445 108q9 2 18 0t17-8l139-116q-11-15-28-24.5t-37-9.5H240q-26 0-45.5 13.5T166-510Z" /></svg>,
  'wallet_FILL1_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-473.333h480q26.667 0 50.334 7.667Q794-618 813.334-603v-37q0-39-27.167-66.167T720-733.334H240q-39 0-66.167 27.167T146.666-640v37q19.334-15 43-22.666 23.667-7.667 50.334-7.667Zm-89.334 134L617-386.666q7.667 2 15.667.333t14-7l153-128q-12.333-20.334-33.333-32.834-21.001-12.5-46.334-12.5H240q-32 0-56.5 18.834-24.5 18.833-32.834 48.5Z" /></svg>,
  'wallet_FILL1_wght400_GRAD0_opsz48': <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-470h480q29 0 54.5 9t45.5 26v-45q0-42-29-71t-71-29H240q-42 0-71 29t-29 71v45q20-17 45.5-26t54.5-9Zm-97 136 477 115q7 2 14.5.5T647-385l160-134q-13-23-36-37t-51-14H240q-35 0-62 21.5T143-494Z" /></svg>,
  'work_FILL0_wght400_GRAD0_opsz24': <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z" /></svg>,
  'work_FILL0_wght400_GRAD0_opsz40': <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40"><path d="M146.666-120q-27 0-46.833-19.833T80-186.666v-466.668q0-27 19.833-46.833T146.666-720H320v-93.334q0-27 19.833-46.833T386.666-880h186.668q27 0 46.833 19.833T640-813.334V-720h173.334q27 0 46.833 19.833T880-653.334v466.668q0 27-19.833 46.833T813.334-120H146.666Zm0-66.666h666.668v-466.668H146.666v466.668Zm240-533.334h186.668v-93.334H386.666V-720Zm-240 533.334v-466.668 466.668Z" /></svg>,
  "domain_add_FILL0_wght400_GRAD0_opsz24": <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M80-120v-720h400v160h400v320h-80v-240H480v80h80v80h-80v80h80v80h-80v80h160v80H80Zm80-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 480h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80ZM800-40v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80ZM640-440v-80h80v80h-80Zm0 160v-80h80v80h-80Z" /></svg>,
  "wallpaper_slideshow_FILL0_wght400_GRAD0_opsz24": <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M680-640q-17 0-28.5-11.5T640-680q0-17 11.5-28.5T680-720q17 0 28.5 11.5T720-680q0 17-11.5 28.5T680-640ZM360-400l108-140 62 80 92-120 138 180H360ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm80-505v-215q0-33 23.5-56.5T320-880h200v80H320v215h-80Zm80 345q-33 0-56.5-23.5T240-320v-185h80v185h200v80H320Zm280 0v-80h200v-185h80v185q0 33-23.5 56.5T800-240H600Zm200-345v-215H600v-80h200q33 0 56.5 23.5T880-800v215h-80Z" /></svg>
};
export type IconName = "ac_unit" | "account_circle" | "add_ad" | "add_circle" | "add_location" | "arrow_circle_left" | "arrow_circle_right" | "attach_file" | "badge" | "bathtub" | "bed" | "bookmark" | "build_circle" | "call" | "cancel" | "chair" | "chat" | "chat_bubble" | "check_circle" | "close" | "code" | "content_copy" | "credit_card" | "database" | "delete_24dp" | "description" | "do_not_disturb_on" | "door_front" | "door_open" | "download_for_offline_24dp" | "dvr" | "event_available" | "expand_circle_down" | "expand_circle_up" | "expand_more" | "fitness_center" | "fullscreen_24dp" | "fullscreen_exit_24dp" | "grid_view" | "help" | "home" | "id_card" | "info" | "key" | "keyboard_double_arrow_down" | "kitchen" | "label" | "landscape" | "leaderboard" | "license" | "lightbulb" | "list" | "local_fire_department" | "location_on" | "lock" | "lock_open_right" | "mail" | "menu" | "mic" | "monetization_on" | "page_info" | "pause" | "pause_circle" | "payments" | "person" | "pets" | "play_arrow" | "play_circle" | "podcasts" | "policy" | "print" | "problem" | "real_estate_agent" | "request_quote" | "rotate_90_degrees_ccw" | "rotate_90_degrees_cw" | "schedule" | "search" | "sell" | "send" | "settings" | "smoking_rooms" | "square_foot" | "support_agent" | "upload_file" | "verified" | "visibility" | "visibility_off" | "wallet" | "work" | "domain_add" | "wallpaper_slideshow";
export const IconNames = ["ac_unit", "account_circle", "add_ad", "add_circle", "add_location", "arrow_circle_left", "arrow_circle_right", "attach_file", "badge", "bathtub", "bed", "bookmark", "build_circle", "call", "cancel", "chair", "chat", "chat_bubble", "check_circle", "close", "code", "content_copy", "credit_card", "database", "delete_24dp", "description", "do_not_disturb_on", "door_front", "door_open", "download_for_offline_24dp", "dvr", "event_available", "expand_circle_down", "expand_circle_up", "expand_more", "fitness_center", "fullscreen_24dp", "fullscreen_exit_24dp", "grid_view", "help", "home", "id_card", "info", "key", "keyboard_double_arrow_down", "kitchen", "label", "landscape", "leaderboard", "license", "lightbulb", "list", "local_fire_department", "location_on", "lock", "lock_open_right", "mail", "menu", "mic", "monetization_on", "page_info", "pause", "pause_circle", "payments", "person", "pets", "play_arrow", "play_circle", "podcasts", "policy", "print", "problem", "real_estate_agent", "request_quote", "rotate_90_degrees_ccw", "rotate_90_degrees_cw", "schedule", "search", "sell", "send", "settings", "smoking_rooms", "square_foot", "support_agent", "upload_file", "verified", "visibility", "visibility_off", "wallet", "work", "domain_add", "wallpaper_slideshow"];
