import React, { useState } from 'react'
import { type User } from '../../../../store/slices/userSlice'
import useUserType from '../../../../hooks/UseUserType'
import { useNavigate } from 'react-router'
import { openChatByUserID } from '../../../../store/actionsCreators/chatActions'
import { useAppDispatch } from '../../../../hooks/redux'
import Avatar from '../../elements/Avatar'
import Icon from '../../../../app/icon/Icon'
import { store } from '../../../../store/store'

interface ProfileCardProps {
    user: User
    showUserType?: boolean
}
export default function ProfileCardNew({ user, showUserType = false }: ProfileCardProps): JSX.Element {

    const { userType } = useUserType()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    return (
        <div className="d-flex flex-stack bg-white p-4 rounded shadow-sm position-relative"
            style={user.id % 2 !== 1 ? {} : {
                backgroundImage: `url(/assets/media/stock/s_${Math.floor(Math.random() * 5) + 1}.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
            onClick={(e) => {
                if ((e.target as HTMLDivElement).closest('#contact-user-button') == null) {
                    navigate(`/${userType.toLowerCase()}/profile/${user.id}`);
                }
            }}>


            {user.id % 2 === 1 && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    zIndex: 0
                }}></div>
            )}


            <div className="symbol symbol-40px me-5" style={{ position: 'relative', zIndex: 1 }}>
                <Avatar image={user?.Type?.[0]?.Photo ?? undefined} name={user?.ContactInfo?.FirstName ?? ''} size={'6rem'} />
            </div>

            <div className="d-flex align-items-center flex-row-fluid flex-wrap" style={{ position: 'relative', zIndex: 1 }}>
                <div className="flex-grow-1 me-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="text-gray-800 text-hover-primary fs-6 fw-bold" style={{ wordBreak: 'break-word' }}>
                            {user?.ContactInfo?.FirstName} {user?.ContactInfo?.FamilyName}
                        </span>
                        <span id="contact-user-button" onClick={() => {
                            if (user.id !== store.getState().user.currentUser.id) {
                                setLoading(true);
                                dispatch(openChatByUserID(user.id))
                                    .then(({ id }: { id: any }) => {
                                        setLoading(false);
                                        if (id != null) {
                                            navigate(`/${userType.toLowerCase()}/chats/${String(id)}`);
                                        }
                                    });
                            }
                        }}>
                            <Icon name="mail" className="text-gray-800 fs-2hx" />
                        </span>
                    </div>
                    <span
                        className={` ${user.id % 2 !== 1 ? "text-muted" : "text-gray-800"} fw-semibold d-block fs-7`}

                    >{user.Settings?.[0].Company ?? ""}</span>
                    <div className="text-gray-800 fs-7 lh-sm">
                        {user.About ?? "Hey there! I am using BSO Club!"}
                    </div>
                </div>
            </div>
        </div>

    )
}
