import React, { useState } from 'react'

import AgentHeader from '../../AgentHeader'
import ProfileMetrics from './AgentProfileMetrics'
import AgentAreas from './AgentAreas'
import { type RootState } from '../../../../store/store'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import AgentProfileDocuments from './AgentProfileDocuments'
import AgentFooter from '../../AgentFooter'
import { type ISettings, type User } from '../../../../store/slices/userSlice'
import AgentCreditsBSO from './AgentCreditsBSO'

import { generateProgressProfile } from '../../../../functions/agent/profile/generateProgressProfile'
import { getMetrics } from './getMetric'
import MyProfileHead from '../other/viewOtherProfile/MyProfileHead'
import Icon from '../../../../app/icon/Icon'
import { Drawer } from 'antd'
import AddOnsServiceBricks from '../../addOnsServices/AddOnsServicesCards/AddOnsServiceBricks'
import AddOnsServiceArea from '../../addOnsServices/AddOnsServicesCards/AddOnServicesArea'
import { setButtonDriverAddOnsParent, setButtonDriverExtendLocation } from '../../../../store/slices/modalSlice'
import { setNotEnoughMarketingCredits } from '../../../../store/slices/advertisesSlice'
import MyProfileNEW from '../other/viewOtherProfile/MyProfileNEW'

export default function AgentProfile(): JSX.Element {
    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    ) as User
    const settings = currentUser?.Settings as ISettings[]
    const userID = currentUser.id
    const documents = useAppSelector(
        (state: RootState) => state.documents.documents
    ).filter(doc => doc.attributes?.User?.data?.id === Number(userID))

    const documentsLoaded = useAppSelector((state: RootState) => state.agentStates.documentsLoaded)
    const loadSts = documentsLoaded.emiratesLoaded || documentsLoaded.passportLoaded

    const [previewOpen, setPreviewOpen] = useState(false)
    const [progressProfile, setProgressProfile] = useState<number>(generateProgressProfile(currentUser, documents))
    const profileMetrics = getMetrics(currentUser)
    const progressBarWidth = 300
    const progressInPixels = (progressProfile / 100) * progressBarWidth
    const dispatch = useAppDispatch()

    const buttonDriverAddOnsParent = useAppSelector((state: RootState) => state.modal.buttonDriverAddOnsParent)
    const buttonDriverExtendLocation = useAppSelector((state: RootState) => state.modal.buttonDriverExtendLocation)
    const isDev = process.env.REACT_APP_IS_DEV === 'true'

    return (
        <>
            <div
                className="page d-flex flex-column flex-row-fluid fs-5">
                <AgentHeader
                    active="Profile"
                />

                <div className="content d-flex flex-column flex-fill p-0 m-4">
                    <div className="w-100 mw-450px mx-auto">
                        <div className="card shadow-sm mb-4 mt-2">
                            <div className={`card-body ${isDev ? "p-0" : "p-4"} `}>
                                {isDev ?
                                    <MyProfileNEW previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} currentUser={currentUser} /> :
                                    <MyProfileHead previewOpen={previewOpen} setPreviewOpen={setPreviewOpen} currentUser={currentUser} />}
                            </div>
                        </div>
                        {isDev &&
                            <div className="d-flex align-items-center w-100  flex-column px-2 my-2">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                    <span className="fw-semi-bold fs-6 text-gray-400">Profile Completion</span>
                                    <span className="fw-bold fs-6 ">{progressProfile}%</span>
                                </div>
                                <div className="h-5px mx-3 w-100 bg-secondary mb-2">
                                    <div className="bg-success rounded h-5px" style={{ width: progressInPixels === 300 ? '100%' : `${progressInPixels}px` }}></div>
                                </div>
                            </div>}
                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <ProfileMetrics
                                    metrics={profileMetrics}
                                />
                            </div>
                        </div>

                        <div className="card shadow-sm mb-4">
                            <div className="card-body p-4">
                                <AgentProfileDocuments documents={documents} />
                            </div>
                        </div>
                        {loadSts && <>
                            <div className="card shadow-sm mb-4">
                                <div className="card-body p-4">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className={'text-gray-900 fs-4 fw-bold '}>Your Credits</div>
                                    </div>
                                    <AgentCreditsBSO settings={settings} />
                                    <div className="text-end">
                                        <div
                                            className={'btn btn-bso mt-4 py-2 border-bso2px  w-100 d-flex justify-content-center align-items-center'}
                                            onClick={() => { dispatch(setButtonDriverAddOnsParent(true)) }}>
                                            <Icon name='add_ad' className={'fs-3x'}
                                            />  Top up</div>
                                    </div>
                                </div>

                                <Drawer
                                    style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                                    height={'auto'}
                                    styles={{
                                        body: {
                                            maxHeight: '88vh', // maxHeight: '80vh',
                                            marginBottom: '3px',
                                            paddingTop: '3px',
                                            marginTop: '6px',
                                            paddingLeft: '2px',
                                            paddingRight: '2px'
                                        }
                                    }}
                                    placement={'bottom'}
                                    closable={false}
                                    onClose={() => { dispatch(setButtonDriverAddOnsParent(false)); dispatch(setNotEnoughMarketingCredits(0)) }}
                                    open={buttonDriverAddOnsParent}
                                    rootStyle={{ zIndex: '1100' }}
                                    key={'parent-driver-bricks'}
                                    maskClosable={true}
                                >
                                    <div key={'child-driver-bricks'}
                                        className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                                    >
                                        <div className="w-100 mw-450px  mx-auto">
                                            <AddOnsServiceBricks />
                                        </div>
                                    </div>
                                </Drawer>
                            </div>

                            <div className="card shadow-sm mb-4">
                                <div className="card-body p-4">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className={'text-gray-900 fs-4 fw-bold '}>Preferred Areas</div>
                                    </div>
                                    <AgentAreas user={currentUser} />

                                    <div
                                        onClick={() => { dispatch(setButtonDriverExtendLocation(true)) }}
                                        className={'btn btn-bso mt-4  py-2 border-bso2px  w-100 d-flex justify-content-center align-items-center'}
                                    >
                                        <Icon name='add_location' className={'fs-3x'}
                                        />
                                        Add  more locations
                                    </div>

                                </div>
                                <Drawer
                                    style={{ borderTopRightRadius: '8px', borderTopLeftRadius: '8px', paddingBottom: '3px' }}
                                    height={'auto'}
                                    styles={{
                                        body: {
                                            maxHeight: '88vh',
                                            marginBottom: '3px',
                                            paddingTop: '3px',
                                            marginTop: '6px',
                                            paddingLeft: '2px',
                                            paddingRight: '2px'
                                        }
                                    }}
                                    placement={'bottom'}
                                    closable={false}
                                    onClose={() => { dispatch(setButtonDriverExtendLocation(false)) }}
                                    open={buttonDriverExtendLocation}
                                    rootStyle={{ zIndex: '1100' }}
                                    key={'parent-driver-areas'}
                                    maskClosable={true}
                                >
                                    <div key={'child-driver-areas'}
                                        className="content d-flex flex-column flex-fill p-0 m-1 px-2"
                                    >
                                        <div className="w-100 mw-450px  mx-auto">
                                            <AddOnsServiceArea />
                                        </div>
                                    </div>
                                </Drawer>
                            </div>
                        </>}

                    </div>
                </div>
                <div style={{ height: '160px' }}></div>
            </div >
            {!previewOpen ? <AgentFooter /> : null}
        </>
    )
}
