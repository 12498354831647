
import { type IAdvertise } from '../../../store/slices/advertisesSlice'
import { store } from '../../../store/store';
export const getStatusesAdvertisements = (
    advertises: IAdvertise[],
    queueAdvertises: number[] 
): IAdvertise[] => { 
    const statuses = [] as any
    let queueAds: IAdvertise[] = [];

    if (queueAdvertises.length > 0) {
        queueAds = store.getState().advertises.advertises.filter((adv: IAdvertise) => queueAdvertises.includes(adv.id));
    }


    const docsReceivedAds = advertises.filter(advertise => advertise?.attributes?.Statuses.includes('Docs received'))
    const startAds = advertises.filter((advertise) => {
        const dateNow = new Date().getTime()
        if (advertise.attributes.StartDate !== null && advertise.attributes.EndDate !== null) {
            if (new Date(advertise.attributes.StartDate).getTime() < dateNow && dateNow < new Date(advertise.attributes.EndDate).getTime() && advertise?.attributes?.Statuses.includes('Links approved')) {
                return advertise
            }
        }
    })

    const inProcessAds = advertises.filter(advertise => advertise?.attributes?.Statuses.includes('In-process'))
    
    const linksApprovedAds = advertises.filter((advertise) => {
        const dateNow = new Date().getTime()
        if (advertise.attributes.StartDate !== null && advertise.attributes.EndDate !== null) {
            if (advertise?.attributes?.Statuses.includes('Links approved') && !(new Date(advertise.attributes.StartDate).getTime() < dateNow && dateNow < new Date(advertise.attributes.EndDate).getTime())) {
                return advertise
            }
        }
    })

    const linksAddedAds = advertises.filter(advertise => advertise?.attributes?.Statuses.includes('Links added'))
    const linksRejectedAds = advertises.filter(advertise => advertise?.attributes?.Statuses.includes('Links rejected'))
    // const linksApprovedAds = advertises.filter(advertise => advertise?.attributes?.Statuses.includes('Links approved'))

    const completedAds = advertises.filter(advertise => advertise?.attributes?.Statuses.includes('Completed'))

    const otherAds = advertises.filter(advertise =>
        !(advertise?.attributes?.Statuses.includes('Links added')) &&
        !(advertise?.attributes?.Statuses.includes('Links rejected')) &&
        !(advertise?.attributes?.Statuses.includes('Links approved')) &&
        !(advertise?.attributes?.Statuses.includes('Advertisement started')) &&
        !(advertise?.attributes?.Statuses.includes('Docs received')) &&
        !(advertise?.attributes?.Statuses.includes('In-process')) &&
        !(advertise?.attributes?.Statuses.includes('Completed'))
    )

    statuses.push({
        status: 'In-process',
        advertisements: inProcessAds,
        advertisementsLength: inProcessAds.length
    })
    statuses.push({
        status: 'Advertisement started',
        advertisements: startAds,
        advertisementsLength: startAds.length
    })
    statuses.push({
        status: 'Docs received',
        advertisements: docsReceivedAds,
        advertisementsLength: docsReceivedAds.length
    })
    statuses.push({
        status: 'Links added',
        advertisements: linksAddedAds,
        advertisementsLength: linksAddedAds.length
    })
    statuses.push({
        status: 'Links rejected',
        advertisements: linksRejectedAds,
        advertisementsLength: linksRejectedAds.length
    })
    statuses.push({
        status: 'Links approved',
        advertisements: linksApprovedAds,
        advertisementsLength: linksApprovedAds.length
    })
    statuses.push({
        status: 'Completed',
        advertisements: completedAds,
        advertisementsLength: completedAds.length
    })

    statuses.push({
        status: 'Other',
        advertisements: otherAds,
        advertisementsLength: otherAds.length
    })
    if (queueAds.length > 0) {
        statuses.push({
            status: 'Awaiting list',
            advertisements: queueAds,
            advertisementsLength: queueAds.length,
            isAwaitingTask: true 
        });
    }

    return statuses
}
